import { useState } from "react";
import { Container } from "./styles";
import { Login } from "../loginCard";
import { SignUp } from "../registerCard";
import { VisitCard } from "../../visitCard";

//componente que permite o carrossel das telas mobile
export const MobileCredentials = () => {
  const [classCenter, setClassCenter] = useState("active");
  const [classRight, setClassRight] = useState("right");
  const [classLeft, setClassLeft] = useState("left");

  const classTransitions = {
    next: {
      active: { center: "left", right: "active", left: "right" },
      left: { center: "right", right: "left", left: "active" },
      right: { center: "active", right: "right", left: "left" },
    },
    prev: {
      active: { center: "right", right: "left", left: "active" },
      right: { center: "left", right: "active", left: "right" },
      left: { center: "active", right: "right", left: "left" },
    },
  };

  const next = () => {
    const transitions = classTransitions.next[classCenter];
    setClassCenter(transitions.center);
    setClassRight(transitions.right);
    setClassLeft(transitions.left);
  };

  const prev = () => {
    const transitions = classTransitions.prev[classCenter];
    setClassCenter(transitions.center);
    setClassRight(transitions.right);
    setClassLeft(transitions.left);
  };

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // A distancia minima de deslize na tela para ativar as funções onTouchStart e onTouchEnd
  const minSwipeDistance = 50;

  //inicia a contagem do deslize
  const onTouchStart = (e) => {
    setTouchEnd(null); // caso contrario a função seria executada mesmo sem o toque ser finalizado
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  //quando o dedo é retirado, finaliza o movimento
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe || isRightSwipe) isLeftSwipe ? next() : prev();
  };

  return (
    <Container
      className="inicialScreenMobileContainer"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Login
        firstLogin={true}
        onClick={() => prev()}
        className={`card card--${classCenter}`}
      />
      <VisitCard
        onClick={() => prev()}
        className={`card card--${classRight}`}
      />
      <SignUp onClick={() => next()} className={`card card--${classLeft}`} />
    </Container>
  );
};
