import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useEffect } from "react";
import { ToolsAnaliseDrawer } from "../swipeModal/toolsAnalise";
import { useGenText } from "../../Contexts/iaRequests";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#FF6300",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#FF6300",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#FF6300 0%,#FF6300 50%,#FF6300 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#FF6300 0%,#FF6300 50%,#FF6300 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#FF6300",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "#FF6300",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BorderColorOutlinedIcon />,
    2: <SettingsIcon />,
    3: <ChecklistOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export const AnaliseStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { themes } = useThemeChanger();
  const { genlia, type } = useGenText();
  const [disabled, setDisabled] = useState(false);

  //ferramentas selecionadas para edição da redação
  const [selectedTools, setSelectedTools] = useState("");
  const validationSchema = yup.object({
    prompt: yup
      .string()
      .required("Digite seu texto.")
      .min(10, "Mínimo 10 caracteres")
      .max(8000, "Máximo 8000 caracteres"),
  });

  const formik = useFormik({
    initialValues: {
      prompt: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!values.prompt) {
        handleReset();
      } else if (selectedTools === "") {
        handleBack();
      } else {
        const dataFormated = {
          prompt: { tema: values.prompt, modo: selectedTools },
          type: type,
        };
        genlia(dataFormated);
      }
    },
  });

  useEffect(() => {
    if (activeStep === 0) {
      if (
        formik.values.prompt.length < 10 ||
        formik.values.prompt.length > 8000
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else if (activeStep === 1) {
      if (selectedTools === "") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [formik.values.prompt, selectedTools, activeStep]);

  const steps = [
    {
      name: "Texto",
      child: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            "& .form": {
              overflowY: "scroll",
              py: 1,
              border: `1px solid ${themes.liaMainColor}`,
              borderRadius: 2,
              maxHeight: "100%",
              "::-webkit-scrollbar ": {
                width: "5px",
              },

              "::-webkit-scrollbar-track": {
                background: themes.background,
                margin: "1rem 0",
              },

              "::-webkit-scrollbar-thumb": {
                backgroundColor: themes.highlight,
                borderRadius: "20px",
              },
            },
          }}
        >
          <form className="form" onSubmit={formik.handleSubmit}>
            <TextField
              id="prompt"
              name="prompt"
              placeholder="Insira o texto desejado."
              label="Insira o texto desejado."
              multiline
              type="text"
              value={formik.values.prompt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: 15,
                  color: formik.errors.prompt ? "red" : themes.liaMainColor,
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 15,
                  color: formik.errors.prompt ? "red" : themes.liaMainColor,
                },
              }}
              sx={{
                position: "relative",
                width: ["100%"],
                "& fieldset": {
                  border: `0px solid ${themes.liaMainColor}`,
                },
                "& .css-s4urx7.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: `0px solid ${themes.liaMainColor}`, // Substitua com a cor desejada
                },
                "& .css-17njh4a-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: `0px solid ${themes.liaMainColor}`, // Substitua com a cor desejada
                  },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          </form>
          <Typography
            sx={{
              fontSize: "12px",
              color: formik.errors.prompt
                ? themes.highlight
                : themes.liaMainColor,
            }}
          >
            {`${formik.values.prompt.length}/8000`}
            {`${formik.errors.prompt ? " - " + formik.errors.prompt : ""}`}
          </Typography>
        </Box>
      ),
    },
    {
      name: "Opções",
      child: (
        <>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              mb: [4, 4, 3, 5],
              fontSize: [16, 16, 20, 24],
            }}
          >
            O que deseja que eu analise ou crie?
          </Typography>
          <ToolsAnaliseDrawer
            selectedTools={selectedTools}
            setSelectedTools={setSelectedTools}
          />
        </>
      ),
    },
    {
      name: "Revisão",
      child: (
        <Box sx={{}}>
          <Typography
            sx={{ fontSize: [16, 16, 20, 24], fontWeight: 600, mb: 5 }}
          >
            Revise suas escolhas antes de confirmar!
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: themes.liaMainColor,
              mt: 2,
              fontWeight: 600,
              fontSize: [16, 16, 20, 24],
            }}
          >
            Parâmetros:
          </Typography>
          <Typography sx={{ fontSize: [14, 18, 20, 20] }}>
            Texto:{" "}
            <b style={{ color: themes.highlight }}>
              {formik.values.prompt.length > 20
                ? formik.values.prompt.slice(0, 20).concat("...")
                : formik.values.prompt}
            </b>
          </Typography>
          <Typography sx={{ fontSize: [14, 18, 20, 20] }}>
            Modo: <b style={{ color: themes.highlight }}>{selectedTools}</b>
          </Typography>
        </Box>
      ),
    },
  ];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }} spacing={4}>
      <Stepper
        id="Box input"
        data-intro="Primeiro digite o que é solicidatado, escolha as opções que mais te agradam e depois revise seu conteúdo, com tudo pronto, envie e aguarde"
        data-step="5"
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        style={{ color: themes.liaMainColor }}
      >
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.name + index} {...stepProps}>
              <StepLabel
                sx={{ color: themes.liaMainColor }}
                {...labelProps}
                StepIconComponent={ColorlibStepIcon}
              >
                <Typography
                  sx={{ color: themes.liaMainColor }}
                  variant="caption"
                >
                  {step.name}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Gerando texto, por favor aguarde!
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Novo</Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            id={"scroll_Box"}
            sx={{
              height: "100%",
              width: ["100%", "100%", "80%", "80%"],
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              "::-webkit-scrollbar ": {
                width: "5px",
              },

              "::-webkit-scrollbar-track": {
                background: themes.background,
                margin: "1rem 0",
              },

              "::-webkit-scrollbar-thumb": {
                backgroundColor: themes.highlight,
                borderRadius: "20px",
              },
            }}
          >
            <>{steps[activeStep].child}</>
          </Box>
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              color: themes.liaMainColor,
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                mr: 1,
                color: "#fff",
                background: activeStep === 0 ? "none" : themes.textGenerateBg,
                ":hover": {
                  background: themes.hoverButton,
                },
              }}
            >
              Voltar
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              id="Box input"
              data-intro="Este botão será liberado assim que você cumprir com as necessidades de cada passo."
              data-step="6"
              type="submit"
              sx={{
                color: "#fff",
                background: disabled ? "none" : themes.textGenerateBg,
                ":hover": {
                  background: themes.hoverButton,
                },
              }}
              disabled={disabled}
              onClick={() => {
                activeStep === steps.length - 1
                  ? formik.handleSubmit()
                  : handleNext();
              }}
            >
              {activeStep === steps.length - 1 ? "Analizar " : "Proximo"}
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};
