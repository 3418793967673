import { Box, Container } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { LinkTo } from "./style";

//Componente localizado na tela de login/registro, (caixa roxa e laranja)
export const VisitCard = ({ className, onClick }) => {
  const { themes } = useThemeChanger();
  return (
    <Container
      id="lia"
      className={className}
      sx={{
        minWidth: ["80%", "80%", "auto", "auto"],
        height: ["75vh", "75vh", "100%", "100%"],
        background: " rgb(154, 5, 141)",
        background:
          "-moz-radial-gradient(circle, rgba(154, 5, 141, 1) 0%, rgba(255, 99, 0, 1) 100%)",
        background:
          "-webkit-radial-gradient(circle,rgba(154, 5, 141, 1) 0%,rgba(255, 99, 0, 1) 100%)",
        background:
          "radial-gradient(circle,rgba(154, 5, 141, 1) 0%,rgba(255, 99, 0, 1) 100%)",
        color: themes.text,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          height: "100%",
        }}
      >
        <h1>Inteligencia artificial!</h1>
        <p>
          A Lia é uma Inteligência Artificial prática, fácil e rápida de ser
          acessada através do seu WhatsApp. Clique abaixo e acesse a Lia.{" "}
        </p>
        <LinkTo
          color={themes}
          className="ghost"
          href="https://falecomlia.com.br/"
          rel="noreferrer"
          target="_blank"
        >
          Acesse o site
        </LinkTo>
      </Box>
    </Container>
  );
};
