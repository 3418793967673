import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { MenuItem, Select, Typography } from "@mui/material";
import { removerAcentos } from "../../utils";

const tools = [
  {
    style: "select",
    name: "Forma",
    type: "forma",
    options: ["Leigo", "Didático", "Técnico", "Especialista"],
  },
  {
    style: "select",
    name: "Método",
    type: "Metodo",
    options: [
      "Comparação",
      "Passo a Passo",
      "Analogias",
      "Histórias",
      "Exemplos",
      "Abordagem Socrática",
    ],
  },
];

//Componente que controla o menu de ferramentas para texto
export const ToolsExplicaDrawer = ({ selectedTools, setSelectedTools }) => {
  const { themes } = useThemeChanger();

  //Função que verificar as keys do objeto e define verdadeiro ou falso para alterar a cor do botão
  //no menu de ferramentas
  const verifySelectedCard = (buttonText) => {
    if (Object.values(selectedTools).includes(buttonText)) {
      return true;
    }
    return false;
  };

  //Função que troca apenas o item selecionado
  const handleChangeOptionCard = (option, buttonText) => {
    setSelectedTools((prevState) => ({
      ...prevState,
      [option.toLowerCase()]: buttonText,
    }));
  };

  //Função que troca apenas o item selecionado
  const handleChange = (item, event) => {
    const value = event.target.value;
    setSelectedTools((prevState) => ({
      ...prevState,
      [item.toLowerCase()]: value,
    }));
  };
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        gap: "16px",
        maxWidth: "800px",
      }}
    >
      {tools.map((item, index) =>
        item.style === "select" ? (
          <Box
            key={item.type + index}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography sx={{ fontWeight: [600, 600, 800, 800] }}>
              {item.name}:
            </Typography>
            <Select
              sx={{
                width: "auto",
                color: themes.liaMainColor,
                borderRadius: "15px",
                border: `1px solid ${themes.liaMainColor}`,
                "& .Mui-focused": {
                  border: `1px solid ${themes.highlight}`,
                },
                "& .MuiSvgIcon-root": {
                  color: themes.liaMainColor,
                  background: themes.background,
                  borderRadius: "100%",
                  margin: [0, 0, 0, 0],
                  transition: "0.5s",
                },
              }}
              key={item.type + index}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedTools[item.type.toLowerCase()]}
              onChange={(e) => handleChange(item.type, e)}
            >
              {item.options.map((option, i) => (
                <MenuItem key={option + i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: [600, 600, 800, 800] }}>
              {item.name}:
            </Typography>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              {item.options.map((option, i) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    onClick={(e) => {
                      handleChangeOptionCard(
                        item.type,
                        option.name
                          ? option.name
                            ? option.name
                            : option
                          : option
                      );
                      e.stopPropagation();
                    }}
                    sx={{
                      textAlign: "center",
                      width: option.name ? "50px" : "auto",
                      height: option.name ? "50px" : "auto",
                      px: option.name ? 2 : 2,
                      py: option.name ? 0 : 1,
                      color: verifySelectedCard(
                        option.name ? option.name : option
                      )
                        ? themes.text
                        : themes.liaMainColor,
                      background: verifySelectedCard(
                        option.name ? option.name : option
                      )
                        ? themes.highlight
                        : "none",
                      border: `1px solid ${themes.highlight}`,
                      borderRadius: 3,
                      fontSize: 18,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      ":hover": {
                        color: themes.text,
                        background: themes.highlight,
                      },
                    }}
                  >
                    {option.icon && option.icon}
                    {!option.icon && <Typography>{option}</Typography>}
                  </Box>
                  {option.icon && <Typography>{option.name}</Typography>}
                </Box>
              ))}
            </Box>
          </Box>
        )
      )}
    </Box>
    // <Box
    //   sx={{
    //     height: "100%",
    //     background: themes.background,
    //   }}
    //   role="presentation"
    // >
    //   <List sx={{}}>
    //     {tools.map((item, index) => (
    //       <ListItem
    //         id={index}
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           border: `1px solid ${themes.liaMainColor}`,
    //           borderRadius: 2,
    //           my: 1,
    //         }}
    //         key={item.type + index}
    //         disablePadding
    //       >
    //         <ListItemText
    //           sx={{
    //             pl: 1,
    //             width: "90%",
    //             borderBottom: `1px solid ${themes.liaMainColor}`,
    //             color: themes.liaMainColor,
    //             "& .MuiListItemText-primary": { fontWeight: 700 },
    //           }}
    //           primary={item.type}
    //         />
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexWrap: "wrap",
    //             width: "100%",
    //             gap: 1,
    //             p: 1,
    //           }}
    //         >
    //           {item.options.map((option, i) => (
    //             <ListItemButton
    //               onClick={(e) => {
    //                 handleChangeOptionCard(item.type, option);
    //                 e.stopPropagation();
    //               }}
    //               sx={{
    //                 textAlign: "center",
    //                 width: "auto",
    //                 px: "8px",
    //                 color: verifySelectedCard(option)
    //                   ? themes.text
    //                   : themes.liaMainColor,
    //                 background: verifySelectedCard(option)
    //                   ? themes.highlight
    //                   : "none",
    //                 border: `1px solid ${themes.highlight}`,
    //                 borderRadius: 3,
    //                 fontSize: 18,
    //                 ":hover": {
    //                   color: themes.text,
    //                   background: themes.highlight,
    //                 },
    //               }}
    //             >
    //               <ListItemText primary={option} />
    //             </ListItemButton>
    //           ))}
    //         </Box>
    //       </ListItem>
    //     ))}
    //   </List>
    // </Box>
  );
};
