import * as React from "react";
import { Box, Button, Select } from "@mui/material";
import { FaBuilding, FaPlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { Img } from "./style";
import { compareData } from "../../utils";
import { useCompany } from "../../Contexts/companyProvider";
import { DashboardSwipeDrawer } from "../swipeModal/dashboardSwipe";
import { ModalLogOff } from "../modal";
import { useUser } from "../../Contexts/UserProvider";
import Cookies from "js-cookie";
import introJs from "intro.js";
import "intro.js/introjs.css";

//componente de Cabeçalho
export const HeaderComponent = ({ id, intro, step, startAnimation, where }) => {
  const navigate = useNavigate();
  const { themes } = useThemeChanger();
  const { empresas } = useCompany();
  const { firstTime, setFirstTime } = useUser();
  const [company, setCompany] = useState("");
  const [verify, setVerify] = useState("false");
  const [companyList, setCompanyList] = useState([]);

  //Define a empresa selecionada no cabeçalho
  const handleChange = (event) => {
    setCompany(event.target.value);
    const value = empresas.find((el) => el.company_name === event.target.value);
    sessionStorage.setItem("@social:company", value.company_tag);
  };

  //verifica se a empresa foi trocada e define no sessionStorage para uso futuro
  useEffect(() => {
    if (empresas.length > 0) {
      setCompanyList(empresas.map((el) => el?.company_name));
      const sessionCompany = sessionStorage.getItem("@social:company");
      const companySelected = empresas.find(
        (el) => el.company_tag === sessionCompany
      );
      setCompany(companySelected?.company_name);
    }
  }, [empresas]);

  //Compara a hash do local storage para saber se o usuario foi autenticado
  useEffect(() => {
    const hash = Cookies.get("social:ha");
    if (hash !== null) {
      setVerify(hash);
    } else {
      setVerify(verify);
    }
  }, [Cookies.get("social:ha")]);

  useEffect(() => {
    compareData("true", verify) ? setVerify("true") : setVerify("false");
  }, [verify]);

  const iconStyle = {
    minWidth: "20px",
    minHeight: "20px",
    color: themes.highlight,
    marginRight: 10,
  };

  const [open, setOpen] = useState(false);
  const toggleLogout = () => {
    open ? setOpen(false) : setOpen(true);
  };

  //Se for a primeira vez do usuario no site ele ira ver o tutorial
  useEffect(() => {
    if (firstTime) {
      //Marque a visita atual com um cookie
      Cookies.set("visitedBefore", "true", { expires: 365 }); //Define o cookie por 1 ano
      introJs().start();
      setFirstTime(false);
    }
  }, [firstTime, setFirstTime]);
  return (
    <Box
      className="headerBox"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: themes.headerbackground,
        boxShadow: "0px 5px 5px rgba(0,0,0,0.25)",
        width: `100vw`,
        padding: ["0.5rem", "0.5rem 1rem", "1rem 2rem", "1rem 3rem"],
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: [2, 3, 5, 8],
        }}
      >
        <Img
          className="headerBox--logo"
          src={themes.src.logo}
          alt="texto Fale com Lia social."
          onClick={() => {
            startAnimation();
          }}
        />
        {where !== "profile" && (
          <Box
            id="botao1"
            data-intro={
              company
                ? "Aqui você pode trocar sua empresa ou adicionar uma nova (Limite maximo:5 empresas)"
                : "Clique aqui para adicionar sua empresa"
            }
            data-step="1"
            className="headerBox--companiesBox"
            sx={{ display: "flex", alignItems: "center", gap: [1, 1, 2, 2] }}
          >
            {company ? (
              <>
                <Select
                  className="headerBox--companiesBox--addButton"
                  id="demo-simple-select"
                  value={company}
                  defaultValue={company}
                  onChange={handleChange}
                  sx={{
                    gap: 1,
                    background: themes.background,
                    color: themes.liaMainColor,
                    borderRadius: "15px",
                    boxShadow: `0px 0px 5px ${themes.borderColor}`,
                    fontWeight: 900,
                    fontSize: [14, 14, 14, 16],
                    height: 50,
                    width: [150, 150, 200, 200],
                    transition: "0.5s",
                    position: "relative",
                    overflow: "hidden",
                    "& div": { display: "flex", width: "100%" },
                    "& .MuiSvgIcon-root": {
                      color: themes.liaMainColor,
                      background: themes.background,
                      borderRadius: "100%",
                      margin: [0, 0, 0, 0],
                      right: -2,
                      transition: "0.5s",
                    },
                  }}
                >
                  {empresas &&
                    companyList.map((el, i) => (
                      <MenuItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          position: "sticky",
                        }}
                        key={`${el}-${i}`}
                        value={el}
                        onClick={() => {}}
                      >
                        <FaBuilding style={iconStyle} />

                        {el}
                      </MenuItem>
                    ))}
                </Select>
                {companyList.length < 5 && (
                  <Button
                    className="headerBox--companiesBox--addButton"
                    sx={{
                      display: [`none`, `none`, `block`, `block`],
                      color: themes.highlight,
                      fontSize: 24,
                      transition: "0.5s",
                      ":hover": {
                        background: "none",
                        transform: "scale(1.2)",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => navigate("/cadastra_empresa")}
                  >
                    <FaPlusCircle />
                  </Button>
                )}
              </>
            ) : (
              <Button
                sx={{
                  width: "100%",
                  height: ["2rem"],
                  bgcolor: themes.background,
                  color: themes.liaMainColor,
                  border: `1px solid ${themes.liaMainColor}`,
                  fontSize: 12,
                  transition: "0.5s",
                  ":hover": { transform: "scale(1.1)" },
                }}
                onClick={() => navigate("/cadastra_empresa")}
              >
                adicionar empresa
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{ color: themes.textGray, display: "flex", gap: 5, fontSize: 24 }}
      >
        {where !== "profile" ? (
          <DashboardSwipeDrawer
            id={id}
            intro={intro}
            step={step}
            startAnimation={startAnimation}
          />
        ) : (
          <ModalLogOff
            open={open}
            handleOpen={() => toggleLogout()}
            handleClose={() => toggleLogout()}
          />
        )}
      </Box>
    </Box>
  );
};
