import { Box, Button, Typography } from "@mui/material";
import { FaCoins } from "react-icons/fa";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Cartão de compras de token
export const BuyTokenCard = ({ amount, bonus, price, onClick }) => {
  const { themes } = useThemeChanger();
  return (
    <Box
      className="tokenCardBox"
      sx={{
        boxShadow: `0 0 9px ${themes.textGray}`,
        color: themes.highlight,
        borderRadius: 5,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: [130, 200, 250, 250],
        height: [200, 300, 350, 350],
      }}
    >
      <FaCoins
        className="tokenCardBox--coinIcon"
        style={{ width: "50%", height: "50%" }}
      />
      <Box
        className="tokenCardBox--descriptionBox"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: [16, 20, 24, 28],
              textAlign: "center",
            }}
          >
            {amount} <span style={{ color: themes.liaMainColor }}> Tokens</span>
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: 900, mb: 2, fontSize: [10, 10, 14, 14] }}>
          +{bonus} bônus
        </Typography>
        <Button
          className="tokenCardBox--descriptionBox--button"
          onClick={() => {
            onClick();
          }}
          sx={{
            background: themes.highlight,
            color: "white",
            borderRadius: 2,
            width: "90%",
            ":hover": {
              background: themes.hoverButton,
            },
            fontSize: [10, 10, 14, 14],
          }}
        >
          R$ {price}
        </Button>
      </Box>
    </Box>
  );
};
