import { useState } from "react";
import { useEffect } from "react";
import { HistoryCard } from "./historyCard";
import { dashboardCards } from "../../utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AiOutlineClear } from "react-icons/ai";
import { useUser } from "../../Contexts/UserProvider";
import { useThemeChanger } from "../../Contexts/themeProvider";
import {
  Box,
  Button,
  InputBase,
  Popover,
  Tab,
  Tabs,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "0px",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
//componente de historico de textos gerados
export const Historico = () => {
  const { historyCards, getHistory } = useUser();
  const { themes } = useThemeChanger();

  const [filteredItems, setfilteredItems] = useState(historyCards);
  const [tag, setTag] = useState("");
  const [location, setLocation] = useState("Marketing");
  const [isFavorite, setIsFavorite] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //Faz a requisição para pegar os itens do historico
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (historyCards.length === 0 && count === 0) {
      getHistory(location, 1);
    }
    setCount(1);
    setfilteredItems(historyCards);
  }, [historyCards]);

  //controla o filtro de items
  useEffect(() => {
    const arr = [...historyCards];
    if (isFavorite) {
      if (tag) {
        setfilteredItems(
          arr
            .filter((el) => el.location === tag)
            .sort((a, b) => b.favorite - a.favorite)
        );
      } else {
        setfilteredItems(arr.sort((a, b) => b.favorite - a.favorite));
      }
    } else {
      if (tag) {
        setfilteredItems(arr.filter((el) => el.location === tag));
      } else {
        setfilteredItems(arr);
      }
    }
  }, [isFavorite, tag, historyCards]);

  const handleSearch = (e) => {
    const arr = [...historyCards];
    const text = e.currentTarget.value;

    setfilteredItems(
      arr.filter((el) => el.prompt.toLowerCase().includes(text.toLowerCase()))
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [index, setIndex] = useState(0);
  const handleClick = (event, i, name) => {
    if (location !== name) {
      setIndex(i);
      setLocation(name);
      getHistory(name, 1);
      setPageSelected(1);
      setTag("");
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Controladores de paginação
  const [pageSelected, setPageSelected] = useState(1);
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);

  const handleNextPage = () => {
    const page = pageSelected + 1;
    if (filteredItems.length >= 10) {
      setPageSelected(() => pageSelected + 1);
      getHistory(location, page);
    }
  };
  const handlePreviewPage = () => {
    const page = pageSelected - 1;

    if (pageSelected > 1) {
      setPageSelected(() => pageSelected - 1);
      getHistory(location, page);
    }
  };

  useEffect(() => {
    if (filteredItems.length < 10) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
    if (pageSelected === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }
  }, [filteredItems, pageSelected]);

  return (
    <Box
      className="historyPromptContainer"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "85vh",
        maxHeight: "auto",
        minWidth: ["90vw", "70vw", "50vw", "50vw"],
        maxWidth: ["90vw", "70vw", "50vw", "50vw"],
        borderRadius: 5,
        transition: "1s",
        padding: "1rem ",
        position: "relative",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Search sx={{ background: themes.background, alignSelf: "center" }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Pesquise por prompt"
          onChange={(e) => handleSearch(e)}
        />
      </Search>
      <Box sx={{ maxWidth: "100%" }}>
        <Tabs
          className="historyPromptContainer--filters"
          value={value}
          onChange={handleChange}
          // centered
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          textColor={themes.highlight}
          indicatorColor={themes.highlight}
          sx={{
            display: "flex",
            pb: 1,
            "& button": {
              padding: "12px",
              fontSize: [10, 12, 14, 16],
            },
            "& .Mui-selected": {
              color: themes.highlight,
            },
            "& .MuiTabs-indicator": { background: themes.highlight },
            "& .MuiTabs-centered": { display: "flex", gap: 1 },
          }}
        >
          {dashboardCards.map((item, i) => (
            <Tab
              key={item.topic + i}
              sx={{ display: item.disabled ? "none" : "flex" }}
              tabIndex={i}
              label={item.topic}
              onClick={(event) => handleClick(event, i, item.topic)}
            />
          ))}

          <Box>
            {dashboardCards[index].items && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {dashboardCards[index].items.map((el, i) => (
                  <Box
                    key={`${el.title}-${i}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      padding: 1.5,
                      background: themes.background,
                    }}
                  >
                    <Button
                      sx={{
                        display: `flex`,
                        justifyContent: `start`,
                        gap: 1,
                        padding: [0, 0, 0, 0],
                        border: "none",
                        background: "none",
                        borderBottom: `1px solid ${themes.liaMainColor}`,
                        color: themes.liaMainColor,
                        fontWeight: 600,
                        borderRadius: 0,
                        transition: `0.3 s`,
                        ":hover": {
                          color: themes.highlight,
                        },
                      }}
                      onClick={() => {
                        handleClose();
                        setTag(el.tag);
                      }}
                    >
                      {el.icon()}
                      {el.title}
                    </Button>
                  </Box>
                ))}

                <Button
                  className="historyPromptContainer--filters--clear"
                  sx={{
                    display: `flex`,
                    justifyContent: `start`,
                    alignSelf: `start`,
                    justifySelf: `start`,
                    width: `100%`,
                    gap: 1,
                    padding: "12px 12px 0 12px",
                    border: "none",
                    background: themes.background,
                    borderBottom: `1px solid ${themes.liaMainColor}`,
                    color: themes.liaMainColor,
                    fontWeight: 600,
                    borderRadius: 0,
                    transition: `0.3 s`,
                    ":hover": {
                      background: themes.background,

                      color: themes.highlight,
                    },
                  }}
                  onClick={() => {
                    handleClose();
                    setTag("");
                  }}
                >
                  <AiOutlineClear /> Limpar
                </Button>
              </Popover>
            )}
          </Box>

          {/* <Box
          sx={{
            display: "flex",
            gap: 2,
            "& button": {
              minWidth: 24,
              fontSize: 24,
            },
            zIndex: 1,
          }}
        >
          <Button
            className="historyPromptContainer--favButton"
            onClick={() => {
              isFavorite ? setIsFavorite(false) : setIsFavorite(true);
            }}
            sx={{
              color: isFavorite ? themes.highlight : themes.liaMainColor,
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
          <FaStar />
          </Button>
          <Button
            className="historyPromptContainer--reloadButton"
            onClick={getHistory}
            sx={{
              color: themes.highlight,
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
          <BsArrowRepeat />
          </Button>
        </Box> */}
        </Tabs>
      </Box>

      <Box
        className="historyPromptContainer--cardContainer"
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          py: 1,
          pr: 2,
          "::-webkit-scrollbar ": {
            width: "5px",
          },

          "::-webkit-scrollbar-track": {
            background: "none",
            margin: "1rem 0",
          },

          "::-webkit-scrollbar-thumb": {
            backgroundColor: themes.highlight,
            borderRadius: "20px",
          },
        }}
      >
        {filteredItems ? (
          filteredItems.map((el, i) => (
            <HistoryCard
              key={el.register_id}
              historicItem={el}
              duration={i / 2}
            />
          ))
        ) : (
          <Box
            className="historyCardContainer--emptyCards"
            sx={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              alignItems: `center`,
              width: `100%`,
              height: `100vh`,
            }}
          >
            <Typography sx={{ textAlign: `center` }}>
              Aparentemente você ainda não possui nenhum texto neste tópico.
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          "& button": {
            color: themes.liaMainColor,
            ":hover": {
              color: themes.highlight,
              background: "none",
            },
          },
        }}
      >
        <Button disabled={disabledPrev} onClick={handlePreviewPage}>
          <ArrowBackIosIcon />
        </Button>
        <Button disabled={disabledNext} onClick={handleNextPage}>
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  );
};
