import { Box, Container, Typography } from "@mui/material";
import { WhatsInput } from "./whatsNumberInput";
import { NumberFields } from "./codeNumberInput";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente principal que une as validações de 2FA, no caso,
//valida o numero de whatsapp e o codigo inserido pelo usuario

export const TwoFactorAuth = () => {
  const { themes } = useThemeChanger();
  return (
    <Container
    className="container2FA"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        margin: [0, 0, 0, 0],
        padding: [0, 0, 0, 0],
        gap: 4,
      }}
    >
      <Box className='container2FA--box--whatsappValidate' sx={{ display: "flex", width: ["80vw", "80vw", "30vw", "30vw"] }}>
        <WhatsInput />
      </Box>
      <Box  className='container2FA--box--codeValidate'>
        <Typography sx={{ color: themes.highlight }}>
          Insira aqui o código recebido.
        </Typography>
        <NumberFields bgColor={"rgba(0,0,0,0.03)"} />
      </Box>
    </Container>
  );
};
