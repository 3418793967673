import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { useUser } from "../../Contexts/UserProvider";
import { FaSignOutAlt } from "react-icons/fa";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { NumberFields } from "../2FAComponent/codeNumberInput";
import { Timer } from "../timerComponent";
import { ButtonComponent } from "../button";
import { motion } from "framer-motion";
import { useState } from "react";
import { useCompany } from "../../Contexts/companyProvider";
import { useNavigate } from "react-router-dom";


//Modal de premiados (resultado sorteios kremer)
export const ModalPremiados = ({ open, handleClose, premiados }) => {
  return (
    <Modal
      className="modalprized"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: 350,
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", mb: "1rem" }}
        >
          Prêmiados
        </Typography>
        <Box
          className="modalPrized--prized"
          sx={{
            minWidth: 350,
            maxHeight: 500,
            overflowX: "hidden",
            bgcolor: "background.paper",
            p: 4,
          }}
        >
          {premiados &&
            premiados.map((el, i) => (
              <Box
                key={`${el.nome} - ${i}`}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 3,
                  mb: 1,
                  borderBottom: "1px solid black",
                }}
              >
                <Tooltip title={el.nome}>
                  <Typography variant="p" id="modal-modal-description">
                    {el.nome.length > 15
                      ? el.nome.split("").splice(0, 15).join("") + "..."
                      : el.nome}
                  </Typography>
                </Tooltip>
                <Typography variant="p" id="modal-modal-description">
                  {el.cidade}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

//Modal de status dos sorteios kremer
export const ModalStatusSorteio = ({ open, handleClose, arr }) => {
  return (
    <Modal
      className="modalStatus"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: 350,
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          gap: 2,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          sx={{ textAlign: "center", mb: "2rem" }}
        >
          {arr.nome}
        </Typography>
        <Box
          className="modalStatus--info"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "center",
            gap: 3,
          }}
        >
          <Box
            className="modalStatus--info--start"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 16, fontWeight: 500 }}
              id="modal-modal-description"
            >
              Data inicio:
            </Typography>
            <Typography variant="p" id="modal-modal-description">
              {arr.data_inicio}
            </Typography>
          </Box>
          <Box
            className="modalStatus--info--end"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 16, fontWeight: 500 }}
              id="modal-modal-description"
            >
              Data final:
            </Typography>
            <Typography variant="p" id="modal-modal-description">
              {arr.data_final}
            </Typography>
          </Box>
          <Box
            className="modalStatus--info--status"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 16, fontWeight: 500 }}
              id="modal-modal-description"
            >
              Status:
            </Typography>
            <Typography variant="p" id="modal-modal-description">
              {arr.status}
            </Typography>
          </Box>
          <Box
            className="modalStatus--info--totalPrize"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 16, fontWeight: 500 }}
              id="modal-modal-description"
            >
              Total de sorteios:
            </Typography>
            <Typography variant="p" id="modal-modal-description">
              {arr.total_sorteios}
            </Typography>
          </Box>
          <Box
            className="modalStatus--info--frequency"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 16, fontWeight: 500 }}
              id="modal-modal-description"
            >
              Frequencia de sorteios:
            </Typography>
            <Typography variant="p" id="modal-modal-description">
              {arr.frequencia_sorteios}
            </Typography>
          </Box>
          <Box
            className="modalStatus--info--time"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: 16, fontWeight: 500 }}
              id="modal-modal-description"
            >
              Horario dos sorteios:
            </Typography>
            <Typography
              variant="p"
              id="modal-modal-description"
              sx={{ pb: "6px" }}
            >
              30/06 - 18h00 - 18h30 - 19h00 - 19h30 - 20h00 - 20h30 - 21h00 -
              21h30 <br />
              <hr />
              01/07 - 18h30 - 19h00 - 19h30 - 20h30 - 21h00 - 21h30 -22h00 -
              22h30 <br />
              <hr />
              02/07 - 12h30 - 14h00 - 16h30 - 18h00 - 18h30 - 19h30 - 20h00 -
              21h00
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

//Modal de Logoff
export const ModalLogOff = ({ open, handleOpen, handleClose }) => {
  const { signOut } = useUser();
  const { themes } = useThemeChanger();
  return (
    <div>
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: [0, 0, 0, 0],
          minWidth: "24px",
        }}
        onClick={handleOpen}
      >
        <FaSignOutAlt
          style={{
            fontSize: "24px",
            fontWeight: "thin",
            color: themes.textGray,
          }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          background: "rgba(0,0,0,0.5)",
          boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: "blur( 5px )",
          WebkitBackdropFilter: "blur( 5px )",
        }}
      >
        <Box
          sx={{
            height: `100%`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: `10px`,
            p: 4,
            color: themes.highlight,
            gap: 5,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ fontSize: [20, 28, 36, 48], fontWeight: 900 }}
          >
            Realmente deseja sair?
          </Typography>
          <Box sx={{ display: "flex", gap: 5 }}>
            <ButtonComponent width={"200px"} text="Sim" onClick={signOut} />

            <ButtonComponent
              color={"#055C9A"}
              text="Não"
              onClick={handleClose}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

//Modal de validação ao acessar a dashboard
export const ModalValidateWhats = () => {
  const { themes } = useThemeChanger();
  const { signOut } = useUser();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        background: "rgba(0,0,0,0.9)",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 5px )",
        WebkitBackdropFilter: "blur( 5px )",
        position: "absolute",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
          padding: [`16px 8px`, `16px 8px`, 5, 5],
        }}
      >
        <Typography
          sx={{
            fontSize: [20, 28, 36, 48],
            fontWeight: 900,
            color: themes.highlight,
          }}
        >
          Verifique seu Whatsapp
        </Typography>
        <Typography
          sx={{
            fontSize: [16, 20, 28, 36],
            fontWeight: 900,
            color: themes.text,
          }}
        >
          Digite o código recebido:
        </Typography>
        <NumberFields bgColor={"rgba(255,255,255,0.2)"} />
        <Timer fontSize={[12, 16, 20, 24]} callback={signOut} />
        <ButtonComponent
          text={"Sair"}
          width={"50%"}
          onClick={() => signOut()}
        />
      </Box>
    </Box>
  );
};

//Modal de confirmação de cadastro de empresa
export const ModalRegisterCompany = ({ handleClear }) => {
  const { themes } = useThemeChanger();

  const { open, setOpen, empresas } = useCompany();
  const [isAnimating, setIsAnimating] = useState(false);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleAnimationComplete = () => {
    setOpen(false);
    setIsAnimating(false);
    if (value === 0) {
      navigate("/dashboard");
    } else {
      handleClear();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          background: "rgba(0,0,0,0.5)",
          boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: "blur( 5px )",
          WebkitBackdropFilter: "blur( 5px )",
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isAnimating ? 0 : 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          onAnimationComplete={isAnimating && handleAnimationComplete}
        >
          <Box
            sx={{
              height: `100vh`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: `10px`,
              p: 4,
              color: themes.highlight,
              gap: 5,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{
                fontSize: [20, 28, 36, 48],
                fontWeight: 900,
                textAlign: "center",
              }}
            >
              Empresa Cadastrada com sucesso!
              <Typography
                id="modal-modal-title"
                sx={{
                  fontSize: [10, 14, 18, 24],
                  fontWeight: 900,
                  textAlign: "center",
                }}
              >
                {`${empresas.length}/5 empresas cadastradas`}
              </Typography>
            </Typography>
            <Box sx={{ display: "flex", gap: 5 }}>
              <ButtonComponent
                width={"200px"}
                text="Continuar"
                onClick={() => {
                  setValue(0);
                  setIsAnimating(true);
                }}
              />
              {empresas.length < 5 && (
                <ButtonComponent
                  color={"#055C9A"}
                  width={"200px"}
                  text="Cadastrar nova empresa"
                  onClick={() => {
                    setValue(1);
                    setIsAnimating(true);
                  }}
                />
              )}
            </Box>
          </Box>
        </motion.div>
      </Modal>
    </div>
  );
};
