import { api } from "../Services/api";
import "react-toastify/dist/ReactToastify.css";
import { createContext, useCallback, useContext, useState } from "react";
import { useUser } from "./UserProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const BountyContext = createContext({});

export const useBounty = () => {
  const context = useContext(BountyContext);
  if (!context) {
    throw new Error("useBounty must be used within an BountyProvider");
  }
  return context;
};

//Context responsavel por gerenciamento de tokens
export const BountyProvider = ({ children }) => {
  const [balance, setBalance] = useState();
  const [transactions, setTransactions] = useState([]);
  const [tokensPackage, setTokensPackage] = useState([]);
  const [bonusCards, setBonusCards] = useState([]);
  const navigate = useNavigate();

  const { signOut, setIsLoading } = useUser();
  //Pega o valor total de tokens que o usuario possui
  const getBalance = useCallback(async () => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    await api
      .get("/balance", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBalance(Number(res.data.balance));
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status === 429) {
          toast.error("Limite de chamadas atingido. Aguarde.");
        }
        setIsLoading(false);
      });
  }, []);

  //carrega o historico de compras e gastos do usuario
  const getTransactions = useCallback(async () => {
    setIsLoading(true);
    const token = Cookies.get("social:tk");
    await api
      .get("/transactions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTransactions(res.data.transactions);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status === 429) {
          toast.error(
            "Muitas tentativas, aguarde um instante e tente novamente."
          );
        }
        setIsLoading(false);
      });
  }, []);

  // Gera os pacotes de tokens para compras
  const getTokensPackage = useCallback(async () => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);

    await api
      .get("/tokens_package", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTokensPackage(res.data.lista_pacotes);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }

        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status === 429) {
          toast.error(
            "Muitas tentativas, aguarde um instante e tente novamente."
          );
        }
        setIsLoading(false);
      });
  }, []);

  //Valida o codigo inserido para resgatar tokens bonus
  const bonus = useCallback(async (data) => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    await api
      .post("/bonus", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        navigate(`/dashboard`);
        getBonus();
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        if (err.response.status === 429) {
          toast.error(
            "Muitas tentativas, aguarde um momento e tente novamente."
          );
        }
        if (err.response.status === 401) {
          signOut();
        }
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  //Captura o historico de cupons utilizados
  const getBonus = useCallback(async () => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);

    await api
      .get("/envia_cupons", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBonusCards(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status === 429) {
          toast.error(
            "Muitas tentativas, aguarde um instante e tente novamente."
          );
        }
        setIsLoading(false);
      });
  }, []);
  return (
    <BountyContext.Provider
      value={{
        tokensPackage,
        transactions,
        bonusCards,
        balance,
        bonus,
        getBonus,
        setBalance,
        getBalance,
        setBonusCards,
        getTransactions,
        setTransactions,
        getTokensPackage,
      }}
    >
      {children}
    </BountyContext.Provider>
  );
};
