import { useThemeChanger } from "../../Contexts/themeProvider";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { TopContent } from "../topContent";
import { Description } from "../geraTextoComponents/description";
import * as yup from "yup";
import { useFormik } from "formik";
import { useBounty } from "../../Contexts/bountyProvider";
import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { CircularIndeterminate } from "../loadingComponents/loading";
import { useUser } from "../../Contexts/UserProvider";

//Componente que faz a validação de codigos promocionais
export const BonusComponent = ({ startAnimation }) => {
  const { themes } = useThemeChanger();
  const { bonus, getBonus, bonusCards } = useBounty();
  const { isLoading } = useUser();
  const [cupons, setCupons] = useState([]);

  const convertDate = (date) => {
    const dataObj = new Date(date);
    const dia = dataObj.getDate().toString().padStart(2, "0");
    const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
    const ano = dataObj.getFullYear().toString();
    const hora = dataObj.toString().split(` `)[4];

    return `${dia}/${mes}/${ano} às ${hora}`;
  };

  useEffect(() => {
    if (bonusCards.length === 0) {
      getBonus();
    }
    setCupons(bonusCards.cupons);
  }, [bonusCards, getBonus]);

  const formSchema = yup.object().shape({
    codigo: yup
      .string()
      .required("Insira sua mensagem.")
      .max(350, "Limite de caracteres atingido."),
  });

  const formik = useFormik({
    initialValues: {
      codigo: "",
    },
    validationSchema: formSchema,
    onSubmit: (data) => {
      bonus(data);
    },
  });

  return (
    <Box
      className="bonusBox"
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row", "row"],
        height: "100%",
        width: "90vw",
        color: themes.liaMainColor,
        overflow: "hidden",
      }}
    >
      {isLoading && <CircularIndeterminate />}
      <Box
        className="bonusBox--pageInfo"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: [0, 0, 4, 5],
          mt: ["1rem", "1rem", "2rem", "2rem"],
          width: ["100%", "100%", "40%", "40%"],
        }}
      >
        <TopContent title={"Cupons"} onClick={startAnimation} />

        <Description
          text={"Resgate aqui seus códigos promocionais e obtenha seus bônus."}
        />
      </Box>
      <Box className="bonusBox--form">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: `center`,
            alignItems: "center",
            mt: 5,
            gap: 5,
          }}
        >
          <Typography
            className="bonusBox--form--text"
            sx={{
              fontWeight: 900,
              textAlign: "center",
              fontSize: [14, 16, 18, 20],
            }}
          >
            Insira o código de resgate abaixo <br />
            para receber seus bônus!
          </Typography>
          <Box
            className="bonusBox--form--submitBox"
            sx={{
              ".bonusBox--form": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: ["90vw", "90vw", "50vw", "50vw"],
                height: "50px",
              },
            }}
          >
            <form className="bonusBox--form" onSubmit={formik.handleSubmit}>
              <Tooltip>
                <input
                  className="bonusBox--form--submitBox--input"
                  id="codigo"
                  name="codigo"
                  label="Insira seu codigo aqui!"
                  placeholder="Insira seu codigo aqui!"
                  type="text"
                  disabled={false}
                  autoComplete="off"
                  style={{
                    width: "100%",
                    fontSize: 12,
                    minHeight: "100%",
                    borderRadius: `10px 0 0 10px`,
                    border: `2px solid ${themes.highlight}`,
                    paddingLeft: ".7rem",
                  }}
                  value={formik.values.codigo}
                  onBlur={(e) => formik.handleBlur(e)}
                  onChange={(event) => {
                    formik.handleChange(event);
                    if (formik.values.codigo.length > 30) {
                      let value = formik.values.codigo;
                      formik.setFieldValue("codigo", value.slice(0, 30));
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      formik.handleSubmit();
                    }
                  }}
                />
              </Tooltip>
              <Button
                className="bonusBox--form--submitBox--subimitButton"
                type="submit"
                sx={{
                  minHeight: "100%",
                  borderRadius: "0 10px 10px 0",
                  color: "white",
                  background: themes.highlight,
                  fontSize: [12, 12, 16, 16],
                  ":hover": {
                    background: themes.highlight,
                  },
                }}
              >
                Resgatar
              </Button>
            </form>
          </Box>
          <Box
            className="bonusBox--historic"
            sx={{
              height: "50vh",
              width: "100%",
              borderRadius: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pb: 3,
            }}
          >
            <Typography
              className="bonusBox--historic--title"
              sx={{ padding: 2, fontWeight: 900 }}
            >
              Histórico de cupons
            </Typography>
            <Box
              className="bonusBox--historic--cardBox"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
                height: "100%",
                maxHeight: "50vh",
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                pt: 1,
                pr: 1,
                background: themes.background,
                "::-webkit-scrollbar ": {
                  width: "5px",
                },

                "::-webkit-scrollbar-track": {
                  background: "none",
                  margin: "1rem 0",
                },

                "::-webkit-scrollbar-thumb": {
                  backgroundColor: themes.highlight,
                  borderRadius: "20px",
                },
                ".motion": {
                  width: "100%",
                },
              }}
            >
              {cupons && cupons.length > 0 ? (
                cupons.map((item, i) => (
                  <motion.div
                    key={i}
                    className="motion"
                    initial={{ transform: "translateX(100%)" }}
                    animate={{ transform: "translateX(0)" }}
                    exit={{ transform: "translateX(100%)" }}
                    transition={{ duration: i / 2 }}
                  >
                    <Box
                      className="bonusBox--historic--card"
                      sx={{
                        border: `1px solid ${themes.liaMainColor}`,
                        borderRadius: 5,
                        width: "100%",
                        p: 2,
                        color: themes.liaMainColor,
                        transition: "0.5s",
                        ":hover": {
                          borderColor: themes.highlight,
                        },
                        ".MuiTypography-root": {
                          fontSize: [12, 14, 16, 24],
                        },
                      }}
                    >
                      <Typography>
                        <b>Código:</b> {item.coupon_code}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          pt: 1,
                        }}
                      >
                        <Typography>
                          <b>Bônus:</b> {item.tokens_bonus.split(".")[0]}{' '}Tokens
                        </Typography>
                        <Typography>
                          <b>Data:</b> {convertDate(item.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                  </motion.div>
                ))
              ) : (
                <Box
                  className="bonusBox--historic--card"
                  sx={{
                    border: `1px solid ${themes.liaMainColor}`,
                    borderRadius: 5,
                    width: "100%",
                    p: 2,
                    color: themes.liaMainColor,
                    transition: "0.5s",
                    ":hover": {
                      borderColor: themes.highlight,
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: [12, 14, 16, 24], textAlign: "center" }}
                  >
                    Você ainda não resgatou nenhum código promocinal. <br />
                    Quando isso acontecer, eles aparecerão aqui!
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
