import { Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente de descrição de texto do usuario no perfil
export const UserDescriptionData = ({ field, userData }) => {
  const { themes } = useThemeChanger();

  return (
    <Typography sx={{ fontSize: [16, 20, 20, 20] }}>
      {field}:{" "}
      <span style={{ color: themes.liaMainColor }}>
        {userData ? userData : "vazio"}
      </span>
    </Typography>
  );
};
