import { toast } from "react-toastify";
import { viaCEP } from "../Services/api";
import { createContext, useCallback, useContext, useState } from "react";

const CepContext = createContext({});
//Context responsavel por gerenciamento de endereços

export const useCep = () => {
  const context = useContext(CepContext);
  if (!context) {
    throw new Error("useCep must be used within an cepProvider");
  }
  return context;
};

export const CepProvider = ({ children }) => {
  const [cep, setCep] = useState();
  const [cepError, setCepError] = useState();
  //consulta o cep passado e retorna o local
  const consultCEP = useCallback(async (cep) => {
    await viaCEP
      .get(`${cep}/json/`)
      .then((response) => response.data)
      .then((res) => {
        if (res.cep) {
          setCep(res);
          setCepError("");
        } else {
          setCepError(`CEP digitado é invalido, tente novamente.`);
          toast.error(`CEP digitado é invalido, tente novamente.`);
        }
      })
      .catch((err) => toast.error("CEP digitado é invalido, tente novamente."));
  }, []);

  return (
    <CepContext.Provider value={{ cep, setCep, cepError, consultCEP }}>
      {children}
    </CepContext.Provider>
  );
};
