import { Box, Button, Container, Typography } from "@mui/material";
import { useUser } from "../../Contexts/UserProvider";
import { useParams } from "react-router-dom";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { Img, List } from "./style";
import { CircularIndeterminate } from "../../Components/loadingComponents/loading";
import { Timer } from "../../Components/timerComponent";

//Pagina de bloqueio de conta do usuario
export const BlockUser = () => {
  const { BlockEmail, isLoading, disabled, setDisabled } = useUser();
  const { themes } = useThemeChanger();
  const { hash } = useParams();

  localStorage.clear();
  sessionStorage.clear();

  const sendHashBlock = () => {
    BlockEmail({ block: hash });
  };
  return (
    <Container
      className="blockUserContainer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: [4, 2, 3, 4],
        maxWidth: ["100vw", "100vw", "100vw", "100vw"],
        height: "100%",
        overflowY: ["scroll", "scroll", "hidden", "hidden"],
        overflowX: ["hidden"],
        padding: [0, 0, 0, 0],
      }}
    >
      {isLoading && <CircularIndeterminate />}
      <Img
        className="logo"
        src={themes.src.logoSocial}
        alt="Logo fale com Lia Social"
      />
      <Box
        className="blockUserContainer--content"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: [0, 0, 5, 5],
        }}
      >
        <Typography
          sx={{
            width: ["90%", "80%", "70%", "80%"],
            fontWeight: 900,
            fontSize: [16, 24, 28, 32],
            color: themes.textGray,
            textAlign: "center",
          }}
        >
          Antes de confirmar o bloqueio, leia com atenção e revise todos os
          critérios de segurança.
        </Typography>

        <List
          className="blockUserContainer--orientation"
          style={{ color: themes.textGray }}
        >
          <li>
            Verifique se não foi nenhum conhecido que acessou a sua conta;
          </li>
          <li>
            Revise cuidadosamente suas transações, pagamentos ou histórico de
            atividades para detectar quaisquer irregularidades.
          </li>
          <li>
            Verifique se algum familiar ou pessoa autorizada acessou
            recentemente sua conta.
          </li>
          <li>
            Avalie se você compartilhou suas credenciais de conta com alguém ou
            se suas senhas foram comprometidas.
          </li>
          <li>
            Verifique se suas informações pessoais e de contato estão
            atualizadas e corretas.
          </li>
          <li>
            Analise se você recebeu alguma mensagem ou e-mail suspeito
            solicitando informações pessoais ou financeiras.
          </li>
        </List>
        <Button
          className="blockUserContainer--blockButton"
          sx={{
            width: ["90%", "80%", "70%", "70%"],
            height: ["50px", "50px", "80px", "80px"],
            color: "white",
            background: disabled ? themes.textGray : themes.highlight,
            padding: 2,
            fontSize: 24,
            fontWeight: 900,
            transition: "0.5s",
            mt: 2,
            display: `flex`,
            flexDirection: `column`,
            ":hover": {
              transform: "scale(1.1)",
              background: themes.hoverButton,
            },
          }}
          disabled={disabled}
          onClick={sendHashBlock}
        >
          BLOQUEAR ACESSO
          {disabled && <Timer callback={() => setDisabled(false)} />}
        </Button>
        <Typography
          sx={{
            width: ["90%", "80%", "70%", "70%"],
            fontSize: [16, 18, 20, 24],
            color: themes.textGray,
            textAlign: "center",
            mt: 1,
          }}
        >
          Ao confirmar o bloqueio te enviaremos um e-mail para alterar sua
          senha!
        </Typography>
      </Box>
    </Container>
  );
};
