import { Img } from "./styles";
import { Box } from "@mui/material";
import { useUser } from "../../Contexts/UserProvider";
import { AlertDialogSlide } from "../../Components/popup";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { CircularIndeterminate } from "../../Components/loadingComponents/loading";
import { MobileCredentials } from "../../Components/credentialsComponents/carousel";
import { DesktopCredentials } from "../../Components/credentialsComponents/desktopCredentials";
import { useState } from "react";

//Pagina inicial de login/registro
export const CredentialsPage = () => {
  const { themes } = useThemeChanger();
  const { isLoading } = useUser();

  // useEffect(() => {
  //   const token = 'Cookies.get("")';
  //   const socket = io("https://app.falecomlia.com.br", { auth: token }); // Substitua pelo endereço do seu servidor

  //   socket.on("message", (data) => {
  //     toast.success(data.message);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    <Box
      className="loginRegisterContainer"
      sx={{
        display: "flex",
        gap: [0, 0, 5, 5],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: [`center`, `center`, `start`, `start`],
        background: themes.background,
        width: "100%",
        height: "100%",
        transition: "1s",
      }}
    >
      {isLoading && <CircularIndeterminate />}
      <AlertDialogSlide />
      <Img src={themes.src.logoSocial} alt="Logo fale com Lia Social" />
      <Box
        className="loginRegisterContainer--desktop"
        sx={{ display: ["none", "none", "flex", "flex"] }}
      >
        <DesktopCredentials />
      </Box>
      <Box
        className="loginRegisterContainer--mobile"
        sx={{
          display: ["flex", "flex", "none", "none"],
        }}
      >
        <MobileCredentials />
      </Box>
    </Box>
  );
};
