import { Box, Container } from "@mui/material";
import { HeaderComponent } from "../../Components/header";
import { TopContent } from "../../Components/topContent";
import { Description } from "../../Components/geraTextoComponents/description";
import { CompanyCredentials } from "../../Components/credentialsComponents/companyCredentials";
import TransitionWrapper from "../../Components/screenTransition";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useState } from "react";
import { useCompany } from "../../Contexts/companyProvider";
import { CircularIndeterminate } from "../../Components/loadingComponents/loading";
import { useEffect } from "react";
import { useGenText } from "../../Contexts/iaRequests";

//Pagina de cadastro de empresas
export const CadastraEmpresa = () => {
  const navigate = useNavigate();
  const { themes } = useThemeChanger();
  const { isLoading } = useCompany();
  const [isAnimating, setIsAnimating] = useState(false);
  const { setCurrentRoute } = useGenText();
  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);

  const startAnimation = () => {
    setIsAnimating(true);
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate("/dashboard")}
    >
      {isLoading && <CircularIndeterminate />}
      <Container
        className="companyRegisterContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          gap: 4,
          overflowX: ["hidden", "hidden", "visible", "visible"],
        }}
      >
        <HeaderComponent startAnimation={startAnimation} />
        <Box
          className="companyRegisterContainer--content"
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            width: "95vw",
            height: "100vh",
            overflow: `hidden scroll`,
            "::-webkit-scrollbar ": {
              width: "5px",
            },

            "::-webkit-scrollbar-track": {
              background: themes.background,
              margin: "1rem 0",
            },

            "::-webkit-scrollbar-thumb": {
              backgroundColor: themes.highlight,
              borderRadius: "20px",
            },
            gap: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: ["100%", "100%", "50%", "50%"],
              gap: 5,
            }}
          >
            <TopContent
              showTokens={false}
              title="Cadastrar empresa"
              onClick={startAnimation}
            />
            <Description text="Cadastre até 5 empresas e crie conteúdos personalizados e únicos para cada uma delas." />
          </Box>
          <CompanyCredentials startAnimation={startAnimation} />
        </Box>
      </Container>
    </TransitionWrapper>
  );
};
