import { Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

//Componente Mui Accordion - https://mui.com/material-ui/react-accordion/
export const AccordionComponent = ({ title, children, index }) => {
  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { themes } = useThemeChanger();
  return (
    <MuiAccordion
      disableGutters
      elevation={0}
      sx={{
        color: themes.liaMainColor,
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        width: "90%",
        "&:before": {
          display: "none",
        },
      }}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
      onBlur={handleChange(`panel${index}`)}
    >
      <MuiAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ color: themes.liaMainColor, fontSize: "0.9rem" }}
          />
        }
        sx={{
          backgroundColor: themes.background,
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: 1,
          },
        }}
        aria-controls={`panel${index}d-content`}
        id={`panel${index}d-header`}
      >
        <Typography>{title}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails
        sx={{
          background: themes.background,
          padding: 1,
          pl:5,
          borderTop: `1px solid ${themes.highlight}`,
        }}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
