import styled from "styled-components";
import lia from "../../assets/lia.jpeg";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  .overlay {
    background: rgb(154, 5, 141);
    background: -moz-radial-gradient(
      circle,
      rgba(154, 5, 141, 1) 0%,
      rgba(255, 99, 0, 1) 100%
    );
    background: -webkit-radial-gradient(
      circle,
      rgba(154, 5, 141, 1) 0%,
      rgba(255, 99, 0, 1) 100%
    );
    background: radial-gradient(
      circle,
      rgba(154, 5, 141, 1) 0%,
      rgba(255, 99, 0, 1) 100%
    );
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
`;

export const LinkTo = styled.a`
  border-radius: 20px;
  border: 1px solid white;
  background-color: transparent;
  color: ${(props) => props.color.text};
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  text-decoration: none;
  text-align: center;

  :active {
    transform: scale(0.95);
  }

  :focus {
    outline: none;
  }
`;
