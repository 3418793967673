import { Box, Button } from "@mui/material";
import React from "react";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Filtro por Datas
export const DateFilter = ({ handleClose, setDate }) => {
  const { themes } = useThemeChanger();
  const periodos = [90, 30, 15, 7, 0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 2,
        background: themes.background,

        "& button": {
          border: "none",
          borderBottom: `1px solid ${themes.liaMainColor}`,
          color: themes.liaMainColor,
          fontWeight: 600,
          borderRadius: 0,
        },
      }}
    >
      {periodos.map((el, i) => (
        <Button
          key={`${el}-${i}`}
          onClick={() => {
            handleClose();
            setDate(el);
          }}
        >
          {el === 0 ? "Tudo" : `${el} dias`}
        </Button>
      ))}
    </Box>
  );
};
