import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useThemeChanger } from "../../../Contexts/themeProvider";

//componente de loading circular
export const CircularIndeterminate = () => {
  const { themes } = useThemeChanger();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        zIndex: 9010,
        background: "rgba(0,0,0,0.5)",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
      }}
    >
      <CircularProgress sx={{ color: themes.liaMainColor }} />
    </Box>
  );
};
