import * as React from "react";
import { Box, Button, Drawer } from "@mui/material";
import { useUser } from "../../Contexts/UserProvider";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { FaArrowLeft } from "react-icons/fa";
import { TopContent } from "../topContent";

//Botão comum laranja
export const ButtonComponent = ({ width, text, type, onClick, color }) => {
  const { themes } = useThemeChanger();
  const { disabled } = useUser();
  return (
    <Button
      type={type}
      disabled={disabled}
      sx={{
        width: width ? width : "70%",
        height: 50,
        bgcolor: color ? color : themes.highlight,
        color: themes.text,
        fontWeight: themes.typograph.bold,
        ":hover": { background: color ? color+99: themes.hoverButton },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

//Botão retangular dashboard feito com Mui drawer - https://mui.com/material-ui/react-drawer/
export const ButtonDashboardComponent = ({
  height,
  Icon,
  text,
  title,
  children,
  callback,
  showTokens,
}) => {
  const { themes } = useThemeChanger();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: "100vw",
        height: height ? height : "95vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: themes.background,
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: ["column", "column", "flex", "flex"],
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: height ? height : "95vh",
          overflow: ["hidden"],
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: height ? height : "95vh",
            padding: "0 1rem",
          }}
        >
          <TopContent
            showTokens={showTokens}
            onClick={toggleDrawer(anchor, false)}
            title={title}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box onClick={callback}>
        <Button
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: [3, 1, 2, 3],
            padding: 1,
            width: ["80vw", "15rem", "15rem", "16rem"],
            height: ["40px", "50px", "50px", "50px"],
            color: themes.liaMainColor,
            background: themes.dashBoardCardBg,
            borderRadius: "10px",
            fontWeight: themes.typograph.bold,
            transition: "0.3s",
            fontSize: [10, 10, 10, 12],
            ":hover": { transform: "scale(1.1)" },
          }}
          onClick={toggleDrawer("bottom", true)}
        >
          <Box sx={{ fontSize: [16, 16, 24, 24], color: themes.highlight }}>
            <Icon />
          </Box>
          {text}
        </Button>
      </Box>
      <Drawer
        anchor={"bottom"}
        open={state["bottom"]}
        onClose={toggleDrawer("bottom", false)}
      >
        {list("bottom")}
      </Drawer>
    </Box>
  );
};

//Botão para retornar pagina "seta laranja para esquerda"
export const ReturnDashButton = ({ startAnimation }) => {
  const { themes } = useThemeChanger();

  return (
    <Button
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        width: ["1%", "1%", "80%", "80%"],
        minWidth: 12,
        fontSize: [16, 24, 32, 38],
        p: 0,
      }}
      onClick={startAnimation}
    >
      <FaArrowLeft style={{ color: themes.highlight }} />
    </Button>
  );
};
