import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { FaPaperPlane } from "react-icons/fa";
import { Form } from "./style";
import * as yup from "yup";
import { useFormik } from "formik";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGenText } from "../../Contexts/iaRequests";

//Input principal utilizado para formularios
export const InputComponent = ({
  id,
  width,
  label,
  placeholder,
  type,
  errors,
  onChange,
  handleSubmit,
  value,
  name,
  onBlur,
  autoComplete,
  disabled,
}) => {
  const { themes } = useThemeChanger();

  return (
    <Tooltip title={errors}>
      <TextField
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        label={label}
        type={type}
        name={name}
        variant="outlined"
        autoComplete={autoComplete}
        inputProps={{
          style: { fontSize: 15, color: errors ? "red" : themes.liaMainColor },
        }}
        InputLabelProps={{
          style: { fontSize: 15, color: errors ? "red" : themes.liaMainColor },
        }}
        error={errors}
        value={value}
        sx={{
          position: "relative",
          width: ["90%", "90%", width ? width : "70%", width ? width : "70%"],
          "& fieldset": {
            border: `1px solid ${themes.liaMainColor}`,
          },
        }}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
            event.target.value = "";
          }
        }}
      />
    </Tooltip>
  );
};

//input de password
export const PasswordInput = ({
  label,
  width,
  errors,
  onChange,
  handleSubmit,
  autoComplete,
  id,
  name,
  onBlur,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { themes } = useThemeChanger();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        TransitionComponent={Zoom}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={errors}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <FormControl
            sx={{
              width: [
                "90%",
                "90%",
                width ? width : "70%",
                width ? width : "70%",
              ],
              position: "relative",
            }}
            variant="outlined"
            onClick={handleTooltipOpen}
          >
            <InputLabel
              style={{
                fontSize: 15,
                color: errors ? "red" : themes.liaMainColor,
              }}
              htmlFor="outlined-adornment-password"
            >
              {label}
            </InputLabel>

            <OutlinedInput
              id={id}
              name={name}
              autoComplete={autoComplete}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: themes.liaMainColor }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                fontSize: 15,
                borderColor: themes.liaMainColor,
                color: themes.liaMainColor,
                "& fieldset": {
                  border: `1px solid ${themes.liaMainColor}`,
                },
              }}
              error={errors}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </FormControl>
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

//Input de sorteios kremer
export const InputSorteio = ({
  label,
  type,
  errors,
  register,
  onChange,
  value,
  name,
}) => {
  return (
    <Tooltip title={errors}>
      <TextField
        name={name}
        label={label}
        type={type}
        variant="outlined"
        autoComplete="off"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        error={errors}
        sx={{
          position: "relative",
          width: ["90%", "80%", "70%", "70%"],
          background: "white",
        }}
        {...register}
        onChange={onChange}
        value={value}
      />
    </Tooltip>
  );
};

//Input  com botão integrado
export const InputButton = ({ disabled, id, name, prompt, label, path }) => {
  const { themes } = useThemeChanger();
  const { genlia, type, setInitialRoute } = useGenText();
  const navigate = useNavigate();

  useEffect(() => {
    setInitialRoute(path);
  }, []);

  const formSchema = yup.object().shape({
    prompt: yup
      .string()
      .required("Insira sua mensagem.")
      .max(350, "Limite de caracteres atingido."),
  });

  const formik = useFormik({
    initialValues: {
      prompt: "",
    },
    validationSchema: formSchema,
    onSubmit: (data) => {
      if (type !== "") {
        const dataFormated = {
          ...data,
          type: type,
        };
        genlia(dataFormated);
      } else {
        toast.error("Selecione a categoria desejada e tente novamente.");
        navigate("/dashboard");
      }
      formik.values.prompt = "";
    },
  });

  useEffect(() => {
    formik.setFieldValue("prompt", prompt);
  }, [prompt]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Tooltip>
          <input
            id={id}
            name={name}
            label={label}
            placeholder={label}
            type="text"
            disabled={disabled}
            autoComplete="off"
            style={{ width: "100%", fontSize: 12 }}
            value={formik.values.prompt}
            onBlur={(e) => formik.handleBlur(e)}
            onChange={(e) => formik.handleChange(e)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                formik.handleSubmit();
              }
            }}
          />
        </Tooltip>
        <Button
          type="submit"
          sx={{
            minHeight: "100%",
            borderRadius: "0 10px 10px 0",
            color: "white",
            background: disabled === true ? themes.textGray : themes.highlight,
            fontSize: 24,
            ":hover": {
              background: themes.highlight,
            },
          }}
          disabled={disabled}
        >
          <FaPaperPlane />
        </Button>
      </Box>
    </Form>
  );
};
