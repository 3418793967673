import * as Yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { InputComponent } from "../../input";
import { ButtonComponent } from "../../button";
import { CircularIndeterminate } from "../../loadingComponents/loading";
import { useCep } from "../../../Contexts/cepProvider";
import { useUser } from "../../../Contexts/UserProvider";
import { useThemeChanger } from "../../../Contexts/themeProvider";
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { handleCPF, handleCep, handleDate, ufs } from "../../../utils";
import { TwoFactorAuth } from "../../2FAComponent";
import TransitionWrapper from "../../screenTransition";
import { HeaderComponent } from "../../header";

//Componente de cadastro do perfil do usuario
export const CadastroProfileUsuario = () => {
  const { themes } = useThemeChanger();
  const { consultCEP, cep, cepError, setCep } = useCep();
  const { cadastraPerfilUsuario, tokenExpire, twoFactor, whatsNumber } =
    useUser();
  const { isLoading } = useUser();

  const validationSchema = Yup.object().shape({
    cpf: Yup.string()
      .required("Campo obrigatório.")
      .matches(/^(\d{3}).(\d{3}).(\d{3}).(\d{2})$/, "Formato: xxx.xxx.xxx-xx"),
    nascimento: Yup.string()
      .required("Campo obrigatório.")
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/([0-9]{4})$/,
        "Formato: DD/MM/YYYY"
      ),
    cep: Yup.string().required("Campo obrigatório."),
    cidade: Yup.string().required("Campo obrigatório."),
    uf: Yup.string().required("Campo obrigatório."),
    endereco: Yup.string().required("Campo obrigatório."),
    bairro: Yup.string().required("Campo obrigatório."),
    numero: Yup.string()
      .required("Campo obrigatório.")
      .matches(/^\d{1,6}$/, "No minimo 1 digito, maximo 6."),
    complemento: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      whatsapp: "",
      cpf: "",
      nascimento: "",
      cep: "",
      cidade: "",
      uf: "",
      endereco: "",
      bairro: "",
      numero: "",
      complemento: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatedData = {
        whatsapp: whatsNumber,
        cpf: values.cpf,
        birth: values.nascimento,
        zip_code: values.cep,
        city: values.cidade,
        neighborhood: values.bairro,
        street: values.endereco,
        number: values.numero,
        complement: values.complemento ? values.complemento : "Sem complemento",
        uf: values.uf,
      };

      cadastraPerfilUsuario(formatedData);
    },
  });

  const cidadeconsultCEP = (event) => {
    const cepValue = event.target.value.replace("-", "");

    if (cepValue.length === 8) {
      consultCEP(cepValue);
    }
  };

  useEffect(() => {
    formik.setFieldValue("cidade", cep && cep.localidade);
    formik.setFieldValue("uf", cep && cep.uf);
    formik.setFieldValue("endereco", cep && cep.logradouro);
    formik.setFieldValue("bairro", cep && cep.bairro);
  }, [cep]);

  return (
    <TransitionWrapper>
      <HeaderComponent where="profile" />
      <Container
        className="profileSignContainer"
        sx={{ width: "100vw", height: "100vh", overflowX: "hidden" }}
      >
        <form
          className="profileSignContainer--form"
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            transition: "1s",
            gap: 9,
            padding: 0,
            overflowX: "hidden",
          }}
          onSubmit={formik.handleSubmit}
        >
          {isLoading && <CircularIndeterminate />}
          <Typography
            sx={{
              color: themes.textGray,
              fontSize: ["20px", "24px", "28px", "32px"],
              margin: [2, 5, 4],
            }}
            variant="h4"
          >
            Antes de continuar, vamos finalizar o seu cadastro.
          </Typography>

          {twoFactor ? (
            <TwoFactorAuth />
          ) : (
            <>
              <InputComponent
                id="cpf"
                label="CPF"
                type="text"
                name="cpf"
                value={formik.values.cpf}
                onChange={(e) => {
                  handleCPF(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                errors={formik.touched.cpf && formik.errors.cpf}
              />
              <InputComponent
                id="nascimento"
                label="Data de nascimento"
                type="text"
                name="nascimento"
                value={formik.values.nascimento}
                onChange={(e) => {
                  handleDate(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                errors={formik.touched.nascimento && formik.errors.nascimento}
              />

              <Box
                sx={{
                  width: ["100%", "100%", "70%", "70%"],
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <InputComponent
                  id="cep"
                  label="CEP"
                  type="text"
                  name="cep"
                  width={"100%"}
                  value={formik.values.cep}
                  onChange={(e) => {
                    cidadeconsultCEP(e);
                    handleCep(e, formik.setFieldValue);

                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  errors={cepError && cepError}
                />

                {!cep && (
                  <Link
                    sx={{
                      fontSize: 14,
                      justifySelf: [
                        "center",
                        "center",
                        "flex-start",
                        "flex-start",
                      ],
                      alignSelf: [
                        "center",
                        "center",
                        "flex-start",
                        "flex-start",
                      ],
                      color: themes.highlight,
                      textDecoration: "none",
                      pt: 1,
                    }}
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                  >
                    Para consultar seu cep, click aqui!
                  </Link>
                )}
              </Box>

              {cep && (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                    justifyContent: [
                      "center",
                      "center",
                      "space-between",
                      "space-between",
                    ],
                    alignItems: "center",
                    width: ["100%", "100%", "70%", "70%"],
                    flexWrap: "wrap",
                    padding: [0, 0, 0, 0],
                    rowGap: 1,
                  }}
                >
                  <InputComponent
                    type="text"
                    label="Cidade"
                    width="80%"
                    name="cidade"
                    id="cidade"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={formik.touched.cidade && formik.errors.cidade}
                  />
                  <FormControl sx={{ width: ["90%", "90%", "18%", "18%"] }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      sx={{ fontSize: "15px", color: themes.liaMainColor }}
                    >
                      UF
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="uf"
                      name="uf"
                      label="UF"
                      value={formik.values.uf}
                      defaultValue=""
                      onChange={formik.handleChange}
                      error={formik.touched.uf && formik.errors.uf}
                      sx={{
                        position: "relative",
                        width: "100%",
                        color: themes.liaMainColor,
                        "& fieldset": {
                          border: `1px solid ${themes.liaMainColor}`,
                        },
                        "& svg": {
                          color: themes.liaMainColor,
                        },
                      }}
                    >
                      {ufs.map((el, i) => (
                        <MenuItem
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            position: "sticky",
                          }}
                          key={`${el}-${i}`}
                          value={el}
                          onClick={() => {}}
                        >
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <InputComponent
                    type="text"
                    label="Endereço"
                    width="80%"
                    name="endereco"
                    id="endereco"
                    value={formik.values.endereco}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={formik.touched.endereco && formik.errors.endereco}
                  />

                  <InputComponent
                    type="number"
                    label="Nº"
                    width="18%"
                    name="numero"
                    id="numero"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={formik.touched.numero && formik.errors.numero}
                  />
                  <InputComponent
                    type="text"
                    label="Complemento"
                    width="48%"
                    name="complemento"
                    id="complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={
                      formik.touched.complemento && formik.errors.complemento
                    }
                  />
                  <InputComponent
                    type="text"
                    label="Bairro"
                    width="50%"
                    name="bairro"
                    id="bairro"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={formik.touched.bairro && formik.errors.bairro}
                  />
                  <ButtonComponent
                    width={"100%"}
                    text="Enviar"
                    type="submit"
                    onClick={tokenExpire}
                  />
                </Container>
              )}
            </>
          )}
        </form>
      </Container>
    </TransitionWrapper>
  );
};
