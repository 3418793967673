import * as yup from "yup";
import { PasswordInput } from "../../input";
import { ButtonComponent } from "../../button";
import { Box, Container, Typography } from "@mui/material";
import { useThemeChanger } from "../../../Contexts/themeProvider";
import { useFormik } from "formik";
import { useUser } from "../../../Contexts/UserProvider";
import { useParams } from "react-router-dom";
import { Img } from "./styles";

//Componente onde se define a nova senha
export const RecuperarSenhaFormulario = () => {
  const { themes } = useThemeChanger();
  const { redefinir_senha } = useUser();
  const { emailToken } = useParams();

  //Validação com YUP
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Senha é necessária")
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        "senha: No minimo 8 caracteres; um número; uma letra maiuscula; uma letra minuscula; um caracter especial"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas diferentes")
      .required("Necessário a confirmação de senhas."),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatedData = {
        email_token: emailToken,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      console.log(emailToken);
      redefinir_senha(formatedData);
    },
  });

  return (
    <Container
      className="newPasswordContainer"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        p: [0, 0, 0, 0],
        gap: 5,
      }}
    >
      <Img
        className="logo"
        src={themes.src.logoSocial}
        alt="Logo fale com Lia Social"
      />
      <Box
        className="newPasswordContainer--form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 5,
          borderRadius: 3,
          left: 0,
          zIndex: 1,
          width: ["90vw", "40vw", "50vw", "50vw"],
          height: "50vh",
          boxShadow: [`0 4px 8px ${themes.textGray}`],
          transition: "1s",
          padding: "3rem 2rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: [18, 24, 28, 32],
            color: themes.textGray,
            fontWeight: 600,
          }}
        >
          Crie uma nova senha:
        </Typography>
        <form
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            width: "80%",
          }}
          onSubmit={formik.handleSubmit}
        >
          <PasswordInput
            width="100%"
            id="password"
            name="password"
            label="Digite sua senha"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.touched.password && formik.errors.password}
          />
          <PasswordInput
            width="100%"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirme sua senha"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <ButtonComponent width="100%" text="Redefinir" type="submit" />
        </form>
      </Box>
    </Container>
  );
};
