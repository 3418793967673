import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button, TextField, Typography } from "@mui/material";
import { ToolsSwipeDrawer } from "../swipeModal/toolsRedacao";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useEffect } from "react";
import { useGenText } from "../../Contexts/iaRequests";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#FF6300",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#FF6300",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#FF6300 0%,#FF6300 50%,#FF6300 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#FF6300 0%,#FF6300 50%,#FF6300 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#FF6300",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "#FF6300",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BorderColorOutlinedIcon />,
    2: <SettingsIcon />,
    3: <ChecklistOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export const RedacaoStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { themes } = useThemeChanger();
  const { genlia, type } = useGenText();
  const [disabled, setDisabled] = useState(false);

  //ferramentas selecionadas para edição da redação
  const [selectedTools, setSelectedTools] = useState({
    tom: "Profissional",
    formato: "Parágrafo",
    comprimento: "Curto",
    estilo: "Minimalista",
    perspectiva: "Segunda pessoa",
  });
  const validationSchema = yup.object({
    prompt: yup
      .string()
      .required("Digite o tema da sua redação.")
      .min(10, "Mínimo 10 caracteres")
      .max(200, "Máximo 200 caracteres"),
  });

  const formik = useFormik({
    initialValues: {
      prompt: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values["options"] = selectedTools;
      const objTeste = { ...selectedTools };
      objTeste["tema"] = values.prompt;

      if (!values.prompt) {
        handleReset();
      } else if (Object.values(values.options).includes("")) {
        handleBack();
      } else {
        if (type !== "") {
          const dataFormated = {
            prompt: { ...objTeste },
            type: type,
          };
          // setGenerating(true);
          genlia(dataFormated);
        }
      }
    },
  });
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (activeStep === 0) {
      if (
        formik.values.prompt.length < 10 ||
        formik.values.prompt.length > 200
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else if (activeStep === 1) {
      if (Object.values(selectedTools).includes("")) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [formik.values.prompt, selectedTools, activeStep]);

  const steps = [
    {
      name: "Tema",
      child: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: 600, fontSize: [16, 16, 20, 24] }}>
            Qual o tema da sua redação?
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="prompt"
              name="prompt"
              placeholder="Inteligência artificial"
              type="text"
              value={formik.values.prompt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: 15,
                  color: formik.errors.prompt ? "red" : themes.liaMainColor,
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 15,
                  color: formik.errors.prompt ? "red" : themes.liaMainColor,
                },
              }}
              sx={{
                position: "relative",
                width: ["100%"],
                "& fieldset": {
                  border: `1px solid ${themes.liaMainColor}`,
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                color: formik.errors.prompt
                  ? themes.highlight
                  : themes.liaMainColor,
              }}
            >
              {`${formik.values.prompt.length}/200`}
              {`${formik.errors.prompt ? " - " + formik.errors.prompt : ""}`}
            </Typography>
          </form>
        </Box>
      ),
    },
    {
      name: "Configurações",
      child: (
        <>
          <Typography
            variant="h5"
            sx={{ fontWeight: 600, mb: 5, fontSize: [16, 16, 20, 24] }}
          >
            Escolha as opções desejadas.
          </Typography>
          <ToolsSwipeDrawer
            selectedTools={selectedTools}
            setSelectedTools={setSelectedTools}
          />
        </>
      ),
    },
    {
      name: "Revisão",
      child: (
        <Box sx={{}}>
          <Typography
            sx={{ fontSize: [16, 16, 20, 24], fontWeight: 600, mb: 5 }}
          >
            Revise suas escolhas antes de confirmar!
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: themes.liaMainColor,
              mt: 2,
              fontWeight: 600,
              fontSize: [16, 16, 20, 24],
            }}
          >
            Parâmetros:
          </Typography>
          <Typography sx={{ fontSize: [14, 18, 20, 20] }}>
            Tema:{" "}
            <b style={{ color: themes.highlight }}>{formik.values.prompt}</b>
          </Typography>
          {Object.entries(selectedTools).map(([key, value]) => (
            <Typography key={key + value} sx={{ fontSize: [14, 18, 20, 20] }}>
              {capitalizeFirstLetter(key)}:{" "}
              <b style={{ color: themes.highlight }}>{value}</b>
            </Typography>
          ))}
        </Box>
      ),
    },
  ];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }} spacing={4}>
      <Stepper
        id="Box input"
        data-intro="Primeiro digite o que é solicidatado, escolha as opções que mais te agradam e depois revise seu conteúdo, com tudo pronto, envie e aguarde"
        data-step="5"
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        style={{ color: themes.liaMainColor }}
      >
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.name} {...stepProps}>
              <StepLabel
                sx={{ color: themes.liaMainColor }}
                {...labelProps}
                StepIconComponent={ColorlibStepIcon}
              >
                <Typography
                  sx={{ color: themes.liaMainColor }}
                  variant="caption"
                >
                  {step.name}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Gerando texto, por favor aguarde!
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Novo</Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            id={"scroll_Box"}
            sx={{
              height: "100%",
              width: ["100%", "100%", "80%", "80%"],
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              "::-webkit-scrollbar ": {
                width: "5px",
              },

              "::-webkit-scrollbar-track": {
                background: themes.background,
                margin: "1rem 0",
              },

              "::-webkit-scrollbar-thumb": {
                backgroundColor: themes.highlight,
                borderRadius: "20px",
              },
            }}
          >
            <>{steps[activeStep].child}</>
          </Box>
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              color: themes.liaMainColor,
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                mr: 1,
                color: "#fff",
                background: activeStep === 0 ? "none" : "#055C9A",
                ":hover": {
                  background: themes.highlight,
                },
              }}
            >
              Voltar
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              id="Box input"
              data-intro="Este botão será liberado assim que você cumprir com as necessidades de cada passo."
              data-step="6"
              type="submit"
              sx={{
                color: "#fff",
                background: disabled ? "none" : "#055C9A",
                ":hover": {
                  background: themes.highlight,
                },
              }}
              disabled={disabled}
              onClick={() => {
                activeStep === steps.length - 1
                  ? formik.handleSubmit()
                  : handleNext();
              }}
            >
              {activeStep === steps.length - 1 ? "Criar" : "Proximo"}
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};
