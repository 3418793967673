import { toast } from "react-toastify";
import { api } from "../Services/api";
import { createContext, useCallback, useContext, useState } from "react";
import { useUser } from "./UserProvider";
import { converterBase64ParaImagem, verificarQRCode } from "../utils";
import Cookies from "js-cookie";

const AsassContext = createContext({});
//Context responsavel por pagamentos

export const useAsass = () => {
  const context = useContext(AsassContext);
  if (!context) {
    throw new Error("useAsass must be used within an asassProvider");
  }
  return context;
};

export const AsassProvider = ({ children }) => {
  const [payload, setPayload] = useState({ qrCode: "nada", pixCode: "nada" });
  const [loading, setLoading] = useState(false);

  const { signOut } = useUser();

  //Realiza a solicitação de compra de tokens
  const comprar_tokens = useCallback(async (tag) => {
    const token = Cookies.get("social:tk");
    const data = { tag: tag };
    setLoading(true);
    await api
      .post(`/comprar_tokens`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .then((res) => {
        setPayload({
          qrCode: converterBase64ParaImagem(res.pix.encodedImage),
          pixCode: res.pix.payload,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          signOut();
        }
        toast.error("Algo deu errado.");
      });
  }, []);

  return (
    <AsassContext.Provider
      value={{ comprar_tokens, payload, loading, setLoading }}
    >
      {children}
    </AsassContext.Provider>
  );
};
