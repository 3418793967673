import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../../Contexts/themeProvider";
import { Load } from "./styles";
import { useGenText } from "../../../Contexts/iaRequests";

//componente de loading de 3 pontos que acontecem dentro do social Lia
export const LoadingDot = () => {
  const { themes } = useThemeChanger();
  const { delayMessage } = useGenText();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Load color={themes.liaMainColor} />
      <Typography
        sx={{
          color: themes.liaMainColor,
          fontSize: [14, 16, 16, 18],
          height: "auto",
          textAlign: "center",
        }}
      >
        {delayMessage}
      </Typography>
    </Box>
  );
};
