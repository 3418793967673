import { createTheme } from "@mui/material/styles";
import liaWhite from "../assets/logoLiaWhite.png";
import liaBlue from "../assets/logoLiaBlue.png";
import socialLiaWhite from "../assets/logoSocialLiaWhite.png";
import socialLiaBlue from "../assets/logoSocialLiaBlue.png";
import sunIcon from "../assets/sol.png";
import moonIcon from "../assets/lua.png";
import styled from "styled-components";

export const theme = createTheme({
  palette: {
    primary: {
      //Tema claro
      main: "#000", //Changing only this color, the input focus changes too (mui theme). SO, DON'T CHANGE IT.
      text: "#fff",
      textInput: "#000",
      textGray: "#A7ACB0",
      liaMainColor: "#055C9A",
      background: "#eee",
      headerbackground: "#fff",
      borderColor: "#A7ACB0",
      highlight: "#FF6300",
      hoverButton: "#CA5003",
      dashBoardCardBg: "#dddddd",
      dashBoardCardTxt: "#FFF",
      expense: "#F83B12",
      acquisition: "#2BAD0A",
      textGenerateBg: "#055C9A",
      src: {
        logo: liaBlue,
        logoSocial: socialLiaBlue,
        themeIcon: moonIcon,
      },
      typograph: {
        title: "Poppins",
        text: "Inter",
        bold: 800,
        light: 300,
        thin: 100,
      },
    },
    secondary: {
      //Tema escuro
      main: "#1E1E1E",
      text: "#FFF",
      textInput: "#FFF",
      textGray: "#A7ACB0",
      liaMainColor: "#fff",
      background: "#1E1E1E",
      headerbackground: "#1E1E1E",
      borderColor: "#fff",
      highlight: "#FF6300",
      hoverButton: "#CA5003",
      dashBoardCardBg: "#222222",
      dashBoardCardTxt: "#fff",
      expense: "#F83B12",
      acquisition: "#2BAD0A",
      textGenerateBg: "#FF6300",

      src: {
        logo: liaWhite,
        logoSocial: socialLiaWhite,
        themeIcon: sunIcon,
      },
      typograph: {
        title: "Poppins",
        text: "Inter",
        bold: 800,
        light: 300,
        thin: 100,
      },
    },
  },
});

export const OpenScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background: red;
  z-index: 200;
`;
