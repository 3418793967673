import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  position: relative;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  > span {
    align-self: flex-end;
    justify-self: flex-end;
  }
  input {
    width: 100%;
    background: #fff;
    border-radius: 10px 0 0 10px;
    font-size: 16px;
    padding: 10px;
    border: 2px #ff6300 solid;
    color: #055c9a;
    font-weight: bold;
    :focus {
      outline: none;
    }
  }
  @media (min-width: 910px) {
    width: 50vw;

    > input {
      width: 50%;
      font-size: 24px;
    }
    > button {
      height: 52px;
    }
  }
`;
