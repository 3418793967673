import { Box, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionWrapper from "../../Components/screenTransition";
import { HeaderComponent } from "../../Components/header";
import { SupportComponent } from "../../Components/supportComponent";
import { useState } from "react";
import { useEffect } from "react";
import { useGenText } from "../../Contexts/iaRequests";

//Pagina de solicitação de suporte para usuario Social Lia
export const SupportPage = () => {
  // tawkTo(userProfile);

  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentRoute } = useGenText();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);

  const [isAnimating, setIsAnimating] = useState(false);
  const startAnimation = () => {
    setIsAnimating(true);
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate("/dashboard")}
    >
      <Container
        className="supportPageContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          gap: 2,
          overflowX: ["hidden", "hidden", "visible", "visible"],
        }}
      >
        <HeaderComponent startAnimation={startAnimation} />
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            width: "95vw",
            height: "100%",
            gap: 5,
          }}
        >
          <SupportComponent startAnimation={startAnimation} />
        </Box>
      </Container>
    </TransitionWrapper>
  );
};
