import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useNavigate } from "react-router-dom";

//Componente que descreve como o usuario poderá trocar seus dados
export const ProfileEdit = () => {
  const { themes } = useThemeChanger();

  const navigate = useNavigate();
  return (
    <Box
      className="profileEditContainer"
      sx={{
        width: `80vw`,
        overflowY: "scroll",
        "::-webkit-scrollbar ": {
          width: "5px",
        },

        "::-webkit-scrollbar-track": {
          background: themes.background,
          margin: "1rem 0",
        },

        "::-webkit-scrollbar-thumb": {
          backgroundColor: themes.highlight,
          borderRadius: "20px",
        },
      }}
    >
      <Typography
        className="profileEditContainer--title"
        sx={{
          textAlign: `center`,
          fontWeight: `bolder`,
          fontSize: 24,
          color: themes.highlight,
          mb: 5,
        }}
      >
        Edição de tokens
      </Typography>
      <Box
        className="profileEditContainer--description"
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: 2,
          pb: 2,
          color: themes.liaMainColor,
        }}
      >
        <Typography>
          Gostaríamos de informar que, para garantir a segurança e a integridade
          dos dados, a alteração de informações cadastradas, seja para seu
          perfil pessoal ou empresarial, é restrita a administradores do
          sistema. Isso é uma medida de proteção que visa prevenir possíveis
          alterações indevidas e garantir que as informações estejam sempre
          precisas.
        </Typography>
        <Typography>
          Caso você deseje realizar qualquer mudança nos dados cadastrados,
          solicitamos que entre em contato com nosso{" "}
          <Typography
            variant="span"
            sx={{
              color: themes.highlight,
              textDecoration: "underline",
              fontWeight: "bold",
              ":hover": { cursor: "pointer" },
            }}
            onClick={() => navigate(`/suporte`)}
          >
            suporte técnico
          </Typography>
          . Nossa equipe de atendimento está preparada para auxiliá-lo nesse
          processo e garantir que todas as alterações sejam realizadas de forma
          segura e conforme suas necessidades.
        </Typography>
        <Typography>
          Para abrir um chamado de suporte, basta acessar a seção "Suporte" em
          nosso menu, preenchendo o formulário com os detalhes da solicitação.
          Nossos especialistas estarão prontos para atendê-lo e fornecer as
          orientações necessárias.
        </Typography>
        <Typography>
          Salientamos que nossa preocupação é garantir a melhor experiência de
          uso e manter a confiabilidade de seus dados. Portanto, conte conosco
          para qualquer assistência relacionada à alteração de informações
          cadastrais.
        </Typography>
        <Typography>
          Agradecemos sua compreensão e estamos à disposição para quaisquer
          dúvidas ou necessidades adicionais.
        </Typography>
      </Box>
    </Box>
  );
};
