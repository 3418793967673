import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Typography,
} from "@mui/material";
import { ButtonDashboardComponent } from "../button";
import { FaCoins, FaDollarSign, FaScroll, FaUser } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../Contexts/UserProvider";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import LiaClip from "../../assets/LiaDID.mp4";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useBounty } from "../../Contexts/bountyProvider";
import { CompraToken } from "../../Pages/Compra_token";
import { Perfil } from "../../Pages/Perfil";
import { BuyTokenHistory } from "../BuyToken/history";
import { dashboardCards } from "../../utils";
import {
  DashboardSelectCard,
  DashboardSelectCardUnique,
} from "../Cards/DashboardSwipeCard";
import { useCompany } from "../../Contexts/companyProvider";
import TransitionWrapper from "../screenTransition";
import { HeaderComponent } from "../header";
import Cookies from "js-cookie";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useGenText } from "../../Contexts/iaRequests";

//Componente area principal do social lia
export const DashboardComponent = () => {
  //Declaração dos contextAPI para carregar todos itens do usuario
  const { themes } = useThemeChanger();

  const location = useLocation();
  const { setCurrentRoute } = useGenText();

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);

  const {
    envia_profile,
    handleVisibleToken,
    isVisible,
    setIsVisible,
    setSelectedCard,
    signOut,
  } = useUser();

  const { recebe_empresas, empresas } = useCompany();

  const {
    balance,
    transactions,
    getBalance,
    tokensPackage,
    getTransactions,
    getTokensPackage,
  } = useBounty();

  //Chamada dos contextAPI para carregar todos itens do usuario
  const handleTransactions = () => {
    if (transactions.length === 0) {
      getTransactions();
    }
  };
  const handleTokenPackage = () => {
    if (tokensPackage.length === 0) {
      getTokensPackage();
    }
  };

  const [secondRender, setSecondRender] = useState(false);
  const navigate = useNavigate();

  const handleToken = () => {
    if (!balance) {
      getBalance();
    }
    if (balance === undefined) {
      setIsVisible(false);
    }
  };
  // tawkTo();
  //verifica se o usuario esta autenticado, caso não esteja, fará logoff automaticamente
  useEffect(() => {
    const auth = Cookies.get("social:isA");
    if (!auth) {
      signOut();
    }
    if (secondRender && auth === "true") {
      if (empresas.length === 0) {
        recebe_empresas();
        envia_profile();
        handleToken();
        getTokensPackage();
        getBalance();
      }
    } else {
      setSecondRender(true);
    }
  }, [secondRender]);

  const username = Cookies.get("social:us");

  const newName = username.split(" ")[0];

  const abreviations = username
    .split(" ")
    .splice(1)
    .map((el) => el[0])
    .join(". ");

  //Array para criar os Botões retangulares
  const buttons = [
    {
      icon: () => <FaUser />,
      text: "Informações do perfil",
      title: "Perfil do Usuário",
      Component: <Perfil />,
      callback: () => { },
      showTokens: false,
    },
    {
      icon: () => <FaDollarSign />,
      text: "Comprar Tokens",
      title: "Tokens-Lia",
      Component: <CompraToken />,
      callback: () => handleTokenPackage(),
      showTokens: true,
    },
    {
      icon: () => <FaScroll />,
      text: "Extrato",
      title: "Histórico",
      Component: <BuyTokenHistory />,
      callback: () => handleTransactions(),
      showTokens: true,
    },
  ];
  const [isAnimating, setIsAnimating] = useState(false);
  const [route, setRoute] = useState("/dashboard");

  const startAnimation = () => {
    setIsAnimating(true);
  };
  const [hasVisitedBefore, setHasVisitedBefore] = useState(
    Cookies.get("visitedBefore")
  );
  const handleTutorial = () => {
    //Marque a visita atual com um cookie
    Cookies.set("visitedBefore", "true", { expires: 365 }); //Define o cookie por 1 ano
    introJs()
      .oncomplete(() => window.scrollTo(0, 0))
      .start();
    setHasVisitedBefore(Cookies.get("visitedBefore"));
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate(route)}
    >
      <HeaderComponent
        id="botao2"
        intro="Este é o menu, clique nele e encontre mais ferramentas."
        step="2"
        startAnimation={startAnimation}
      />
      {!hasVisitedBefore && (
        <Box
          sx={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.1)",
            zIndex: 9000,
            top: 0,
          }}
          onClick={() => handleTutorial()}
        ></Box>
      )}
      <Box
        className="dashboardBox"
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          gap: ["2rem", "6rem", "6rem", "8rem"],
          mt: [2, 2, 0, 0],
        }}
      >
        <Box
          className="dashboardBox--userInfo"
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            justifyContent: "space-around",
            alignItems: "center",
            gap: 5,
            width: ["100vw", "90vw", "80vw", "70vw"],
            mt: [2, 3, 4, 5],
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              color: themes.liaMainColor,
            }}
          >
            <Box
              className="lia"
              sx={{
                height: ["120px", "140px", "180px", "250px"],
                width: ["120px", "140px", "180px", "250px"],
                borderRadius: "100%",
                overflow: "hidden",
                transition: "0.5s",
                boxShadow: "0 4px 5px  gray",
              }}
            >
              <ReactPlayer
                url={LiaClip}
                width="100%"
                height="100%"
                playing={true}
                loop={true}
                onReady={() => { }}
                volume={0}
                muted={true}
                style={{
                  zIndex: 5,
                }}
              />
            </Box>
            <Box
              id="botao3"
              data-intro="Aqui você visualizará seu nome abreviado e seu saldo"
              data-step="3"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: [1, 2, 3, 4],
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: themes.highlight,
                  fontWeight: 900,
                  fontSize: [24, 36, 40, 48],
                }}
              >
                Bem vindo(a)!
              </Typography>
              <Typography
                variant="h4"
                sx={{ ml: 1, fontSize: [16, 22, 26, 34] }}
              >
                {`${newName} ${abreviations}.`}
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: 900, fontSize: [14, 20, 24, 32] }}
              >
                <FaCoins style={{ color: themes.highlight }} />{" "}
                <span style={{ color: themes.highlight }}>
                  {isVisible ? balance : "****"}
                </span>{" "}
                tokens
                <Button
                  id="botao4"
                  data-intro="Seu saldo ficará oculto, para visualizar clique neste icone"
                  data-step="4"
                  sx={{
                    minWidth: "20px",
                    color: themes.highlight,
                    fontSize: [16, 16, 20, 20],
                  }}
                  onClick={() => {
                    handleToken();
                    handleVisibleToken();
                  }}
                >
                  {isVisible ? <VisibilityOff /> : <Visibility />}
                </Button>
                <Button
                  id="botao5"
                  data-intro="Para atulizar seu saldo, clique aqui!"
                  data-step="5"
                  sx={{
                    minWidth: "20px",
                    color: themes.highlight,
                    fontSize: [16, 16, 20, 20],
                  }}
                  onClick={() => {
                    getBalance();
                  }}
                >
                  <TfiReload />
                </Button>
              </Typography>
            </Box>
          </Box>
          <Box
            id="botao6"
            data-intro="Visualize seu perfil, compre tokens ou visualize seu extrato escolhendo uma dessas opções."
            data-step="6"
            className="dashboardBox--rectangleButtons"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: ["center"],
              justifyContent: ["center"],
              gap: [2, 2, 3, 4],
            }}
          >
            {buttons.map((el, i) => (
              <ButtonDashboardComponent
                key={i + el.text}
                Icon={el.icon}
                title={el.title}
                type="text"
                text={el.text}
                route={el.route}
                callback={el.callback}
                showTokens={el.showTokens}
              >
                {el.Component}
              </ButtonDashboardComponent>
            ))}
          </Box>
        </Box>

        <Box
          id="botao7"
          data-intro="Crie textos, publicações, redações e mais, escolhendo uma dessas ferramentas."
          data-step="7"
          className="dashboardBox--toolsButtons"
          sx={{ position: "relative", zindex: 50, pr: "5px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: ["row"],
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 3,
              width: "80vw",
              height: "100%",
              scrollBehavior: "smooth",
              position: "relative",
              zindex: 0,
              pb: 3,
              "::-webkit-scrollbar ": {
                width: "5px",
              },

              "::-webkit-scrollbar-track": {
                background: themes.background,
              },

              "::-webkit-scrollbar-thumb": {
                backgroundColor: themes.highlight,
                borderRadius: "20px",
              },
            }}
          >
            {empresas.length === 0 && (
              <Box
                sx={{
                  zIndex: 50,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: [-0, -25, -25, -50],
                  position: "relative",
                }}
              >
                <Alert
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  sx={{
                    "& .MuiAlert-icon": { fontSize: 32 },
                  }}
                  severity="warning"
                >
                  <AlertTitle> ATENÇÃO</AlertTitle>
                  Para ter acesso as ferramentas de marketing, é necessário
                  cadastrar pelo menos uma empresa.
                  <Link
                    sx={{
                      color: themes.highlight,
                      textDecoration: "none",
                      ":hover": { cursor: "pointer" },
                    }}
                    onClick={() => navigate("/cadastra_empresa")}
                  >
                    {" "}
                    Cadastrar
                  </Link>
                </Alert>
              </Box>
            )}
            {dashboardCards.length > 0 &&
              dashboardCards.map((el, i) =>
                el.items ? (
                  <DashboardSelectCard
                    needCompany={el.needCompany}
                    height={el.height}
                    key={i}
                    Icon={el.icon}
                    title={el.topic}
                    type="text"
                    text={el.topic}
                    route={el.route}
                    disabled={el.disabled}
                    items={el.items}
                    startAnimation={startAnimation}
                    setRoute={setRoute}
                    setSelectedCard={setSelectedCard}
                  />
                ) : (
                  <DashboardSelectCardUnique
                    key={i}
                    Icon={el.icon}
                    title={el.topic}
                    type={el.type}
                    text={el.text}
                    route={el.route}
                    disabled={el.disabled}
                    items={el.items}
                    startAnimation={startAnimation}
                    setRoute={setRoute}
                    setSelectedCard={setSelectedCard}
                  />
                )
              )}
          </Box>
        </Box>
      </Box>
    </TransitionWrapper>
  );
};
