import { Box, Button, Link, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { UserDescriptionData } from "../../Components/perfilComponents";
import { useState } from "react";
import { useUser } from "../../Contexts/UserProvider";
import { useEffect } from "react";
import { AccordionComponent } from "../../Components/accordion";
import { useCompany } from "../../Contexts/companyProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useGenText } from "../../Contexts/iaRequests";

//Pagina do perfil de usuario
export const Perfil = () => {
  const { themes } = useThemeChanger();
  const { envia_profile, userProfile } = useUser();
  const { recebe_empresas, empresas } = useCompany();
  const [selectedCompany, setSelectedCompany] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentRoute } = useGenText();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);
  //verifica se o usuario já possui alguma empresa cadastrada em seu nome
  useEffect(() => {
    if (empresas) {
      const sessionCompany = sessionStorage.getItem("@social:company");
      setSelectedCompany(
        empresas.find((el) => el.company_tag === sessionCompany)
      );
    }
  }, [empresas, window.sessionStorage.getItem("@social:company")]);

  const [count, setCount] = useState(0);
  const [secondRender, setSecondRender] = useState(false);

  //impede multiplas chamadas na API
  useEffect(() => {
    if (secondRender) {
      if (!userProfile.email) {
        recebe_empresas();
        envia_profile();
      }
    } else {
      setSecondRender(true);
    }
  }, [count, secondRender]);

  return (
    <Box
      className="profileContainer"
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row", "row"],
        height: "100%",
        width: "100%",
        mt: [0, 0, 5, 5],
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row", "row"],
          maxWidth: "100vw",
          minWidth: "100vw",
          width: "100vw",
          height: "100vh",
          gap: [2, 5, 0, 0],
          pt: [0, 1, 2, 5],
          pl: [0, 1, 2, 5],
        }}
      >
        <Box
          className="profileContainer--description"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: ["100%", "100%", "40%", "40%"],
            gap: [2, 2, 5, 5],
            mt: ["2rem", "2rem", "0", "0"],
          }}
        >
          <Typography
            className="textGen--text"
            sx={{
              fontSize: [14, 18, 22, 28],
              width: "80%",
              color: themes.textGray,
            }}
          >
            Dados pessoais e empresariais. Para alterar dados pessoais ou da
            empresa, entre em contato com o{" "}
            <Link
              sx={{
                fontSize: [14, 18, 22, 28],
                background: "none",
                color: themes.highlight,
                textDecoration: "none",
                ":hover": { background: "none", cursor: "pointer" },
              }}
              onClick={() => navigate("/suporte")}
            >
              suporte
            </Link>
            .
          </Typography>
        </Box>
        <Box
          className="profileContainer--userInfo"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: ["100%", "100%", "70%", "70%"],
            gap: [2, 2, 7, 7],
            pb: [2],
            overflowY: "scroll",
            height: ["80%", "80%", "80%", "80%"],
            "::-webkit-scrollbar ": {
              width: "5px",
            },

            "::-webkit-scrollbar-track": {
              background: themes.background,
            },

            "::-webkit-scrollbar-thumb": {
              backgroundColor: themes.highlight,
              borderRadius: "20px",
            },
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: [16, 16, 20, 24], color: themes.textGray }}
            >
              Dados Pessoais:
            </Typography>
            <AccordionComponent index={0} title={userProfile.user}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: themes.textGray,
                  ml: [2, 2, 12, 12],
                }}
              >
                <Box sx={{ ml: "5%" }}>
                  <UserDescriptionData
                    field="Nome"
                    userData={userProfile.user && userProfile?.user}
                  />
                  <UserDescriptionData
                    field="Whatsapp"
                    userData={
                      userProfile.profile && userProfile?.profile[0].whatsapp
                    }
                  />
                  <UserDescriptionData
                    field="CPF"
                    userData={
                      userProfile.profile && userProfile?.profile[0].cpf
                    }
                  />
                  <UserDescriptionData
                    field="Nascimento"
                    userData={
                      userProfile.profile && userProfile?.profile[0].birth
                    }
                  />
                  <UserDescriptionData
                    field="E-mail"
                    userData={userProfile && userProfile?.email}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: themes.textGray,
                  ml: [2, 2, 12, 12],
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: [16, 16, 20, 20],
                    fontWeight: 900,
                    ml: "5%",
                  }}
                >
                  Endereço:
                </Typography>

                <Box sx={{ ml: "5%" }}>
                  <UserDescriptionData
                    field="CEP"
                    userData={
                      userProfile.profile && userProfile?.profile[0].zip_code
                    }
                  />
                  <UserDescriptionData
                    field="Cidade"
                    userData={
                      userProfile.profile && userProfile?.profile[0].city
                    }
                  />
                  <UserDescriptionData
                    field="Rua"
                    userData={
                      userProfile.profile && userProfile?.profile[0].street
                    }
                  />
                  <UserDescriptionData
                    field="Bairro"
                    userData={
                      userProfile.profile &&
                      userProfile?.profile[0].neighborhood
                    }
                  />
                  <UserDescriptionData
                    field="Numero"
                    userData={
                      userProfile.profile && userProfile?.profile[0].number
                    }
                  />
                  <UserDescriptionData
                    field="Complemento"
                    userData={
                      userProfile.profile && userProfile?.profile[0].complement
                    }
                  />
                  <UserDescriptionData
                    field="UF"
                    userData={userProfile.profile && userProfile?.profile[0].uf}
                  />
                </Box>
              </Box>
            </AccordionComponent>
          </Box>

          {!selectedCompany ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: themes.textGray,
                justifyContent: "center",
                alignItems: "center",
                ml: [0, 0, 5, 5],
                gap: 2,
              }}
            ></Box>
          ) : (
            <Box
              className="profileContainer--businessInfo"
              sx={{
                pb: 5,
              }}
            >
              <Typography
                sx={{
                  color: themes.textGray,
                  fontSize: [16, 16, 20, 24],
                }}
              >
                Dados Empresarais:
              </Typography>

              {empresas.map((element, index) => (
                <AccordionComponent
                  key={index}
                  title={element.company_name}
                  index={index + 1}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color: themes.textGray,
                      ml: [0, 0, 5, 5],
                    }}
                  >
                    <Box sx={{ ml: "10%" }}>
                      <UserDescriptionData
                        field="Nome"
                        userData={element.company_name}
                      />
                      <UserDescriptionData
                        field="E-mail"
                        userData={element.email}
                      />
                      <UserDescriptionData
                        field="Categoria"
                        userData={element.category}
                      />
                      <UserDescriptionData
                        field="Sub Categorias"
                        userData={element.sub_category}
                      />
                      <UserDescriptionData
                        field="Endereço"
                        userData={element.street}
                      />
                      <UserDescriptionData
                        field="Cidade"
                        userData={element.city}
                      />
                      <UserDescriptionData
                        field="Nº"
                        userData={element.number}
                      />
                      <UserDescriptionData field="uf" userData={element.uf} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color: themes.textGray,
                      ml: [0, 0, 5, 5],
                      mt: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: [16, 16, 20, 20],
                        fontWeight: 900,
                        ml: "10%",
                      }}
                    >
                      Redes sociais:
                    </Typography>
                    <Box sx={{ ml: "10%" }}>
                      <UserDescriptionData
                        field="Site"
                        userData={element.site ? element.site : "n/a"}
                      />
                      <UserDescriptionData
                        field="Instagram"
                        userData={element.instagram ? element.instagram : "n/a"}
                      />
                      <UserDescriptionData
                        field="Facebook"
                        userData={element.facebook ? element.facebook : "n/a"}
                      />
                      <UserDescriptionData
                        field="Blog"
                        userData={element.blog ? element.blog : "n/a"}
                      />
                      <UserDescriptionData
                        field="Tiktok"
                        userData={element.tiktok ? element.tiktok : "n/a"}
                      />
                    </Box>
                  </Box>
                </AccordionComponent>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
