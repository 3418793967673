import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  FaBars,
  FaGift,
  FaHistory,
  FaPlusCircle,
} from "react-icons/fa";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { ModalLogOff } from "../modal";
import { useNavigate } from "react-router-dom";
import { TopContent } from "../topContent";
import { useState } from "react";
import { ToggleThemeComponent } from "../toggleTheme";
import { useCompany } from "../../Contexts/companyProvider";

export const DashboardSwipeDrawer = ({
  id,
  intro,
  step,
}) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { empresas } = useCompany();
  const { themes } = useThemeChanger();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleLogout = () => {
    open ? setOpen(false) : setOpen(true);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const arr = [
    {
      icon: <FaHistory />,
      text: "Histórico de Prompts",
      onClick: () => navigate(`/historico`),
    },
    {
      icon: <FaGift />,
      text: "Cupons",
      onClick: () => {
        navigate(`/bonus`);
      },
    },
    {
      icon: <ModalLogOff open={open} />,
      text: "Sair",
      onClick: () => toggleLogout(),
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: 300,
        padding: 2,
        height: "100%",
        background: themes.background,
      }}
      role="presentation"
    >
      <Box>
        <TopContent title={`Menu`} onClick={toggleDrawer(anchor, false)} />
        <ToggleThemeComponent />
      </Box>
      <List>
        {empresas.length < 5 && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={(e) => {
                navigate("/cadastra_empresa");
                e.stopPropagation();
              }}
              sx={{ color: themes.liaMainColor }}
            >
              <ListItemIcon
                sx={{ color: themes.highlight, width: 5, fontSize: 24 }}
              >
                <FaPlusCircle />
              </ListItemIcon>
              <ListItemText primary={"Adicionar empresa"} />
            </ListItemButton>
          </ListItem>
        )}
        {arr.map((element, index) => (
          <ListItem key={element.text + index} disablePadding>
            <ListItemButton
              onClick={(e) => {
                element.onClick();
                e.stopPropagation();
              }}
              sx={{ color: themes.liaMainColor }}
            >
              <ListItemIcon
                sx={{ color: themes.highlight, width: 5, fontSize: 24 }}
              >
                {element.icon}
              </ListItemIcon>
              <ListItemText primary={element.text} />
            </ListItemButton>
          </ListItem>
        ))}


      </List>
    </Box>
  );

  return (
    <Box>
      <Button
        id={id}
        data-intro={intro}
        data-step={step}
        sx={{
          width: 50,
          height: 50,
          fontSize: 24,
          color: themes.liaMainColor,
          background: "none",

          padding: [0, 0, 0, 0],
        }}
        onClick={toggleDrawer("right", true)}
      >
        <FaBars />
      </Button>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </Box>
  );
};

