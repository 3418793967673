import { Box, Button, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { motion } from "framer-motion";
import { useGenText } from "../../Contexts/iaRequests";
import { useBounty } from "../../Contexts/bountyProvider";
import { toast } from "react-toastify";

//Botões de ferramentas do social lia (swipper aberto)
export const DashboardCard = ({
  Icon,
  title,
  disable = true,
  type,
  onClick,
}) => {
  const { themes } = useThemeChanger();
  const { setType } = useGenText();
  const { balance, getBalance } = useBounty();

  const handleOpen = () => {
    if (!balance) {
      getBalance()
    }
    if (balance  <= 0) {
      toast.error('Saldo insuficiente, verifique seu saldo.')
    }
    else {
      setType(type);

      if (!disable) {
        onClick();
      }
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Button
        className="toolButton"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 0,
          overflow: "hidden",
          textTransform: "capitalize",
        }}
        onClick={() => { handleOpen() }}
      >
        <Box
          className="toolButton--content"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: themes.liaMainColor,
            position: "relative",
            transition: "0.3s",
            fontWeight: 900,
            ":hover": {
              color: disable ? themes : themes.highlight,
              cursor: "pointer",
            },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: [20, 20, 48, 48],
              letterSpacing: 6,
              gap: 2,
            }}
          >
            <Icon />
            <Typography
              sx={{
                fontSize: [16, 16, 14, 20],
                width: "150px",
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Button>
    </motion.div>
  );
};
