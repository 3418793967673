import { Box } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente responsavel pela cor de fundo e dimenção dos outros componentes que estarão sempre dentro deste
export const Background = ({ children }) => {
  const { themes } = useThemeChanger();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        transition: "1s",
        background: themes.background,
        mb:5
      }}
    >
      {children}
    </Box>
  );
};
