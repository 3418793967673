import { Container } from "@mui/material";
import { BonusComponent } from "../../Components/bonusComponent";
import { HeaderComponent } from "../../Components/header";
import TransitionWrapper from "../../Components/screenTransition";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GiftAnimation } from "../../Components/giftsAnimation";
import { useEffect } from "react";
import { useGenText } from "../../Contexts/iaRequests";

//Pagina Bonus
export const Bonus = () => {
  const navigate = useNavigate();
  const { setCurrentRoute } = useGenText();
  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);

  const [isAnimating, setIsAnimating] = useState(false);
  const startAnimation = () => {
    setIsAnimating(true);
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate("/dashboard")}
    >
      <Container
        className="bonusContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          minWidth: "100vw",
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          gap: 4,
          overflowX: "hidden",
        }}
      >
        <HeaderComponent startAnimation={startAnimation} />
        <BonusComponent startAnimation={startAnimation} />
        <GiftAnimation />
      </Container>
    </TransitionWrapper>
  );
};
