import * as Yup from "yup";
import { useFormik } from "formik";
import { Container } from "@mui/material";
import { handleEmail } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../../button";
import { useUser } from "../../../Contexts/UserProvider";
import { InputComponent, PasswordInput } from "../../input";
import { useThemeChanger } from "../../../Contexts/themeProvider";

//Componente responsavel pelo login do usuario
export const Login = ({ firstLogin, onClick, className, transform }) => {
  const { themes } = useThemeChanger();
  const { login } = useUser();
  const navigate = useNavigate();

  //Validação utilizando YUP
  const validationSchema = Yup.object({
    email: Yup.string().email("E-mail inválido").required("E-mail obrigatório"),
    password: Yup.string().required("Senha obrigatória"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(JSON.stringify(values));
    },
  });

  return (
    <Container
      id="login"
      className={className}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        height: ["75vh", "75vh", "90%", "90%"],
        minWidth: ["80vw", "80vw", "auto", "auto"],
        borderRadius: [2, 2, 0, 0],
        left: 0,
        zIndex: 1,
        transform: `translate(${transform}%)`,
        boxShadow: ["0 0 8px gray", "0 0 8px gray", 0, 0],
        transition: "1s",
        background: themes.background,
        mt: 1,
      }}
    >
      <p
        style={{
          alignSelf: "center",
          fontSize: "20px",
          fontFamily: themes.typograph.title,
          color: themes.textGray,
        }}
      >
        Acesse já!
      </p>

      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          width: "100%",
          height: "40%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <InputComponent
          id="email"
          label="E-mail"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            handleEmail(e, formik.setFieldValue);
          }}
          errors={formik.touched.email && formik.errors.email}
        />

        <PasswordInput
          id="password"
          name="password"
          label="Senha"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.touched.password && formik.errors.password}
        />
        <ButtonComponent type="submit" text="Acessar" />
        <button
          onClick={() => navigate("/recuperar_senha")}
          style={{
            color: themes.highlight,
            textDecoration: "none",
            cursor: "pointer",
            border: "none",
            background: "none",
          }}
          href=""
        >
          Esqueceu sua senha?
        </button>
      </form>
      {firstLogin && (
        <>
          <hr
            style={{ border: `1px solid ${themes.textGray}`, width: "90%" }}
          />

          <p
            style={{
              color: themes.textGray,
              width: "90%",
              textAlign: "center",
            }}
          >
            Você ainda não possui uma conta ?
            <button
              onClick={() => onClick()}
              style={{
                color: themes.highlight,
                textDecoration: "none",
                cursor: "pointer",
                border: "none",
                background: "none",
                fontSize: "16px",
              }}
              href=""
            >
              Faça seu cadastro agora
            </button>
            É rápido!
          </p>
        </>
      )}
    </Container>
  );
};
