import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useGenText } from "../../Contexts/iaRequests";

export const AlertComponent = () => {
  const { themes } = useThemeChanger();
  const { setLazy, currentRoute } = useGenText();

  const [tempoRestante, setTempoRestante] = useState(30);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentRoute !== location.pathname) {
      setLazy(false);
    }
  }, [currentRoute]);

  useEffect(() => {
    const temporizador = setInterval(() => {
      if (tempoRestante > 0) {
        setTempoRestante(tempoRestante - 1);
      } else {
        setLazy(false);
        clearInterval(temporizador); // Parar o temporizador quando o tempo acabar
        navigate("/historico");
      }
    }, 1000);

    return () => {
      clearInterval(temporizador); // Limpar o temporizador quando o componente for desmontado
    };
  }, [tempoRestante]);

  return (
    <motion.div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        zIndex: 80,
      }}
      initial={{ transform: "translateY(100vh)", opacity: 0 }}
      animate={{ transform: "translateY(0)", opacity: 1 }}
      exit={{ transform: "translateY(100vh)", opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          background: "rgba(0,0,0,0.5)",
          position: "absolute",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            background: themes.background,
            border: `5px solid ${themes.liaMainColor}`,
            borderRadius: [5, 5, 10, 10],
            p: [2, 3, 4, 5],
          }}
        >
          <Typography
            sx={{
              color: themes.liaMainColor,
              fontWeight: 800,
              fontSize: [24, 28, 32, 36],
            }}
          >
            OPS!!!
          </Typography>
          <Typography
            sx={{ color: themes.liaMainColor, fontSize: [12, 16, 20, 24] }}
          >
            Vamos te redirecionar para o histórico. Verifique se o pedido foi
            concluído, caso não, entre em contato com o suporte pelo menu.
            <br />
            <b>Lembre-se selecionar a área utilizada!</b>
            <br />
            <br />
            Redirecionando em: {tempoRestante}s
          </Typography>
        </Box>
      </Box>
    </motion.div>
  );
};
