import styled from "styled-components";

export const List = styled.ul`
  font-size: 12px;
  width: 90%;
  @media (min-width: 800px) {
    font-size: 16px;
    width: 70%;
  }
  @media (min-width: 1000px) {
    font-size: 16px;
    width: 70%;
  }
`;

export const Img = styled.img`
  transition: 0.5s;
  border-radius: 10px 0 0 10px;
  width: 30%;

  @media (max-width:764px) {
      width: 60%;
  }
`;
