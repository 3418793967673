import * as Yup from "yup";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { ButtonComponent } from "../../button";
import { handleEmail, handleName } from "../../../utils";
import { useUser } from "../../../Contexts/UserProvider";
import { PasswordInput, InputComponent } from "../../input";
import { useThemeChanger } from "../../../Contexts/themeProvider";

//Componente de cadastro do usuario
export const SignUp = ({ onClick, className }) => {
  const { themes } = useThemeChanger();
  const { signUp } = useUser();

  const validationSchema = Yup.object({
    username: Yup.string()
      .required("Nome de usuário é necessário.")
      .matches(
        /^[A-ZÀ-ÚÃÕÇ][a-zà-úãõç]+\s([A-ZÀ-ÚÃÕÇ][a-zà-úãõç]+(\s)?)+$/,
        "Necessário nome e sobrenome."
      ),
    email: Yup.string()
      .required("E-mail é necessário")
      .email("Digite um e-mail valido, ex: usuario@email.com"),
    password: Yup.string()
      .required("Senha é necessária")
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        "senha: No minimo 8 caracteres; um número; uma letra maiuscula; uma letra minuscula; um caracter especial"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Senhas diferentes")
      .required("Necessário a confirmação de senhas."),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      signUp(values);
    },
  });

  return (
    <Box
      className={className}
      id="register"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        height: ["75vh", "75vh", "100%", "100%"],
        minWidth: ["80vw", "80vw", "auto", "auto"],
        borderRadius: [2, 2, 0, 0],
        left: 0,
        zIndex: 2,
        boxShadow: ["0 0 8px gray", "0 0 8px gray", 0, 0],
        transition: "1s",
        background: themes.background,
      }}
    >
      <p
        style={{
          alignSelf: "center",
          fontSize: "20px",
          color: themes.textGray,
        }}
      >
        Cadastre-se
      </p>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          width: "100%",
          height: "60%",
        }}
        onSubmit={formik.handleSubmit}
      >

        <InputComponent
          id="username"
          label="Nome e sobrenome*"
          type="text"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            handleName(e, formik.setFieldValue);
          }}
          errors={formik.touched.username && formik.errors.username}
        />
        <InputComponent
          id="email"
          label="E-mail*"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            handleEmail(e, formik.setFieldValue);
          }}
          errors={formik.touched.email && formik.errors.email}
        />
        <PasswordInput
          id="password"
          name="password"
          label="Senha*"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.touched.password && formik.errors.password}
        />
        <PasswordInput
          id="confirmPassword"
          name="confirmPassword"
          label="Confirme sua senha"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
        <ButtonComponent type="submit" text="Cadastrar" />
      </form>

      <hr style={{ border: "1px solid #C1C1C1", width: "90%" }} />

      <p
        style={{
          color: themes.textGray,
          width: "90%",
          textAlign: "center",
        }}
      >
        Você já tem conta? {" "}
        <button
          onClick={() => onClick()}
          style={{
            color: themes.highlight,
            textDecoration: "none",
            cursor: "pointer",
            border: "none",
            background: "none",
            fontSize: "16px",
          }}
          href=""
        >
          Acesse !
        </button>
      </p>
    </Box>
  );
};
