import { Router } from "./Routes/routes";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AppProvider } from "./Contexts";
import { CustomThemeProvider } from "./Contexts/themeProvider";
import { theme } from "./Styles/themes";
import { Background } from "./Components/background";
import { ToastContainer } from "react-toastify";
import { useUser } from "./Contexts/UserProvider";
import { CircularIndeterminate } from "./Components/loadingComponents/loading";
import { TagDevelop } from "./Components/tagDesenvovimento";

function App() {
  const { isLoading } = useUser();

  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <CustomThemeProvider>
          <CssBaseline />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Background>
            {/* <TagDevelop /> */}
            {isLoading && <CircularIndeterminate />}
            <Router />
          </Background>
        </CustomThemeProvider>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
