import { ReturnDashButton } from "../button";
import { Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente de descrição da pagina de geração de texto
export const Description = ({
  isHistory,
  isMobile,
  title,
  text,
  setIsHistory,
}) => {
  const { themes } = useThemeChanger();
  return (
    <>
      <Typography
        className="textGen--title"
        sx={{
          display: ["none", "none", "flex", "flex"],
          fontSize: [18, 22, 28, 36],
          fontWeight: 600,
          width: "80%",
          color: themes.liaMainColor,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <Typography
        className="textGen--text"
        sx={{
          display: ["none", "none", "flex", "flex"],
          fontSize: [14, 18, 22, 28],
          width: "80%",
          color: themes.textGray,
        }}
      >
        {text}
      </Typography>
      {!isMobile && isHistory && (
        <ReturnDashButton
          onClick={() => setIsHistory(false)}
          setIsHistory={setIsHistory}
        />
      )}
    </>
  );
};
