import { Typography } from "@mui/material";
import { useThemeChanger } from "../../../Contexts/themeProvider";

//componente de revisão dos itens do cadastro de empresas
export const ReviewItem = ({ title, value, required }) => {
  const { themes } = useThemeChanger();

  return (
    <Typography>
      {title}:{" "}
      <span
        style={{
          color: required ?  themes.highlight : themes.liaMainColor,
          fontWeight: required ? "bolder" : "normal",
        }}
      >
        {" "}
        {value}
      </span>
    </Typography>
  );
};
