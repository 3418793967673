import styled from "styled-components";

export const Img = styled.img`
    width: 70px;
    height: 40px;
  :hover {
    cursor: pointer;
  }
  @media (min-width: 600px) {
    width: 100px;
    height: 70px;
  }
`;
