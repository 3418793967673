import { compareData } from "../../utils";
import { useState, useEffect } from "react";
import { useUser } from "../../Contexts/UserProvider";
import { Container } from "@mui/material";
import { CadastroProfileUsuario } from "../../Components/credentialsComponents/userProfileCredentials";
import { CircularIndeterminate } from "../../Components/loadingComponents/loading";
import { DashboardComponent } from "../../Components/dashboardComponent";
import { ModalValidateWhats } from "../../Components/modal";
import TransitionWrapper from "../../Components/screenTransition";
import { useThemeChanger } from "../../Contexts/themeProvider";
import Cookies from "js-cookie";

//Pagina dashboard ( cadastro de perfil de usuario e dashboard)
export const Dashboard = () => {
  const [hash, setHash] = useState(Cookies.get("social:ha"));
  const { themes } = useThemeChanger();
  const { isLoading, verify } = useUser();

  useEffect(() => {
    setHash(Cookies.get("social:ha"));
  }, [hash, verify]);

  return (
    <TransitionWrapper>
      <Container
        className="dashboardContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          minWidth: "100vw",
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          overflowX: "hidden",
          overflowY: "auto",
          background: themes.background,
          pb: 10,
        }}
      >
        {Cookies.get("social:isA") === "false" && <ModalValidateWhats />}

        {isLoading && <CircularIndeterminate />}

        {compareData("true", hash) ? (
          <DashboardComponent />
        ) : (
          <CadastroProfileUsuario />
        )}
      </Container>
    </TransitionWrapper>
  );
};
