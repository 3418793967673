import * as yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Box, TextField } from "@mui/material";
import { useUser } from "../../Contexts/UserProvider";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente de inserção de numeros para validar 2FA
export const NumberFields = ({ bgColor }) => {
  const { valida_whatsapp, whatsNumber } = useUser();
  const { themes } = useThemeChanger();

  //Validação do formulario com YUP
  const codeSchema = yup.object({
    code: yup.string().required(),
  });

  const formikCode = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeSchema,
    onSubmit: (values) => {
      const dataFormated = { whatsapp: whatsNumber, code: values.code };
      valida_whatsapp(dataFormated);
    },
  });

  //Compara o tamanho da string inserida para executar a função
  useEffect(() => {
    if (formikCode.values.code.length === 6) {
      formikCode.handleSubmit();
    }
  }, [formikCode.values]);

  //Formata os valores permitindo apenas numeros
  const formater = (event) => {
    let input = event.target;
    input.value = formatValue(input.value);
  };

  const formatValue = (value) => {
    if (value.toString().length <= 6) {
      formikCode.setFieldValue("code", value);
    }
    if (!value) return "";
    value = value.replace(/\D/g, "");

    return value.trim();
  };

  return (
    <Box
      className="code2FA--box"
      sx={{
        display: "flex",
        borderRadius: "5px",
        gap: 1,
      }}
    >
      <TextField
        className="code2FA--box--input"
        id="code"
        variant="standard"
        type="number"
        autoComplete="off"
        InputLabelProps={{ style: { fontSize: 16, color: themes.highlight } }}
        inputProps={{ maxLength: 6 }}
        autoFocus
        onChange={(e) => {
          formikCode.handleChange(e);
          formater(e);
        }}
        onBlur={formikCode.handleBlur}
        sx={{
          width: ["80vw", "80vw", 300, 400],
          height: [62],
          "& input": {
            background: bgColor,
            borderBottom: `2px solid ${themes.liaMainColor}`,
            fontSize: [20, 28, 32, 36],
            color: themes.highlight,
            height: [42, 42, 65, 65],
            letterSpacing: "2rem",
            textAlign: "center",
            "::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          },
        }}
      />
    </Box>
  );
};
