import { createContext, useCallback, useContext, useState } from "react";
import { api } from "../Services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./UserProvider";
import Cookies from "js-cookie";
import {  useNavigate } from "react-router-dom";
import { useEffect } from "react";

const uuid_ganhador = "759bff3b-a5e8-4d6d-af53-fbcaf98d5359";

const SorteioContext = createContext({});

export const useSorteio = () => {
  const context = useContext(SorteioContext);
  if (!context) {
    throw new Error("useSorteio must be used within an ProjectProvider");
  }
  return context;
};

//Context responsavel pelos sorteios realizados(paginas a parte do projeto principal)
export const SorteioProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [nomes, setNomes] = useState([]);
  const [sorteios, setSorteios] = useState([]);

  const [prompt, setPrompt] = useState("");
  const [usuario, setUsuario] = useState("");
  const [display, setDisplay] = useState("flex");
  const [displayText, setDisplayText] = useState("");
  const [type, setType] = useState("");

  const [spentTokens, setSpentTokens] = useState(0);

  const [lazy, setLazy] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const [initialRoute, setInitialRoute] = useState("");
  const [currentRoute, setCurrentRoute] = useState("");

  const header = {
    "Content-Type": "application/json",
  };

  //Converte JSON para Base64
  const objectToJsonBase64 = (obj) => {
    try {
      const jsonString = JSON.stringify(obj);
      const encoder = new TextEncoder();
      const data = encoder.encode(jsonString);
      const base64String = btoa(String.fromCharCode(...data));
      return base64String;
    } catch (error) {
      console.error("Erro ao converter objeto JSON em Base64:", error);
      return null;
    }
  };

  //Função que formata os nomes para formato de titulo
  const titleName = (name) => {
    const arr = name.trim().toLowerCase().split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  };

  const { signOut, setTokens } = useUser();
  //Busca na API os eventos que estão acontecendo atualmente
  const recebe_campanhas_sorteios = useCallback(async () => {
    setIsLoading(false);
    await api
      .get("/captura_campanhas_sorteios", { headers: header })
      .then((res) => {
        setIsLoading(true);
        setSorteios(res.data);
        return res.data;
      })
      .catch((err) => toast.error(err.response.data.message));
  }, []);

  //Descobre o status do usuario dentro do sorteio(concorrendo, perdeu, ganhou)
  const recebe_usuario_status_sorteio = useCallback(async (data) => {
    await api
      .post("/pesquisa_usuario_sorteios", data, { headers: header })
      .then((res) => {
        return setUsuario(res.data);
      })
      .catch((err) => {
        if (err.response.status === 429) {
          toast.error(
            "Muitas tentaivas, aguarde alguns minutos antes da sua próxima pesquisa."
          );
        }
        toast.error(err.response.data.message);
      });
  }, []);

  //cadastra usuarios ao evento atual
  const kremerSorteio = useCallback(async (data) => {
    const tag_sorteio = "Sorteio-Kremer-Julho-2023";

    const dataToPrizeDraw = {
      nome: titleName(data.nome),
      email: data.email.trim().toLowerCase(),
      cidade: `${data.cep.localidade} - ${data.cep.uf}`,
      telefone: data.telefone,
      cpf: data.cpf,
      nascimento: data.nascimento,
      tags: [tag_sorteio],
      sorteado: null,
    };

    const dadosParaLia = {
      nome: titleName(data.nome),
      numero: `55${data.telefone.replace(/[\s()+-]/g, "")}`,
      cep: data.cep.cep.replace(/[.-]/g, ""),
      estado: data.cep.uf,
      cidade: data.cep.localidade,
      tag: tag_sorteio,
    };

    await api
      .post("/cadastra_candidatos_sorteio", dataToPrizeDraw, {
        headers: header,
      })
      .then((res) => {
        setSuccess(true);
        envia_lead_lia_whats(dadosParaLia);
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 429) {
          toast.error(
            "Muitas tentaivas, aguarde alguns minutos antes da sua próxima pesquisa."
          );
        }
        toast.error(err.response.data.message);
      });
  }, []);

  //Pega todos os usuarios cadastrado no sorteio passado por tag
  const recebe_candidatos_sorteio = useCallback(async () => {
    const token = process.env.TOKEN;

    const data = {
      tag: "Sorteio-Kremer-Julho-2023",
      token: "2740482e-afd0-4577-8095-fb6c87ff2f9e",
    };

    setIsLoading(false);
    await api
      .post("/pega_leads_sorteio", data)
      .then((res) => {
        setIsLoading(true);
        setNomes(res.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  }, []);

  //envia a confirmação de cadastro do usuario no evento
  const envia_lead_lia_whats = useCallback(async (data) => {
    await api
      .post("/envia_lead_lia_whats", data)
      .then((res) => res)
      .catch((err) => toast.error(err));
  }, []);
  //Envia mensagem direta para o whatsapp do vencedor e para o dono do sorteio
  const envia_resultado_para_lia = useCallback(async (data) => {
    const formatedData = {
      nome: data.nome,
      phone: `55${data.telefone.replace(/[\s()+-]/g, "")}`,
      cpf: data.cpf,
      data_nascimento: data.nascimento,
      email: data.email,
      cidade: data.cidade,
      phone_fornecedor: "5511996556029",
      tag: "Sorteio-Kremer-Julho-2023",
      info: '*Importante* A retirada do seu prêmio "combo de chopp" é válida APENAS para os dias de rodeio.',
      token: process.env.TOKEN,
    };

    await api
      .post(`/ganhador/${uuid_ganhador}`, formatedData)
      .then((res) => res)
      .catch((err) => toast.error(err));
  }, []);

  //Envia pergunta para o chatGPT essa função é utilizada na page "Festa_da_uva"
  const perguntas_respostas_lia = useCallback(async (data) => {
    setIsLoading(true);
    setPrompt("");

    await api
      .post(`/perguntas_respostas_lia`, data)
      .then((res) => {
        const resposta = res.data.choices[0].message.content;
        setPrompt(resposta);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const status = err.request.status;
        setDisplay("flex");
        if (status === 429) {
          toast.error(
            "Aguarde alguns instantes antes de realizar sua pergunta."
          );
          setPrompt("Aguarde alguns instantes antes de realizar sua pergunta.");
          return;
        }
        toast.error(err.response.data.message);
      });
  }, []);


  return (
    <SorteioContext.Provider
      value={{
        initialRoute,
        currentRoute,
        spentTokens,
        displayText,
        data: data,
        generating,
        isLoading,
        sorteios,
        success,
        usuario,
        display,
        prompt,
        nomes,
        type,
        lazy,
        setData,
        setLazy,
        setType,
        setNomes,
        setPrompt,
        setSuccess,
        setDisplay,
        setIsLoading,
        setGenerating,
        kremerSorteio,
        setSpentTokens,
        setDisplayText,
        setInitialRoute,
        setCurrentRoute,
        perguntas_respostas_lia,
        envia_resultado_para_lia,
        recebe_campanhas_sorteios,
        recebe_candidatos_sorteio,
        recebe_usuario_status_sorteio,
      }}
    >
      {children}
    </SorteioContext.Provider>
  );
};
