import React from "react";
import { motion } from "framer-motion";

//Animação de transição de telas - https://www.framer.com/
const TransitionWrapper = ({
  children,
  callback,
  isAnimating,
  setIsAnimating,
}) => {
  const handleAnimationComplete = () => {
    setIsAnimating(false);
    callback();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isAnimating ? 0 : 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      onAnimationComplete={isAnimating && handleAnimationComplete}
    >
      {children}
    </motion.div>
  );
};
export default TransitionWrapper;
