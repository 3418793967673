import { Box, Button, Link, Popover, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { FaDollarSign, FaInfoCircle, FaScroll } from "react-icons/fa";
import { useState } from "react";

//Cartões do historico de compras de tokens
export const BuyTokenHistoryCard = ({
  payment_method,
  receipt_link,
  spent_location,
  token_volume,
  total_amount,
  transaction_date,
  transaction_id,
  transaction_type,
}) => {
  const { themes } = useThemeChanger();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
    className='historyTokensCard'
      sx={{
        display: "flex",
        boxShadow: `0 0 9px ${themes.textGray}`,
        minHeight: ["4rem", "4rem", "6rem", "6rem"],
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          background:
            transaction_type === "expense"
              ? themes.expense
              : themes.acquisition,
          color: themes.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: ["20%", "20%", "15%", "10%"],
          fontSize: [24, 48],
          borderRadius: "10px 0 0 10px",
        }}
      >
        <FaDollarSign />
      </Box>
      <Box
      className='historyTokensCard--info'
        sx={{
          color: themes.liaMainColor,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: ["2px 5px", "5px 5px", "5px 2rem", "5px 2rem"],
          fontWeight: 900,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontWeight: 900,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: [10, 12, 14, 16] }}>
              Data:{" "}
              <span style={{ fontWeight: "bolder" }}>{transaction_date}</span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                sx={{
                  fontSize: [16, 16, 24, 24],
                  p: [0, 0, 0, 0],
                  minWidth: 24,
                  borderRadius: "50%",
                  transition: "0.5s",
                  color: themes.liaMainColor,
                  opacity: 0.7,
                }}
                onClick={handleClick}
              >
                <FaInfoCircle />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography
                  sx={{
                    background: themes.background,
                    color: themes.liaMainColor,
                    padding: 2,
                    border: `1px solid ${themes.liaMainColor}`,
                    borderRadius: 2,
                  }}
                >
                  Transação: <span>{transaction_id}</span>
                </Typography>
              </Popover>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{ fontSize: [10, 12, 14, 16], width: "20%", alignSelf: "end" }}
          >
            Quantidade: <br />
            <span
              style={{
                fontWeight: "bolder",
                color:
                  transaction_type === "expense"
                    ? themes.expense
                    : themes.acquisition,
              }}
            >
              {transaction_type === "expense"
                ? "-" + token_volume
                : "+" + token_volume}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: [10, 12, 14, 16],
              width: ["40%", "40%", "30%", "30%"],
              alignSelf: "end",
            }}
          >
            <span style={{ color: themes.liaMainColor }}>
              {transaction_type === "expense"
                ? `Gasto no item: `
                : `Método da compra: `}
            </span>
            <br />
            <Typography
              sx={{
                fontSize: [8, 8, 12, 12],
                fontWeight: "bolder",
                color: themes.highlight,
              }}
            >
              {transaction_type === "expense" ? (
                spent_location
              ) : (
                <>
                  {payment_method}
                  <Link
                    variant="caption"
                    href={receipt_link}
                    target="_blank"
                    sx={{
                      color: themes.liaMainColor,
                      fontSize: [10, 12, 14, 14],
                      ml: 1,
                    }}
                  >
                    <FaScroll />
                  </Link>
                </>
              )}
            </Typography>
          </Typography>
          <Typography
            sx={{
              color: themes.highlight,
              fontWeight: 900,
              fontSize: [10, 12, 14, 16],
              alignSelf: "end",
            }}
          >
            <span style={{ color: themes.liaMainColor }}>R$</span>
            <br />
            {transaction_type === "expense"
              ? total_amount.toFixed(2)
              : total_amount.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
