import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente de duvidas sobre social Lia
export const SocialLiaDoubt = () => {
  const { themes } = useThemeChanger();

  return (
    <Box
      className="liaDoubtsContainer"
      sx={{
        color: themes.liaMainColor,
        width: "80vw",
        height: "70vh",
        px: "2rem",
        overflowY: "scroll",
        "::-webkit-scrollbar ": {
          width: "5px",
        },

        "::-webkit-scrollbar-track": {
          background: themes.background,
          margin: "1rem 0",
        },

        "::-webkit-scrollbar-thumb": {
          backgroundColor: themes.highlight,
          borderRadius: "20px",
        },
      }}
    >
      <Typography
        className="liaDoubtsContainer--title"
        sx={{
          textAlign: `center`,
          fontWeight: `bolder`,
          fontSize: 24,
          color: themes.highlight,
          mb: 5,
        }}
      >
        O que é Social Lia?
      </Typography>
      <Box
        className="liaDoubtsContainer--description"
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: 2,
          pb: 2,
          color: themes.liaMainColor,
        }}
      >
        <Typography>
          Bem-vindo à Social LIA, a plataforma revolucionária que dá vida às
          suas ideias por meio da magia da palavra escrita. Aqui, estamos
          empenhados em oferecer a você uma experiência única e empoderadora,
          permitindo que você crie conteúdos excepcionais de maneira
          simples e eficaz.
        </Typography>
        <Typography sx={{ fontWeight: 900 }}>
          Explore, Crie, Compartilhe: Seu Mundo de Palavras ao Seu Alcance
        </Typography>
        <Typography>
          Com Social LIA, acreditamos no poder das palavras para conectar,
          influenciar e inspirar. É por isso que criamos um ambiente digital
          dinâmico, onde você pode acessar uma ampla gama de serviços de
          redação, tudo a partir de um único local conveniente. Imagine-se
          navegando em uma coleção abrangente de categorias, desde artigos
          informativos e postagens em redes sociais e muito mais. A escolha é
          sua, e a plataforma Social LIA coloca o poder de
          criação nas suas mãos.
        </Typography>
        <Typography sx={{ fontWeight: 900 }}>
          Simplicidade e Flexibilidade: A Magia por Trás da Social LIA
        </Typography>
        <Typography>
          Não importa se você é um aspirante a escritor, um empreendedor em
          busca de conteúdo de qualidade ou alguém que queira compartilhar suas
          ideias com o mundo, a Social LIA tem tudo o que você precisa. O
          processo é tão fácil quanto 1, 2, 3:
        </Typography>
        <Typography>
          Acesse Sua Conta: Faça login na sua conta pessoal na Social LIA para
          começar sua jornada criativa.
        </Typography>
        <Typography>
          Escolha Seu Pacote de Tokens: Nós valorizamos sua liberdade de
          escolha. Selecione o pacote de tokens que melhor se adapta às suas
          necessidades, dando-lhe acesso total aos nossos serviços de redação.
        </Typography>
        <Typography>
          Explore e Crie: Navegue pelas categorias e selecione o serviço que
          mais se alinha aos seus objetivos. Com um toque de botão, seus tokens
          serão usados para gerar conteúdo sob medida, atendendo às suas
          especificações e requisitos.
        </Typography>
        <Typography sx={{ fontWeight: 900 }}>
          Apoio Profissional a Cada Passo do Caminho
        </Typography>{" "}
        <Typography>
          Não estamos apenas comprometidos em fornecer um ambiente criativo
          excepcional, mas também estamos aqui para apoiá-lo ao longo de toda a
          sua jornada. Nossa equipe de especialistas em redação está à
          disposição para esclarecer dúvidas, oferecer orientações e garantir
          que sua experiência na Social LIA seja tão suave e satisfatória
          quanto possível.
        </Typography>{" "}
        <Typography sx={{ fontWeight: 900 }}>
          Junte-se à Revolução Textual com a Social LIA
        </Typography>{" "}
        <Typography>
          Chegou a hora de transformar suas ideias em realidade textual. A
          Social LIA é o seu parceiro confiável nessa busca criativa, oferecendo
          a você as ferramentas e os recursos necessários para criar conteúdo
          impactante e autêntico. Sua voz merece ser ouvida, e a Social LIA está
          aqui para garantir que suas palavras ressoem e inspirem.
        </Typography>{" "}
      </Box>
    </Box>
  );
};
