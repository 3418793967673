import { AsassProvider } from "./asassProvider";
import { BountyProvider } from "./bountyProvider";
import { CepProvider } from "./cepProvider";
import { CompanyProvider } from "./companyProvider";
import { GenTextProvider } from "./iaRequests";
import { SorteioProvider } from "./sorteios";
import { UserProvider } from "./UserProvider";

export const AppProvider = ({ children }) => {
  return (
    <CompanyProvider>
      <UserProvider>
        <AsassProvider>
          <BountyProvider>
            <GenTextProvider>
              <SorteioProvider>
                <CepProvider>{children}</CepProvider>
              </SorteioProvider>
            </GenTextProvider>
          </BountyProvider>
        </AsassProvider>
      </UserProvider>
    </CompanyProvider>
  );
};
