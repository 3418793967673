import { Container } from "./styles";
import { Login } from "../loginCard";
import { SignUp } from "../registerCard";
import { useUser } from "../../../Contexts/UserProvider";
import { useThemeChanger } from "../../../Contexts/themeProvider";

//Pagina inicial de cadastro/login Desktop
export const DesktopCredentials = () => {
  const { themes } = useThemeChanger();

  const { toogleSlideContainer, containerClass } = useUser();
  return (
    <Container className="inicialScreenDeskTopContainer" color={themes}>
      <div className={`container ${containerClass}`} id="container">
        <div className="form-container sign-up-container">
          <SignUp onClick={toogleSlideContainer} />
        </div>
        <div className="form-container sign-in-container">
          <Login firstLogin={true} onClick={toogleSlideContainer} />
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Bem vindo!</h1>
              <p>
                Cadastre-se e tenha acesso a ferramentas incríveis que te
                ajudarão no dia a dia.
              </p>
            </div>
            <div className="overlay-panel overlay-right">
              <h1>Inteligencia artificial!</h1>
              <p>
              A Lia é uma Inteligência Artificial prática, fácil e rápida de ser acessada através do seu WhatsApp. Clique abaixo e acesse a Lia. 
              </p>
              <a
                className="ghost"
                id="signUp"
                href="https://falecomlia.com.br/"
                rel="noreferrer"
                target="_blank"
              >
                Acesse o site
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
