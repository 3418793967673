import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Temporizador usado para as validações 2FA
export const Timer = ({ callback, fontSize }) => {
  const [timeRemaining, setTimeRemaining] = useState(120);
  const { themes } = useThemeChanger();

  //Função que será executada a cada segundo do temporizador. Atualiza o tempo restante e chama o callback caso o tempo acabe
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  //callback chamada quando o tempo acaba
  useEffect(() => {
    if (timeRemaining === 0) {
      callback();
    }
  }, [timeRemaining, callback,0]);

  //Fomata o tempo para mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  return (
    <Typography
      className="timerBox"
      sx={{
        fontSize: fontSize,
        fontWeight: 900,
        color: themes.text,
      }}
    >
      {formatTime(timeRemaining)}
    </Typography>
  );
};
