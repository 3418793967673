import { api } from "../Services/api";
import { toast } from "react-toastify";
import { compareData, generateHash, sortByDate } from "../utils";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { createContext, useCallback, useContext, useState } from "react";
import { useCompany } from "./companyProvider";
import Cookies from "js-cookie";

const UserContext = createContext({});
//Context responsavel por gerenciamento de usuario

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [nomes, setNomes] = useState([]);
  const [historyCards, setHistoryCards] = useState([]);
  const [userProfile, setUserProfile] = useState({});

  const [open, setOpen] = useState(false);
  const [verify, setVerify] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [enableTimer, setEnableTimer] = useState(false);
  const [twoFactor, setTwoFactor] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [primeiroEnvio, setPrimeiroEnvio] = useState(true);

  const [email, setEmail] = useState("");
  const [whatsNumber, setWhatsNumber] = useState("");
  const [containerClass, setContainerClass] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [message, setMessage] = useState([
    { name: "Lia", message: "Olá, eu sou a Lia." },
  ]);
  const [firstTime, setFirstTime] = useState(false);
  const [tokens, setTokens] = useState(2000);
  const [video, setVideo] = useState();

  const { recebe_empresas } = useCompany();
  const navigate = useNavigate();

  //Função que permite os tokens ficarem visiveis
  const handleVisibleToken = () => {
    setIsVisible(isVisible ? false : true);
  };

  //Faz com que o container da pagina de login passe para o lado quando o usuario é cadastrado
  const toogleSlideContainer = () => {
    containerClass === ""
      ? setContainerClass("right-panel-active")
      : setContainerClass("");
  };

  const header = {
    "Content-Type": "application/json",
  };

  const [sessions, setSessions] = useState(() => {
    const accessToken = Cookies.get("social:tk");
    const user = Cookies.get("social:us");

    if (accessToken) {
      return { accessToken, user: user === "true" ? true : false };
    }

    return {};
  });

  //em caso de erro 429(too many requests) é ativado esta função para bloquear o usuario
  const requestLimiterTimer = () => {
    const timeoutId = setTimeout(() => {
      setDisabled(false);
    }, 30000);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  //função de login (acessar a conta)
  const login = useCallback(async (data) => {
    setIsLoading(true);
    await api
      .post("/login", data, { headers: header })
      .then((res) => {
        const { token, profile, user } = res.data;

        const hash = generateHash(profile.toString());
        if (profile) {
          Cookies.set("social:isA", false, { expires: 30 / 60 });
        }
        sessionStorage.setItem("@social:try", 2);
        Cookies.set("social:tk", token, { expires: 30 / 60 });
        Cookies.set("social:us", user, { expires: 30 / 60 });
        Cookies.set("social:ha", hash, { expires: 30 / 60 });
        setVerify(hash);
        setSessions({ accessToken: token });
      })
      .then(() => {
        navigate("/dashboard");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 429) {
          toast.error(
            "Limite de tentativas atingido, aguarde alguns minutos e tente novamente."
          );
          setDisabled(true);
          requestLimiterTimer();
        } else {
          toast.error(err.response.data.message);
        }
      });
  }, []);

  //função de logoff (sair da conta)
  const signOut = useCallback(async () => {
    const token = Cookies.get("social:tk");
    await api
      .post(
        `/logout`,
        { user: "logout" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((_) => {
        setTimeout(() => {
          sessionStorage.clear();
          localStorage.clear();
          Cookies.remove("social:tk");
          Cookies.remove("social:us");
          Cookies.remove("social:ha");
          Cookies.remove("social:isA");
          setTwoFactor(true);
          navigate("/");
          setIsLoading(false);
          setSessions({});
        }, 1000);
      })
      .catch((err) => {
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove("social:tk");
        Cookies.remove("social:us");
        Cookies.remove("social:ha");
        Cookies.remove("social:isA");
        setTwoFactor(true);
        navigate("/");
        setIsLoading(false);
        setSessions({});
      });
  }, []);

  //função de registro de nova conta
  const signUp = useCallback(async (data) => {
    setEmail(data.email);

    setIsLoading(true);
    await api
      .post("/register", data, { headers: header })
      .then((res) => {
        toast.success("Cadastrado com sucesso.");
        setOpen(true);
        toogleSlideContainer();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 429) {
          setDisabled(true);
          requestLimiterTimer();
          return toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        }
        if (err.response.status === 408) {
          return toast.error(err.response.data.message);
        }
        toast.error(err.response.data.message);
      });
  }, []);

  //cadastro de perfil(dados complementares) do usuario
  const cadastraPerfilUsuario = useCallback(async (data) => {
    setIsLoading(true);

    const token = Cookies.get("social:tk");
    await api
      .post("/cadastra_perfil_usuario", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const hash = generateHash("true");
        localStorage.setItem("@social:hash", hash);
        setIsLoading(false);
        toast.success(res.data.message);
        Cookies.set("social:ha", hash, { expires: 30 / 60 });
        setVerify(hash);
        setFirstTime(true);
        
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 429) {
          setDisabled(true);
          requestLimiterTimer();
          toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        } else if (err.response.status === 401) {
          signOut();
        }
        setIsLoading(false);
        toast.error(err.response.data.message);
        if (err.response.status === 401) {
          signOut();
        }
      });
  }, []);

  //faz a validação da conta do usuario após clicar no link recebido por e-mail
  const enviaConfimacaoEmail = useCallback(async (data) => {
    setIsLoading(true);

    await api
      .post(`/valida_email`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        setPrimeiroEnvio(false);

        toast.success(res.data.message);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
      });
  }, []);

  const [isTalking, setIsTalking] = useState(false);
  // DEVE SAIR DESSE SCRIPT DEVE SAIR DESSE SCRIPT DEVE SAIR DESSE SCRIPT DEVE SAIR DESSE SCRIPT v
  const liveChat = useCallback(async (data) => {
    setIsLoading(true);
    await api
      .post("/livechat", data)
      .then((res) => {
        setVideo(res.data);
        setIsTalking(true);
        setIsLoading(false);
        const lia = {
          name: "Lia",
          message: "Olá, eu sou a Lia.",
        };
        setMessage((prevMessage) => [...prevMessage, lia]);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 429) {
          setDisabled(true);
          setIsLoading(false);
          requestLimiterTimer();
          toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        }
        toast.error(err.response.data.message);
        if (err.response.status === 401) {
          signOut();
        }
      });
  }, []);
  // DEVE SAIR DESSE SCRIPT DEVE SAIR DESSE SCRIPT DEVE SAIR DESSE SCRIPT DEVE SAIR DESSE SCRIPT ^

  //Bloqueia a conta do usuario
  const BlockEmail = useCallback(async (data) => {
    setIsLoading(true);
    await api
      .post(`/block`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        if (err.response.status === 429) {
          toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
          setDisabled(true);
        }
        toast.error(err.response.data.message);
      });
  }, []);

  //solicita a troca de senha do usuario
  const recuperar_senha = useCallback(async (data) => {
    setIsLoading(true);
    await api
      .post(`/recuperar_senha`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 429) {
          toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        }
        toast.error(err.response.data.message);
      });
  }, []);

  //Redefine a senha do usuario
  const redefinir_senha = useCallback(async (data) => {
    setIsLoading(true);
    const { email_token } = data;
    await api
      .post(`/redefinir_senha`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${email_token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        toast.error(err.response.data.message);
        if (err.response.status === 429) {
          toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        }
      });
  }, []);

  //faz a validação de 2 fatores de login e registro de perfil
  const sendmfa = useCallback(async (data) => {
    setIsLoading(true);
    const token = Cookies.get("social:tk");
    setWhatsNumber(data.whatsapp);

    await api
      .post(`/sendmfa`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEnableTimer(true);
        setIsLoading(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status === 429) {
          return toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        }
        setEnableTimer(false);

        toast.error(err.response.data.message);
      });
  }, []);

  //valida se o numero de whatsapp ja foi registrado
  const valida_whatsapp = useCallback(async (data) => {
    setIsLoading(true);
    const token = Cookies.get("social:tk");

    await api
      .post(`/mfa_valida`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          code: data.code,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTwoFactor(false);
        Cookies.set("social:isA", true, { expires: 30 / 60 });
        sessionStorage.removeItem("@social:try");
        recebe_empresas();
        envia_profile();
        toast.success(res.data.message);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }

        const tentativas = sessionStorage.getItem("@social:try");
        if (err.response.status === 429) {
          toast.error(
            "Limite de tentaivas atingido, aguarde alguns minutos e tente novamente."
          );
        }
        if (err.response.status === 401 && tentativas > 0) {
          sessionStorage.setItem("@social:try", tentativas - 1);
          toast.error(
            `Código invalido, você possui mais ${tentativas} tentativas.`
          );
        } else {
          signOut();
          toast.error(err.response.data.message);
        }
        setIsLoading(false);
      });
  }, []);

  //Cadastra o perfil do usuario
  const envia_profile = useCallback(async () => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    await api
      .get("/envia_profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // toast.success(res.data.message);
        setUserProfile(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  //envia e-mail para o suporte do social lia
  const support = useCallback(async (data) => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    setDisabled(true);
    await api
      .post("/suporte", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success(
          "Mensagem enviada com sucesso, aguarde o retorno do suporte!"
        );
        navigate("/dashboard");
        setDisabled(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  //pega o historico de itens gerados pelo usuario
  const getHistory = useCallback(async (filter, page) => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    console.log(filter, page);
    await api
      .get(
        `/historico?page=${page ? page : 1}&filtro=${filter ? filter : "all"}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const historic = res.data.historic;
        const sortedHistoric = historic.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setHistoryCards(sortedHistoric);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 401) {
          signOut();
        }
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  //Atualiza os itens do historico (favoritar itens)
  const updateHistory = useCallback(async (data) => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    await api
      .put(`/historico`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        getHistory();
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 401) {
          signOut();
        }
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  //Deleta items do historico
  const deleteHistory = useCallback(async (data) => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);

    await api
      .delete(`/historico?id=${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        getHistory();
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
          return;
        }
        if (err.response.status === 401) {
          signOut();
        }
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  //Registra clicks do usuario
  const registerClicks = useCallback(async (data) => {
    const token = Cookies.get("social:tk");
    console.log(data);
    // await api
    //   .post(
    //     "/click",
    //     { data },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));
  });
  return (
    <UserContext.Provider
      value={{
        accessToken: sessions.accessToken,
        user: sessions.user,
        containerClass,
        primeiroEnvio,
        authenticated, 
        historyCards,
        selectedCard,
        whatsNumber,
        enableTimer,
        userProfile,
        data: data,
        isVisible,
        isLoading,
        firstTime,
        isTalking,
        twoFactor,
        disabled,
        message,
        verify,
        tokens,
        email,
        video,
        nomes,
        open,
        login,
        signUp,
        sendmfa,
        signOut,
        setOpen,
        setData,
        support,
        setNomes,
        liveChat,
        setTokens,
        BlockEmail,
        getHistory,
        setMessage,
        setDisabled,
        setFirstTime,
        setIsTalking,
        setIsVisible,
        setIsLoading,
        updateHistory,
        deleteHistory,
        envia_profile,
        setEnableTimer,
        recuperar_senha,
        setHistoryCards,
        redefinir_senha,
        valida_whatsapp,
        setSelectedCard,
        setAuthenticated,
        handleVisibleToken,
        enviaConfimacaoEmail,
        toogleSlideContainer,
        cadastraPerfilUsuario,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
