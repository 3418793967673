import { Box, Container } from "@mui/material";
import TransitionWrapper from "../../Components/screenTransition";
import { HeaderComponent } from "../../Components/header";
import { CircularIndeterminate } from "../../Components/loadingComponents/loading";
import { useUser } from "../../Contexts/UserProvider";
import { Historico } from "../../Components/geraTextoComponents/history";
import { TopContent } from "../../Components/topContent";
import { Description } from "../../Components/geraTextoComponents/description";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useGenText } from "../../Contexts/iaRequests";

//Pagina do historico de prompts criados
export const History = () => {
  const { isLoading } = useUser();
  const { themes } = useThemeChanger();

  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentRoute } = useGenText();
   useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);

  const [isAnimating, setIsAnimating] = useState(false);
  const startAnimation = () => {
    setIsAnimating(true);
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate("/dashboard")}
    >
      <Container
      className="promptHistoryCotainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          minWidth: "100vw",
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          overflowX: "hidden",
          background: themes.background,

        }}
      >
        <HeaderComponent startAnimation={startAnimation}/>
        {isLoading && <CircularIndeterminate />}
        <Box
          className="promptHistoryCotainer--content"
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            height: "100%",
            width: "90vw",
            color: themes.liaMainColor,
          }}
        >
          <Box
            className="promptHistoryCotainer--content--intro"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: [0, 0, 4, 5],
              mt: ["1rem", "1rem", "2rem", "2rem"],
              width: ["100%", "100%", "60%", "50%"],
            }}
          >
            <TopContent
              title={`Histórico de prompts`}
              onClick={startAnimation}
            />
            <Description text="Para facilitar suas buscas, selecione o tópico e a categoria desejada para gerar um filtro." />
          </Box>
          <Box
            className="promptHistoryCotainer--content--cards"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: ["start", "start", "center", "center"],
              alignItems: "center",
              height: "100%",
              width: ["100%", "100%", "80%", "80%"],
              gap: [1, 1, 2, 2],
              color: themes.liaMainColor,
            }}
          >
            <Historico />
          </Box>
        </Box>
      </Container>
    </TransitionWrapper>
  );
};
