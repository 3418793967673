import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { TransactionCode } from "../popup";
import { useAsass } from "../../Contexts/asassProvider";
import TransitionWrapper from "../screenTransition";

//PRECISA AJUSTAR O setCardTag(paymentData.tag)

//Componente de passos utilizado na compra de tokens, passo 1 (confirmação de escolha), passo 2(pagamento) - https://mui.com/material-ui/react-stepper/
export const TokenConfirmStepper = ({ paymentData, setOpen }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [cardTag, setCardTag] = useState("");
  const { themes } = useThemeChanger();
  const { comprar_tokens } = useAsass();
  const completed = {};

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleVerifyCard = (tag) => {
    console.log(cardTag);
    if (cardTag) {
      if (cardTag !== tag) {
        comprar_tokens(tag);
      }
    } else {
      comprar_tokens(tag);
    }
  };
  const steps = [
    {
      label: "Confirmar compra",
      description: (
        <Box>
          <Typography sx={{ color: themes.liaMainColor, fontWeight: 800 }}>
            Dados do comprador
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              color: themes.liaMainColor,
              height: "100%",
              mt: 5,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>Nome:</Typography>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>CPF:</Typography>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>
                Quantidade de Tokens:
              </Typography>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>
                Preço:
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "end",
                color: themes.highlight,
                gap: 5,
              }}
            >
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>
                {paymentData.user}
              </Typography>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>
                {paymentData.cpf}
              </Typography>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>
                {paymentData.tokens}
              </Typography>
              <Typography sx={{ fontSize: [14, 14, 20, 20] }}>
                R${paymentData.price}
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      label: "Pagamento",
      description: <TransactionCode value={paymentData.price} />,
    },
  ];
  return (
    <TransitionWrapper>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          background: "rgba(0,0,0,0.5)",
          zIndex: 1000,
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: ["90%", "90%", "50%", "50%"],
            background: themes.background,

            padding: 3,
            position: "relative",
          }}
        >
          <Alert
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            sx={{
              "& .MuiAlert-icon": { fontSize: 32 },
            }}
            severity="warning"
          >
            <AlertTitle> ATENÇÃO</AlertTitle>
            Por segurança, antes de confirmar o seu pagamento, verifique se os
            dados estão corretos. Verifique no seu app do banco os dados do
            destinatarios.
          </Alert>
          <Stepper
            sx={{
              padding: ["0", "0", "1rem 2rem", "3rem 5rem 1rem 5rem"],
              overflowY: "scroll",
              "::-webkit-scrollbar ": {
                width: "5px",
              },

              "::-webkit-scrollbar-track": {
                background: themes.background,
              },

              "::-webkit-scrollbar-thumb": {
                backgroundColor: themes.highlight,
                borderRadius: "20px",
              },
            }}
            nonLinear
            activeStep={activeStep}
            orientation="vertical"
          >
            {steps.map((item, index) => (
              <Step key={item.label} completed={completed[index]}>
                <StepLabel
                  sx={{
                    "& span": {
                      fontWeight: 800,
                      background: `-webkit-linear-gradient(4deg, ${themes.liaMainColor} 0%, ${themes.liaMainColor} 5%, ${themes.highlight} 30%, ${themes.highlight} 80%)`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    },
                    "& .MuiStepIcon-root.Mui-active, .MuiStepLabel-active, .MuiStepLabel-label.Mui-active, .MuiStepIcon-root.Mui-completed ":
                      {
                        color: themes.highlight,
                      },
                  }}
                >
                  {item.label}
                </StepLabel>
                <StepContent>{item.description}</StepContent>
              </Step>
            ))}
          </Stepper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifySelf: "end",
              padding: ["0", "0", "1rem 2rem", "0rem 5rem 0rem 5rem"],
            }}
          >
            {activeStep === 1 ? (
              <Button
                onClick={() => setOpen(false)}
                sx={{ mr: 1, color: themes.liaMainColor }}
              >
                Fechar
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => setOpen(false)}
                  sx={{ mr: 1, color: themes.liaMainColor }}
                >
                  cancelar
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={() => {
                    handleVerifyCard(paymentData.tag);
                    handleNext();
                  }}
                  sx={{ mr: 1, color: themes.liaMainColor }}
                >
                  Ir para pagamento
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </TransitionWrapper>
  );
};
