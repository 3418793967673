import * as React from "react";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useUser } from "../../Contexts/UserProvider";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useAsass } from "../../Contexts/asassProvider";
import { toast } from "react-toastify";
import { LoadingDot } from "../loadingComponents/loagindDot";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//Componente de alerta MUI - https://mui.com/material-ui/react-dialog/
export const AlertDialogSlide = () => {
  const { open, setOpen, email } = useUser();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Verifique seu e-mail"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Para prosseguir, clique no link de ativação que acabamos de enviar
            para seu e-mail: <b>{email}</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

//Componente que carrega o QR code para o pagamento de tokens
export const TransactionCode = ({ value }) => {
  const { themes } = useThemeChanger();
  const { payload, loading } = useAsass();

  //compara o qrCode
  // const blobUrl = payload.qrCode;
  // const qrHTML =
  //   document.getElementsByClassName("qrCodeBoxContainer")[0]?.attributes[
  //     "aria-details"
  //   ].nodeValue;

  // if (blobUrl === qrHTML) {
  //   console.log("são iguais");
  // }

  return (
    <Box className="paymentContainer" sx={{ position: "relative" }}>
      {loading && (
        <>
          <Box
            className="paymentContainer--loadingBox"
            sx={{
              transition: "1s",
              position: "absolute",
              background: "rgba( 255, 255, 255, 0.35 )",
              backdropFilter: " blur( 13.5px )",
              webkitBackdropFilter: " blur( 13.5px )",
              borderRadius: " 10px",
              border: " 1px solid rgba( 255, 255, 255, 0.18 )",
              zIndex: 500,
              width: "100%",
              height: "100%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              zIndex: 600,
              width: "100%",
              height: "100%",
            }}
          >
            <LoadingDot />
          </Box>
        </>
      )}
      <Box
        className="paymentContainer--info"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              p: 0,
              margin: 0,
              gap: 2,
            }}
          >
            <Box
              className="paymentContainer--info--value"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: [14, 14, 20, 20],
                  alignSelf: "start",

                  color: themes.liaMainColor,
                }}
              >
                Pagando{" "}
                <span style={{ color: themes.highlight, fontWeight: 800 }}>
                  R${value}
                </span>
              </Typography>
              <Box
                className="qrCodeBoxContainer"
                aria-details={payload.qrCode}
                sx={{
                  width: ["12rem", "15rem", "15rem", "15rem"],
                  height: ["12rem", "15rem", "15rem", "15rem"],
                  backgroundImage: `url(${payload.qrCode && payload.qrCode})`,
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  opacity: 0.6,
                }}
              />
              <Typography
                className="paymentContainer--info--receiver"
                sx={{
                  fontSize: [14, 14, 20, 20],

                  alignSelf: "start",
                  color: themes.liaMainColor,
                }}
              >
                para{" "}
                <span style={{ color: themes.highlight, fontWeight: 800 }}>
                  WS7 comunicação
                </span>
              </Typography>
            </Box>
            <Button
              className="paymentContainer--info--copyPixButton"
              onClick={() => {
                toast.success("Copiado");
                navigator.clipboard.writeText(
                  payload.pixCode && payload.pixCode
                );
              }}
              sx={{
                background: "rgba(5,92,154,0.08)",
                padding: "0.5rem 3rem",
                border: `1px solid ${themes.liaMainColor}`,
                fontSize: [12, 12, 20, 20],
                color: themes.liaMainColor,
              }}
            >
              Copia e cola
            </Button>
          </DialogContent>
          <DialogActions sx={{ pb: 3 }}></DialogActions>
        </>
      </Box>
    </Box>
  );
};
