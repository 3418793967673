import { HeaderComponent } from "../../Components/header";
import { Box, Container } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { Description } from "../../Components/geraTextoComponents/description";
import { TextGenerate } from "../../Components/geraTextoComponents/textGenerate";
import { useState } from "react";
import { useEffect } from "react";
import { TopContent } from "../../Components/topContent";
import TransitionWrapper from "../../Components/screenTransition";
import { useUser } from "../../Contexts/UserProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertComponent } from "../../Components/alert";
import { useGenText } from "../../Contexts/iaRequests";
import { useBounty } from "../../Contexts/bountyProvider";
import { toast } from "react-toastify";

//Pagina de geração de texto
export const GeraTexto = () => {
  const { themes } = useThemeChanger();
  const [isHistory, setIsHistory] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { selectedCard } = useUser();
  const { setPrompt, lazy, setIsLoading, setCurrentRoute } = useGenText();
  const { balance } = useBounty()

  const navigate = useNavigate();

  useEffect(() => {
    if (balance <= 0) {
      navigate("/dashboard");
    }
  }, [balance])
  const location = useLocation();
  useEffect(() => {
    setCurrentRoute(location.pathname);
    // const socket = io.connect("https://app.falecomlia.com.br");
    // setSocket(socket);
  }, []);

  // const [message, setMessage] = useState("");
  // const socket = io("http://localhost:3000"); // Substitua pela URL do seu servidor WebSocket

  // useEffect(() => {
  //   // Quando uma mensagem é recebida do servidor
  //   socket.on("message", (data) => {
  //     setMessage(data);
  //   });

  //   return () => {
  //     socket.disconnect(); // Desconecta o WebSocket quando o componente é desmontado
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log(message);
  // }, [message]);

  useEffect(() => {
    setPrompt(``);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 900px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => setIsMobile(event.matches);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  const [isAnimating, setIsAnimating] = useState(false);

  const startAnimation = () => {
    setIsAnimating(true);
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate("/dashboard")}
    >
      <Container
        className="textGenerateContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          margin: [0, 0, 0, 0],
          background: themes.background,

          padding: [0, 0, 0, 0],
        }}
      >
        <HeaderComponent startAnimation={startAnimation} />
        {lazy && <AlertComponent />}
        <Box
          className="textGenerateContainer--content"
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            height: "100%",
            width: "90vw",
            color: themes.liaMainColor,
          }}
        >
          <Box
            className="textGenerateContainer--content--pageInfo"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: [0, 0, 4, 5],
              mt: ["1rem", "1rem", "2rem", "2rem"],
              width: ["100%", "100%", "60%", "50%"],
            }}
          >
            <TopContent
              isMobile={isMobile}
              isHistory={isHistory}
              title={selectedCard.title}
              setIsHistory={setIsHistory}
              onClick={startAnimation}
            />

            <Description text={selectedCard.text} />
          </Box>
          <Box
            className="textGenerateContainer--content--text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: ["start", "start", "center", "center"],
              alignItems: "center",
              height: "100%",
              width: ["100%", "100%", "80%", "80%"],
              gap: [1, 1, 2, 2],
              color: themes.liaMainColor,
            }}
          >
            <TextGenerate setIsHistory={setIsHistory} />
          </Box>
        </Box>
      </Container>
    </TransitionWrapper>
  );
};
