import axios from "axios";

//API socialLia
export const api = axios.create({
  baseURL: "https://app.falecomlia.com.br",
});

//Api Via CEP (API dos correios) - https://viacep.com.br/
export const viaCEP = axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

export const socketAPI = axios.create({
  baseURL: "https://front-social-lia.vercel.app",
});
