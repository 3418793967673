import { createContext, useCallback, useContext, useState } from "react";
import { api } from "../Services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./UserProvider";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useBounty } from "./bountyProvider";
import {  useNavigate } from "react-router-dom";

const GenTextContext = createContext({});

export const useGenText = () => {
  const context = useContext(GenTextContext);
  if (!context) {
    throw new Error("useGenText must be used within an ProjectProvider");
  }
  return context;
};

//Context responsavel pelos GenTexts realizados(paginas a parte do projeto principal)
export const GenTextProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [nomes, setNomes] = useState([]);

  const [type, setType] = useState("");
  const [prompt, setPrompt] = useState("");
  const [display, setDisplay] = useState("flex");
  const [displayText, setDisplayText] = useState("");

  const [spentTokens, setSpentTokens] = useState(0);

  const [lazy, setLazy] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generating, setGenerating] = useState(false);

  const [initialRoute, setInitialRoute] = useState("");
  const [currentRoute, setCurrentRoute] = useState("");

  const { signOut, setTokens } = useUser();
  const { balance, getBalance } = useBounty();

  const navigate = useNavigate();
  //Converte JSON para Base64
  const objectToJsonBase64 = (obj) => {
    try {
      const jsonString = JSON.stringify(obj);
      const encoder = new TextEncoder();
      const data = encoder.encode(jsonString);
      const base64String = btoa(String.fromCharCode(...data));
      return base64String;
    } catch (error) {
      console.error("Erro ao converter objeto JSON em Base64:", error);
      return null;
    }
  };

  const [disabled, setDisabled] = useState(false);
  const [delayMessage, setDelayMessage] = useState("Gerando texto");

  useEffect(() => {
    if (balance > 0) {
      if (currentRoute.includes("texto") === false && generating === true) {
        toast.info(
          `Logo seu texto estará pronto,aguarde e consulte o historico.`
        );
        setRequesting(false);
        setIsLoading(false);
        setGenerating(false);
        setDisabled(false);
      }
    }
  }, [currentRoute, generating]);

  const genCheck = useCallback(
    async (data) => {
      const token = Cookies.get("social:tk");
      if (!balance) {
        getBalance();
      }
      if (balance <= 0) {
        toast.error("Saldo insuficiente, verifique seu saldo.");
        setIsLoading(false);
        setDisabled(false);
        setGenerating(false);
        setRequesting(false);
      }
      await api
        .get(`/genchek`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const resposta = res.data;
          const prompt = data.prompt?.tema ? data.prompt?.tema : data.prompt;
          if (prompt === resposta.last_historic.prompt) {
            setPrompt(resposta.last_historic.prompt_response);
            setTokens((prev) => prev - resposta.last_historic.used_token);
            setSpentTokens(resposta.last_historic.used_token);
            setIsLoading(false);
            setDisabled(false);
            setGenerating(false);
            setRequesting(false);
          }
        })
        .catch((err) => {
          toast.info("Aguarde, estamos gerando.");
          const status = err.request.status;
          console.log(err);
          if (status === 500) {
            toast.error(
              "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
            );
            setRequesting(false);
            setIsLoading(false);
            setGenerating(false);
            setDisabled(false);
            return;
          }
          if (status === 429) {
            toast.error(
              "Aguarde alguns instantes antes de realizar sua pergunta."
            );
            setRequesting(false);
            setIsLoading(false);
            setGenerating(false);
            setDisabled(false);
            setPrompt(
              "Aguarde alguns instantes antes de realizar sua pergunta."
            );

            return;
          }
          if (status === 401) {
            setRequesting(false);
            setIsLoading(false);
            setGenerating(false);
            setDisabled(false);
            signOut();
          }
          toast.error(err.response.data.message);
          setGenerating(false);

          setPrompt("Parece que algo deu errado, tente novamente");
        });
    },
    [setTokens, signOut]
  );
  //envia o prompt digitado pelo usuario para a criação de textos personalizados
  const [requesting, setRequesting] = useState(false);
  const [dados, setDados] = useState({});

  const genlia = useCallback(
    async (data) => {
      if (!balance) {
        getBalance();
      }
      if (balance <= 0) {
        toast.error("Saldo insuficiente, verifique seu saldo.");
        setRequesting(false);
        setIsLoading(false);
        setGenerating(false);
        setDisabled(false);
        navigate("/dashboard");
      } else {
        setIsLoading(true);
        setGenerating(true);
        setDisabled(true);
        setPrompt(" ");
        setSpentTokens("");
        const company = sessionStorage.getItem("@social:company");
        const token = Cookies.get("social:tk");
        const newData = { tag: company, type: data.type };
        newData["prompt"] = objectToJsonBase64(data.prompt);
        setDados(data);
        await api
          .post(`/genlia`, newData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setRequesting(true);
          })
          .catch((err) => {
            const status = err.request.status;
            setDisplay("flex");
            console.log(err);
            if (status === 500) {
              const text =
                "Ops! Estamos temporariamente indisponível, tente novamente mais tarde...";
              toast.error(text);
              setPrompt(text);
              setRequesting(false);
              setIsLoading(false);
              setGenerating(false);
              setDisabled(false);
              return;
            }
            if (status === 429) {
              setRequesting(false);
              setIsLoading(false);
              setGenerating(false);
              setDisabled(false);
              const text =
                "Aguarde alguns instantes antes de realizar sua pergunta.";
              toast.error(text);
              setPrompt(text);
              return;
            }
            if (status === 401) {
              setRequesting(false);
              setIsLoading(false);
              setGenerating(false);
              setDisabled(false);
              signOut();
            }
            toast.error(err.response.data.message);
            // setPrompt("Parece que algo deu errado, tente novamente");
          });
      }
    },
    [signOut]
  );

  useEffect(() => {
    let intervalo;

    if (requesting) {
      let contador = 0;
      setDelayMessage("Gerando texto. Aguarde...");
      const texts = [
        "Gerando texto. Aguarde...",
        "Está demorando um pouco, mas estamos trabalhando.",
        "Está demorando um pouco, mas estamos trabalhando.",
        "Gerando seu texto. Por favor aguarde...",
        "Gerando seu texto. Por favor aguarde...",
        "Verifique o seu histórico ou aguarde mais um pouco.",
      ];

      intervalo = setInterval(() => {
        if (contador < 6) {
          setDelayMessage(texts[contador]);
          contador++;
        }
        genCheck(dados);
      }, 50000);
    }

    return () => {
      clearInterval(intervalo); // Limpa o intervalo ao desmontar o componente
    };
  }, [requesting, dados, genCheck]);

  return (
    <GenTextContext.Provider
      value={{
        initialRoute,
        currentRoute,
        delayMessage,
        spentTokens,
        displayText,
        data: data,
        generating,
        isLoading,
        disabled,
        success,
        display,
        prompt,
        nomes,
        type,
        lazy,
        genlia,
        setData,
        setLazy,
        setType,
        setNomes,
        setPrompt,
        setSuccess,
        setDisplay,
        setIsLoading,
        setGenerating,
        setSpentTokens,
        setDisplayText,
        setInitialRoute,
        setCurrentRoute,
        setDelayMessage,
      }}>
      {children}
    </GenTextContext.Provider>
  );
};
