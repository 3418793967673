import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { TokensDoubt } from "./tokens";
import { ProfileEdit } from "./profileEdit";
import { SocialLiaDoubt } from "./socialLia";

//Opções disponiveis para o usuario
const options = [
  { type: "O que é Social Lia", card: <SocialLiaDoubt /> },
  { type: "Tokens", card: <TokensDoubt /> },
  { type: "Edição de cadastro", card: <ProfileEdit /> },
];

//Componente da pagina de duvidas ao usuario
export const HelpCenterComponent = () => {
  const { themes } = useThemeChanger();

  const [value, setValue] = useState(0);
  const [filteredItems, setFilteredItems] = useState(options[0]);

  const handleChange = (event, newValue) => {
    const toFilteroptions = options;
    setValue(newValue);
    setFilteredItems(toFilteroptions.find((el, i) => i === newValue));
  };

  return (
    <Box
      className="helpCenterComponent"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        width: `100vw`,
      }}
    >
      <Tabs
        className="helpCenterComponent--options"
        value={value}
        onChange={handleChange}
        centered
        textColor={themes.highlight}
        indicatorColor={themes.highlight}
        sx={{
          display: `flex`,
          "& button": {
            padding: [0, 1, 2, 2],
            color: themes.liaMainColor,
            fontSize: [10, 12, 14, 16],
          },
          "& .Mui-selected": {
            color: themes.highlight,
          },
          "& .MuiTabs-indicator": { background: themes.highlight },
        }}
      >
        <Tab label="Social Lia" />
        <Tab label="Tokens" />
        <Tab label="Edição de cadastro" />
      </Tabs>
      <Box
        className="helpCenterComponent--infoCards"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {filteredItems && filteredItems.card}
      </Box>
    </Box>
  );
};
