import { Box, Button, Typography } from "@mui/material";
import { BuyTokenCard } from "../../Components/BuyToken/buyTokenCard";
import { useState } from "react";
import { Description } from "../../Components/geraTextoComponents/description";
import { TokenConfirmStepper } from "../../Components/BuyToken/stepperToken";
import { useUser } from "../../Contexts/UserProvider";
import { useBounty } from "../../Contexts/bountyProvider";
import { LoadingDot } from "../../Components/loadingComponents/loagindDot";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { FaGift } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

//Pagina de compra de tokens (swipper)
export const CompraToken = () => {
  const [open, setOpen] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const { userProfile, isLoading } = useUser();
  const { tokensPackage } = useBounty();
  const { themes } = useThemeChanger();
  const navigate = useNavigate();
  return (
    <>
      {open && (
        <TokenConfirmStepper
          paymentData={paymentData}
          setOpen={() => setOpen()}
        />
      )}
      <Box
        className="buyTokenBox"
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row", "row"],
          height: "100%",
          width: "100%",
          mt: [0, 0, 5, 5],
        }}
      >
        <Box
          className="buyTokenBox--description"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            mt: ["1rem", "1rem", 0, 0],
            gap: [0, 0, 4, 5],
            mb: ["2rem"],
            width: ["100%", "100%", "70%", "40%"],
          }}
        >
          <Description text="Adquira tokens para usar em nossa plataforma. Clique no card de quantidade de tokens desejada para comprar. Pagamentos apenas com PIX." />
        </Box>
        <Box
          className="buyTokenBox--loading"
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: ["100vw", "100vw", "60vw", "55vw"],
            height: "80vh",
            flexWrap: "wrap",
            overflowY: ["scroll"],
            gap: [3, 2, 4, 3],
            padding: ["1rem", "2rem", "10px", "10px"],
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
              }}
            >
              <LoadingDot />
            </Box>
          )}
          <Box
            className="tokenCardBox"
            sx={{
              boxShadow: `0 0 9px ${themes.textGray}`,
              color: themes.highlight,
              borderRadius: 5,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: [130, 200, 250, 250],
              height: [200, 300, 350, 350],
            }}
          >
            <FaGift
              className="tokenCardBox--coinIcon"
              style={{ width: "50%", height: "50%" }}
            />
            <Box
              className="tokenCardBox--descriptionBox"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: [16, 20, 24, 28],
                  textAlign: "center",
                  color:themes.liaMainColor,
                  pb:2
                }}
              >
                Resgatar cupom!
              </Typography>
              <Button
                className="tokenCardBox--descriptionBox--button"
                onClick={() => {
                  navigate("/bonus");
                }}
                sx={{
                  background: themes.highlight,
                  color: "white",
                  borderRadius: 2,
                  width: "90%",
                  ":hover": {
                    background: themes.hoverButton,
                  },
                  fontSize: [10, 10, 14, 14],
                }}
              >
                Resgatar
              </Button>
            </Box>
          </Box>
          {tokensPackage
            .sort((a, b) => Number(a.price) - Number(b.price))
            .map((el, i) => (
              <BuyTokenCard
                key={i}
                amount={Number(el.amount)}
                bonus={Number(el.bonus)}
                price={Number(el.price)}
                onClick={() => {
                  setOpen(true);
                  setPaymentData({
                    user: userProfile.user,
                    cpf: userProfile.profile[0].cpf,
                    tag: el.package,
                    tokens: Number(el.amount) + Number(el.bonus),
                    price: Number(el.price),
                  });
                }}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};
