import { api } from "../Services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserProvider";
import Cookies from "js-cookie";

const CompanyContext = createContext({});

export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error("useCompany must be used within an CompanyProvider");
  }
  return context;
};

//Context responsavel por gerenciamento de empresas
export const CompanyProvider = ({ children }) => {
  const [empresas, setEmpresas] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const { signOut } = useUser();
  const navigate = useNavigate();

  //Cadastra empresas
  const cadastra_perfil_empresa = useCallback(async (data) => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    await api
      .post("/cadastra_perfil_empresa", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setIsLoading(false);
        setIsFormComplete(true);
        recebe_empresas();
        setOpen(true);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          console.log('uepa')
          sessionStorage.clear();
          localStorage.clear();
          Cookies.remove("social:tk");
          Cookies.remove("social:us");
          Cookies.remove("social:ha");
          Cookies.remove("social:isA");
          navigate("/");
          setIsLoading(false);
        }
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        setIsFormComplete(false);
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  // Recebe empresas cadastradas para visualização na tela de perfil
  const recebe_empresas = useCallback(async () => {
    const token = Cookies.get("social:tk");
    setIsLoading(true);
    await api
      .get("/envia_empresas", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data?.lista_empresas) {
          sessionStorage.setItem(
            "@social:company",
            res.data.lista_empresas[0].company_tag
          );
          setEmpresas(res.data.lista_empresas);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status >= 500) {
          toast.error(
            "Ops! Estamos temporariamente indisponível, tente novamente mais tarde..."
          );
        }
        setIsLoading(false);
      });
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        isFormComplete,
        isLoading,
        empresas,
        open,
        setOpen,
        setEmpresas,
        setIsLoading,
        recebe_empresas,
        setIsFormComplete,
        cadastra_perfil_empresa,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
