import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { AccordionComponent } from "../accordion";

const options = [
  {
    title: `Consumo de Tokens`,
    text: ` Possuímos vários modelos, cada um com diferentes capacidades e preços. Os preços podem variar com a quantidade de palavras geradas; portanto, quanto maior for o texto, maior será o consumo de tokens. Por exemplo, este parágrafo contém 35 tokens.`,
  },
  {
    title: `O que são tokens?`,
    text: `Você pode pensar em tokens como pedaços de palavras, onde 1.000 tokens correspondem a cerca de 750 palavras.`,
  },
  {
    title: `É possível comprar tokens usando o Pix?`,
    text: `É possível comprar tokens usando exclusivamente o Pix.`,
  },
  {
    title: `O que posso fazer com os tokens adquiridos no aplicativo?`,
    text: `Você pode utilizar os tokens na categoria desejada para criar conteúdos.`,
  },
  {
    title: `Como visualizo o extrato das minhas transações de tokens?`,
    text: `Para acessar o extrato, basta ir à tela principal. Ao lado das boas-vindas, você verá três botões: "Informações do Perfil", "Comprar Tokens" e, por fim, "Extrato". Ao clicar em "Extrato", você terá acesso às compras realizadas, com um limite máximo de 90 dias.`,
  },

  {
    title: `Posso usar os tokens para acessar serviços de diferentes categorias?`,
    text: `Sim, todas as categorias e ferramentas dentro do Social Lia funcionam com os mesmos tokens. A única diferença é a quantidade de tokens necessária para cada ferramenta.`,
  },

  {
    title: `É possível adquirir mais tokens além da primeira compra?`,
    text: `Sim, a qualquer momento você pode acessar a sua área inicial e clicar no botão "Comprar Tokens". Lá, você pode escolher e comprar a quantidade de tokens desejada. As compras são ilimitadas, mas pedimos que aguarde alguns minutos antes de realizar uma segunda compra no mesmo dia.`,
  },

  {
    title: `Existe algum programa de fidelidade ou benefícios para usuários frequentes?`,
    text: `Existem bônus adicionais na compra de pacotes de tokens com maior volume. Você pode visualizar os pacotes disponíveis no botão "Comprar Tokens".`,
  },
];

//componente de duvidas sobre tokens
export const TokensDoubt = () => {
  const { themes } = useThemeChanger();

  return (
    <Box
      className="tokenDoubtsContainer"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: `80vw`,
        overflowY: "scroll",
        "::-webkit-scrollbar ": {
          width: "5px",
        },

        "::-webkit-scrollbar-track": {
          background: themes.background,
          margin: "1rem 0",
        },

        "::-webkit-scrollbar-thumb": {
          backgroundColor: themes.highlight,
          borderRadius: "20px",
        },
      }}
    >
      <Typography
        className="tokenDoubtsContainer--title"
        sx={{
          textAlign: `center`,
          fontWeight: `bolder`,
          fontSize: 24,
          color: themes.highlight,
        }}
      >
        Tokens
      </Typography>
      <Box
        className="tokenDoubtsContainer--descriptions"
        sx={{
          height: `80vh`,
        }}
      >
        {options.map((el, i) => (
          <AccordionComponent
            className="tokenDoubtsContainer--descriptions--accordion"
            title={el.title}
            index={i}
          >
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                gap: 2,
                pb: 2,
                overflow: "hidden scroll",
                "::-webkit-scrollbar ": {
                  width: "5px",
                },

                "::-webkit-scrollbar-track": {
                  background: themes.background,
                  margin: "1rem 0",
                },

                "::-webkit-scrollbar-thumb": {
                  backgroundColor: themes.highlight,
                  borderRadius: "20px",
                },
              }}
            >
              <Typography>{el.text}</Typography>
            </Box>
          </AccordionComponent>
        ))}
      </Box>
    </Box>
  );
};
