import { useEffect } from "react";
import { Perfil } from "../Pages/Perfil";
import { GeraTexto } from "../Pages/geraTexto";
import { BlockUser } from "../Pages/BlockPage";
import { Dashboard } from "../Pages/Dashboard";
import { CompraToken } from "../Pages/Compra_token";
import { LiaResponde } from "../Pages/Festa_da_uva";
import { useUser } from "../Contexts/UserProvider";
import { ConfirmaEmail } from "../Pages/confirma_email";
import { CredentialsPage } from "../Pages/Login_register";
import { CadastraEmpresa } from "../Pages/Cadastra_empresa";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { TwoFactorAuth } from "../Components/2FAComponent";
import { RecuperarSenhaFormulario } from "../Components/credentialsComponents/recoverPassword/newPassword";
import { RecuperaSenhaEmail } from "../Components/credentialsComponents/recoverPassword/insertEmail";
import { SupportPage } from "../Pages/Support";
import { HelpCenter } from "../Pages/Support/helpCenter";
import { History } from "../Pages/historic/history";
import { Bonus } from "../Pages/Bonus";
import { GeraRedacao } from "../Pages/geraRedacao";
import Cookies from "js-cookie";

// const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       authenticated ? <Component {...props} /> : <Navigate to="/" />
//     }
//   />
// );

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
}
export const Router = () => {
  const { accessToken } = useUser();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Verifica se a tecla pressionada é a tecla de controle direita
      if (event.key === "Control" && event.location === 2) {
        // Substitua a função abaixo pela ação que você deseja executar
        navigate("/liveChat");
      }
    };

    // Adiciona o ouvinte de evento para detectar a tecla pressionada
    document.addEventListener("keydown", handleKeyDown);

    // Remove o ouvinte de evento ao desmontar o componente
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     // Verifique se o usuário realmente deseja sair ou recarregar a página
  //     const confirmationMessage =
  //       "Você tem certeza de que deseja sair desta página?";
  //     e.returnValue = confirmationMessage;
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    const path = location.pathname;
    const user = Cookies.get("social:us");
    if (user && path !== "/dashboard") {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const user = Cookies.get("social:us");
    if (user && path === "/") {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<CredentialsPage />} />
      <Route
        element={
          <PrivateRoute component={Dashboard} isAuthenticated={accessToken} />
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      <Route
        element={
          <PrivateRoute component={Perfil} isAuthenticated={accessToken} />
        }
      >
        <Route path="/perfil" element={<Perfil />} />
      </Route>
      <Route
        element={
          <PrivateRoute component={CompraToken} isAuthenticated={accessToken} />
        }
      >
        <Route path="/aquisicao_token" element={<CompraToken />} />
      </Route>
      <Route
        element={
          <PrivateRoute
            component={CadastraEmpresa}
            isAuthenticated={accessToken}
          />
        }
      >
        <Route path="/cadastra_empresa" element={<CadastraEmpresa />} />
      </Route>
      <Route
        element={
          <PrivateRoute component={GeraTexto} isAuthenticated={accessToken} />
        }
      >
        <Route path="/gera_texto/:tag" element={<GeraTexto />} />
      </Route>
      <Route
        element={
          <PrivateRoute component={GeraRedacao} isAuthenticated={accessToken} />
        }
      >
        <Route path="/textos/:tag" element={<GeraRedacao />} />
      </Route>
      <Route
        element={
          <PrivateRoute component={History} isAuthenticated={accessToken} />
        }
      >
        <Route path="/historico" element={<History />} />
      </Route>
      <Route
        element={
          <PrivateRoute component={Bonus} isAuthenticated={accessToken} />
        }
      >
        <Route path="/bonus" element={<Bonus />} />
      </Route>
      {/* <Route path="/lia_responde" element={<LiaResponde />} /> */}
      <Route path="/recuperar_senha" element={<RecuperaSenhaEmail />} />
      <Route
        path="/redefinir_senha/:emailToken?"
        element={<RecuperarSenhaFormulario />}
      />
      <Route path="/2fa" element={<TwoFactorAuth />} />
      <Route path="/valida_email/:userToken?" element={<ConfirmaEmail />} />
      <Route path="/block/:hash?" element={<BlockUser />} />
      <Route path="/suporte" element={<SupportPage />} />
      <Route path="/centro_ajuda" element={<HelpCenter />} />
    </Routes>
  );
};
