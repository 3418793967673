import { FaGift, FaTicketAlt } from "react-icons/fa";
import { Gifts } from "./style";
import { Box } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";

//Componente de animação de presentes flutuantes na pagina bonus
export const GiftAnimation = () => {
  const { themes } = useThemeChanger();

  return (
    <Gifts>
      <Box sx={{ color: themes.highlight }} id="leaves">
        {Array.from({ length: 15 }).map((_, index) => (
          <i key={index}>
            <FaGift />
          </i>
        ))}
        {Array.from({ length: 15 }).map((_, index) => (
          <i key={index + "ticket"}>
            <FaTicketAlt />
          </i>
        ))}
      </Box>
    </Gifts>
  );
};
