import * as yup from "yup";
import { useFormik } from "formik";
import { Box, Button } from "@mui/material";
import { handlePhone } from "../../utils";
import { InputComponent } from "../input";
import { useUser } from "../../Contexts/UserProvider";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { Timer } from "../timerComponent";

//Componente que recebe e valida o numero de whatsapp inserido
export const WhatsInput = () => {
  const { themes } = useThemeChanger();
  const { sendmfa } = useUser();
  const { enableTimer, setEnableTimer } = useUser();

  //Função que habilita o timer para inserção do codigo recebido por whatsapp
  const requestLimiterTimer = () => {
    setEnableTimer(false);
  };

  //Validação do formulario com YUP
  const whatsSchema = yup.object({
    whatsapp: yup.string().required("Insira o seu número."),
  });

  const formikWhats = useFormik({
    initialValues: {
      whatsapp: "",
    },
    validationSchema: whatsSchema,
    onSubmit: (values) => {
      sendmfa(values);
    },
  });

  return (
    <Box className='validateWhatsapp--box'
      sx={{ display: "flex", justifyContent: "center", gap: 2, width: "100%" }}
    >
      <InputComponent
       className='validateWhatsapp--box--input'
        id="whatsapp"
        label="Whatsapp"
        type="text"
        name="whatsapp"
        width={"70%"}
        value={formikWhats.values.whatsapp}
        onChange={(e) => {
          handlePhone(e);
          formikWhats.handleChange(e);
        }}
        onBlur={formikWhats.handleBlur}
        errors={formikWhats.touched.whatsapp && formikWhats.errors.whatsapp}
      />
      <Button
      className='validateWhatsapp--box--button'
        disabled={enableTimer}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          transition: "0.5s",
          background: themes.highlight,
          color: themes.text,
          ":hover": { background: themes.hoverButton },
        }}
        onClick={formikWhats.handleSubmit}
      >
        {!enableTimer ? "Enviar" : "Reenviar"}
        {enableTimer === true && (
          <Timer fontSize={[12]} callback={requestLimiterTimer} />
        )}
      </Button>
    </Box>
  );
};
