import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { LoadingDot } from "../loadingComponents/loagindDot";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { FaCopy, FaQuestionCircle, FaShareAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { RedacaoStepper } from "./redacaoStepper";
import { AnaliseStepper } from "./analiseStepper";
import { useUser } from "../../Contexts/UserProvider";
import { ExplicaStepper } from "./explicaStepper";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useGenText } from "../../Contexts/iaRequests";

//Componente onde a redação é gerada
export const RedacaoGenerate = () => {
  const { themes } = useThemeChanger();
  const [textValue, setTextValue] = useState("");
  const [component, setComponent] = useState("");
  const [displayText, setDisplayText] = useState([]);
  const { selectedCard } = useUser();
  const {
    prompt,
    isLoading,
    spentTokens,
    generating,
    setGenerating,
    disabled,
  } = useGenText();

  useEffect(() => {
    setGenerating(false);
  }, []);

  useEffect(() => {
    switch (selectedCard.title) {
      case "Redação":
        setComponent(<RedacaoStepper />);
        break;
      case "Análise":
        setComponent(<AnaliseStepper />);
        break;
      case "Explique":
        setComponent(<ExplicaStepper />);
        break;

      default:
        return;
    }
  }, [selectedCard]);

  //Função que auxilia no compartilhamento do texto gerado
  const shareText = (text) => {
    const textToShare = text;
    navigator
      .share({ text: textToShare })
      .then(() => console.log("Texto compartilhado com sucesso"))
      .catch((error) => console.log("Erro ao compartilhar:", error));
  };
  const inputRef = useRef(null);

  //Função que copia o texto gerado
  const handleCopy = () => {
    const textToCopy = inputRef.current.innerText;

    navigator.clipboard.writeText(textToCopy);
  };

  //função quer formata e escreve o texto letra por letra
  useEffect(() => {
    //Formata o texto recebido
    const formattedText = prompt.split("\n").flatMap((line, lineIndex) => {
      const characters = line.split("").map((char, charIndex) => ({
        id: `${lineIndex}-${charIndex}`,
        value: char,
      }));
      characters.push({ id: `${lineIndex}-${line.length}`, value: "\n" });
      return characters;
    });
    setDisplayText(formattedText);
    // let currentIndex = 0;
    // //Escreve letra por letra
    // const intervalId = setInterval(() => {
    //   setDisplayText((prevText) => {
    //     if (currentIndex >= formattedText.length) {
    //       clearInterval(intervalId);
    //       return prevText;
    //     }
    //     if (inputRef.current) {
    //       inputRef.current.focus();
    //     }
    //     currentIndex++;
    //     setDisabled(true);
    //     return formattedText.slice(0, currentIndex);
    //   });
    //   setDisabled(false);
    // }, 10);
    // return () => clearInterval(intervalId);
  }, [prompt, textValue]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box
        className="genTextBox--shortcuts"
        aria-describedby={id}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          alignSelf: "flex-start",
          justifySelf: "flex-start",
          color: themes.highlight,
          gap: 2,
        }}
      >
        <FaQuestionCircle
          onClick={() => introJs().start()}
          style={{ fontSize: "24px" }}
        />
        <Button
          id="Box input"
          data-intro="Copia o texto para que você possa utilizar onde precisar"
          data-step="1"
          className="genTextBox--shortcuts--copy"
          sx={{
            padding: [0, 0, 1, 1],
            minWidth: ["15px", "15px", "20px", "20px"],
            color: themes.highlight,
            fontSize: 20,
          }}
          disabled={disabled}
          onClick={() => {
            toast.success("Texto Copiado!");
            handleCopy();
          }}
        >
          <FaCopy />
        </Button>

        {/* <Button
          id="Box input"
          data-intro="Limpará a tela de texto"
          data-step="2"
          className="genTextBox--shortcuts--clear"
          sx={{
            padding: [0, 0, 1, 1],
            minWidth: ["15px", "15px", "20px", "20px"],
            color: themes.liaMainColor,
            fontSize: 24,
          }}
          disabled={disabled}
          onClick={() => {
            document.getElementById("textResponse").textContent = " ";
          }}
        >
          <RotateLeftSharp />
        </Button> */}
        <Button
          id="Box input"
          data-intro="Clicando aqui você poderá compartilhar o texto gerado"
          data-step="3"
          className="genTextBox--shortcuts--share"
          sx={{
            padding: [0, 0, 1, 1],
            minWidth: ["15px", "15px", "20px", "20px"],
            color: themes.liaMainColor,
            fontSize: 20,
          }}
          disabled={disabled}
          onClick={() => {
            const text = document.getElementById("textResponse").textContent;
            shareText(text);
          }}
        >
          <FaShareAlt />
        </Button>
        {(generating || displayText.length > 1) && (
          <Button
            id="Box input"
            data-intro="Re iniciará o campo de texto para que você possa gerar um novo conteudo. (Textos gerados serão mantidos no seu historico que pode ser acessado pelo menu.)"
            data-step="4"
            className="genTextBox--shortcuts--share"
            sx={{
              padding: [1],
              width: ["auto"],
              color: themes.background,
              background: themes.liaMainColor,
              fontSize: 8,
              ":hover": { background: themes.highlight },
            }}
            disabled={disabled}
            onClick={() => {
              setGenerating(false);
              setDisplayText("");
            }}
          >
            Recomeçar
          </Button>
        )}

        <Box
          className="genTextBox--shortcuts--costTokens"
          sx={{ width: "auto" }}
        >
          {spentTokens && displayText.length > 1 ? (
            <Typography
              sx={{
                fontSize: [12, 12, 16, 16],
                fontWeight: 900,
                color: themes.liaMainColor,
              }}
            >
              Tokens Gastos:{" "}
              <span
                style={{
                  fontSize: [12, 12, 16, 16],
                  fontWeight: 400,
                  color: themes.highlight,
                }}
              >
                {spentTokens}
              </span>
            </Typography>
          ) : (
            ``
          )}
        </Box>
      </Box>
      <Box
        className="genTextBox"
        id="Box input"
        data-intro="Nesta area você criará e visualizará seu texto."
        data-step="5"
        sx={{
          borderRadius: "20px",
          padding: ["1rem", "1rem", "3rem", "3rem"],
          minHeight: "75vh",
          maxHeight: "75vh",
          minWidth: ["100%"],
          maxWidth: ["100%"],
          color: themes.liaMainColor,
          border: `2px solid ${themes.liaMainColor}`,
          outline: "none",
          overflowY: "scroll",
          "::-webkit-scrollbar ": {
            width: "5px",
          },

          "::-webkit-scrollbar-track": {
            background: themes.background,
            margin: "1rem 0",
          },

          "::-webkit-scrollbar-thumb": {
            backgroundColor: themes.highlight,
            borderRadius: "20px",
          },
          position: "relative",
        }}
        ref={inputRef}
      >
        {generating || displayText.length > 1 ? (
          <Typography
            className="genTextBox--textField"
            id="textResponse"
            sx={{
              fontSize: [14, 16, 16, 18],
              height: "auto",
              overflow: "visible",
            }}
          >
            {displayText.length > 1 ? (
              displayText.map((char) => (
                <React.Fragment key={char.id}>
                  {char.value === "\n" ? <br /> : char.value}
                </React.Fragment>
              ))
            ) : (
              <>Gerando sua redação. Por favor aguarde!</>
            )}
          </Typography>
        ) : (
          component
        )}

        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <LoadingDot />
          </Box>
        )}
      </Box>
      <Popover
        className="genTextBox--infoButton"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ "& p": { color: themes.textGray } }}
      >
        <Typography sx={{ p: 2 }}>{selectedCard.text}</Typography>
      </Popover>
    </>
  );
};
