import { Box, Container } from "@mui/material";
// import { tawkTo } from "../../Components/dashboardComponent/tawkTo";
import { useUser } from "../../Contexts/UserProvider";
import TransitionWrapper from "../../Components/screenTransition";
import { HeaderComponent } from "../../Components/header";
import { HelpCenterComponent } from "../../Components/supportComponent/helpCenterComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useGenText } from "../../Contexts/iaRequests";

//Pagina de central de ajudas para o usuario Social Lia
export const HelpCenter = () => {
  const { userProfile } = useUser();
  // tawkTo(userProfile);

  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentRoute } = useGenText();
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, []);
  const [isAnimating, setIsAnimating] = useState(false);
  const startAnimation = () => {
    setIsAnimating(true);
  };
  return (
    <TransitionWrapper
      isAnimating={isAnimating}
      setIsAnimating={setIsAnimating}
      callback={() => navigate("/dashboard")}
    >
      <Container
        className="helpCenterContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          margin: [0, 0, 0, 0],
          padding: [0, 0, 0, 0],
          gap: 2,
          overflowX: ["hidden", "hidden", "visible", "visible"],
        }}
      >
        <HeaderComponent startAnimation={startAnimation} />
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row", "row"],
            width: "95vw",
            height: "100%",
            gap: 2,
            overflow: "hidden",
          }}
        >
          <HelpCenterComponent startAnimation={startAnimation} />
        </Box>
      </Container>
    </TransitionWrapper>
  );
};
