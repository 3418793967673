import { Box, Typography } from "@mui/material";
import { ReturnDashButton } from "../button";
import { FaCoins } from "react-icons/fa";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useUser } from "../../Contexts/UserProvider";
import { useBounty } from "../../Contexts/bountyProvider";

//informação de titulo e botão de retorno de cada pagina dentro do social lia
export const TopContent = ({ title, showTokens = false, onClick }) => {
  const { themes } = useThemeChanger();
  const { isVisible, handleVisibleToken } = useUser();
  const { balance, getBalance } = useBounty();

  const handleToken = () => {
    if (!balance) {
      getBalance();
    }
  };

  return (
    <Box
      className="topContentContainer"
      sx={{
        display: "flex",
        justifyContent: [
          "space-between",
          "space-between",
          "flex-start",
          "flex-start",
        ],
        gap: [0, 0, 5, 5],
      }}
    >
      <Typography
        className="topContentContainer--title"
        sx={{
          fontSize: [24, 28, 32, 40],
          fontWeight: 900,
          background: `-webkit-linear-gradient(4deg, ${themes.liaMainColor} 20%, ${themes.liaMainColor} 50%, ${themes.highlight} 58%, ${themes.highlight} 80%)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          whiteSpace: "nowrap",
          display: "flex",
          gap: 1,
          ":hover": { cursor: "pointer" },
        }}
        onClick={onClick && onClick}
      >
        <ReturnDashButton startAnimation={onClick} />
        {title}
      </Typography>
      {showTokens === true && (
        <Box
          className="topContentContainer--coinIcon"
          sx={{
            display: "flex",
            alignItems: "center",
            color: themes.highlight,
            fontSize: [20, 20, 24, 24],
            gap: 1,
          }}
        >
          <FaCoins />{" "}
          <Typography
            className="topContentContainer--tokensQuantity"
            sx={{
              color: themes.liaMainColor,
              fontSize: [16, 16, 20, 20],
              fontWeight: 900,
              whiteSpace: "nowrap",
            }}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleVisibleToken();
                handleToken();
              }}
              style={{
                color: themes.highlight,
              }}
            >
              {isVisible ? balance : "****"}
            </span>{" "}
            Tokens
          </Typography>
        </Box>
      )}
    </Box>
  );
};
