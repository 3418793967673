import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../Contexts/UserProvider";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { Img } from "./style";

//Componente de confirmação de e-mail
export const ConfirmEmail = ({ transform }) => {
  const { themes } = useThemeChanger();

  const { enviaConfimacaoEmail } = useUser();

  const [validaEmail, setValidaEmail] = useState(true);

  const { userToken } = useParams();

  const enviaEmailToken = () => {
    setValidaEmail(false);
    enviaConfimacaoEmail({ email_token: userToken });
  };

  return (
    <Container
      className="confirmEmailContainer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        transform: `translate(${transform}%)`,
        width: "100vw",
        height: "100vh",
        transition: "1s",
        p: [0, 0, 0, 0],
        gap: 5,
      }}
    >
      <Img
        className="logo"
        src={themes.src.logoSocial}
        alt="Logo fale com Lia Social"
      />
      <Box
        className="confirmEmailContainer--content"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          m: 8,
          lineHeight: [2, 3, 5],
        }}
      >
        <Typography
          className="confirmEmailContainer--content--title"
          variant="h2"
          sx={{
            fontSize: [24, 28, 36, 64],
            fontWeight: 800,
            color: themes.liaMainColor,
          }}
        >
          {validaEmail ? "Valide seu E-mail aqui." : "Validando E-mail."}
        </Typography>
        <Typography
          className="confirmEmailContainer--content--description"
          variant="p"
          sx={{
            fontSize: [14, 16, 20],
            textAlign: "center",
            color: themes.textGray,
          }}
        >
          {validaEmail
            ? "Click no botão abaixo"
            : "Aguarde enquanto verificamos."}
        </Typography>
      </Box>
      <Button
        className="confirmEmailContainer--submitButton"
        sx={{
          width: "15rem",
          height: "3rem",
          background: themes.highlight,
          color: themes.text,
          p: 0,
          transition: "0.3s",
          ":hover": { background: themes.hoverButton, transform: "scale(1.2)" },
        }}
        onClick={enviaEmailToken}
      >
        Validar E-mail
      </Button>
    </Container>
  );
};
