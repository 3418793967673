import { Box, Button, Drawer, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { TopContent } from "../topContent";
import { motion } from "framer-motion";
import { useState } from "react";
import { useCompany } from "../../Contexts/companyProvider";
import { DashboardCard } from "./dashboardCard";
import { useGenText } from "../../Contexts/iaRequests";
import { useBounty } from "../../Contexts/bountyProvider";
import { toast } from "react-toastify";

//Botão que abre a paleta de ferramentas
export const DashboardSelectCard = ({
  height,
  Icon,
  title,
  callback,
  disabled,
  items,
  startAnimation,
  setRoute,
  setSelectedCard, needCompany
}) => {
  const { themes } = useThemeChanger();
  const { empresas } = useCompany();
  const { balance, getBalance } = useBounty();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (!balance) {
      getBalance()
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (balance <= 0) {
      toast.error('Saldo insuficiente, compre mais tokens')
    }
    else {
      setState({ ...state, [anchor]: open });
    }

  };

  const list = (anchor) => (
    <Box
      sx={{
        width: "100vw",
        height: height ? height : "95vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: themes.background,
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: ["column", "column", "flex", "flex"],
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: height ? height : "95vh",
          overflow: ["hidden"],
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: height ? height : "95vh",
            padding: "0 1rem",
          }}
        >
          <TopContent onClick={toggleDrawer(anchor, false)} title={title} />
          <Box
            sx={{
              display: "flex",
              alignContent: "start",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "90vw",
              height: "80vh",
              overflowY: "scroll",
              padding: "2rem 0 ",
              gap: 3,
              mt: 5,
              "::-webkit-scrollbar ": {
                width: "5px",
              },

              "::-webkit-scrollbar-track": {
                background: themes.background,
              },

              "::-webkit-scrollbar-thumb": {
                backgroundColor: themes.highlight,
                borderRadius: "20px",
              },
            }}
            onClick={toggleDrawer(anchor, false)}
          >
            {items &&
              items
                .sort((a, b) =>
                  a.disable === b.disable ? 0 : a.disable ? 1 : -1
                )
                .map((el, i) => (
                  <DashboardCard
                    key={i + el.text}
                    title={el.title}
                    Icon={el.icon}
                    text={el.text}
                    route={el.route}
                    disable={el.disable}
                    type={el.type}
                    onClick={() => {
                      setRoute(el.route);
                      startAnimation();
                      setSelectedCard(el);
                    }}
                  />
                ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className="toolsContainerButton">
      <Box onClick={callback}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <Button
            onClick={
              (empresas.length > 0 && disabled === false) || needCompany === false
                ? toggleDrawer("bottom", true)
                : () => { }
            }
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: themes.dashBoardCardBg,
              color: themes.liaMainColor,
              width: ["120px", "130px", "130px", "130px"],
              height: ["120px", "130px", "130px", "130px"],
              borderRadius: "15px",
              position: "relative",
              transition: "0.3s",
              padding: [0, 0, 0, 0],
              fontWeight: 900,

              ":hover": {
                background: disabled ? "" : "rgba(255,99,0,0.2)",
                color: disabled ? "" : themes.highlight,
                cursor: disabled ? "default" : "pointer",
                transition: "1s",
              },
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontSize: [36, 36, 44, 44],
                filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
              }}
            >
              <Icon />
            </Typography>

            {disabled === true && (
              //Se a tag disabled = true aparecera este componente
              <Box
                className="toolsContainerButton--disabledTag"
                sx={{
                  position: "absolute",
                  background: "rgba( 255, 255, 255, 0.35 )",
                  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                  backdropFilter: "blur( 3.5px )",
                  WebkitBackdropFilter: "blur( 3.5px )",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    background: themes.highlight,
                    border: `2px solid ${themes.highlight}`,
                    width: "250px",
                    position: "absolute",
                    padding: "0 5rem",
                    top: ["10%", "10%", "15%", "20%"],
                    right: ["-80%", "-80%", "-55%", "-55%"],
                    transform: "rotate(45deg)",
                    fontSize: [10, 10, 14, 14],
                    color: "white",
                  }}
                >
                  EM BREVE
                </Box>
              </Box>
            )}
            <Typography
              sx={{
                fontSize: [12, 12, 16, 16],
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          </Button>
        </motion.div>
      </Box>
      <Drawer
        className="toolsContainerButton--toolsContent"
        anchor={"bottom"}
        open={state["bottom"]}
        onClose={toggleDrawer("bottom", false)}
      >
        {list("bottom")}
      </Drawer>
    </Box>
  );
};

//componente semelhante ao DashboardSelectCard, mas este será de click unico
//Levará você direto para uma tela ao invez de abrir um menu

export const DashboardSelectCardUnique = ({
  Icon,
  title,
  callback,
  disabled,
  startAnimation,
  setRoute,
  route,
  setSelectedCard,
  text,
  type,
}) => {
  const { themes } = useThemeChanger();
  const { setType } = useGenText();

  const { balance, getBalance } = useBounty();

  const routeType = typeof route;

  const handleOpen = () => {
    if (!balance) {
      getBalance()
    }
    if (balance <= 0) {
      toast.error('Saldo insuficiente, compre mais tokens')
    }
    else {
      if (routeType === 'string') {
        setRoute(route);
        startAnimation();
        setSelectedCard({
          title: title,
          text: text,
        });
        setType(type);
      }
      else {
        route()
      }
    }
  }

  return (
    <Box className="toolsContainerButton">
      <Box onClick={callback}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <Button
            onClick={() => { handleOpen() }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: themes.dashBoardCardBg,
              color: themes.liaMainColor,
              width: ["120px", "130px", "130px", "130px"],
              height: ["120px", "130px", "130px", "130px"],
              borderRadius: "15px",
              position: "relative",
              transition: "0.3s",
              padding: [0, 0, 0, 0],
              fontWeight: 900,

              ":hover": {
                background: disabled ? "" : "rgba(255,99,0,0.2)",
                color: disabled ? "" : themes.highlight,
                cursor: disabled ? "default" : "pointer",
                transition: "1s",
              },
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontSize: [36, 36, 44, 44],
                filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
              }}
            >
              <Icon />
            </Typography>

            {disabled === true && (
              //Se a tag disabled = true aparecera uma tag e desabilitara o botão
              <Box
                className="toolsContainerButton--disabledTag"
                sx={{
                  position: "absolute",
                  background: "rgba( 255, 255, 255, 0.35 )",
                  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                  backdropFilter: "blur( 3.5px )",
                  WebkitBackdropFilter: "blur( 3.5px )",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    background: themes.highlight,
                    border: `2px solid ${themes.highlight}`,
                    width: "250px",
                    position: "absolute",
                    padding: "0 5rem",
                    top: ["10%", "10%", "15%", "20%"],
                    right: ["-80%", "-80%", "-55%", "-55%"],
                    transform: "rotate(45deg)",
                    fontSize: [10, 10, 14, 14],
                    color: "white",
                  }}
                >
                  EM BREVE
                </Box>
              </Box>
            )}
            <Typography
              sx={{
                fontSize: [12, 12, 16, 16],
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          </Button>
        </motion.div>
      </Box>
    </Box >
  );
};
