import bcrypt from "bcryptjs";
import { FaBloggerB, FaBusinessTime, FaFilter, FaRoute } from "react-icons/fa";

import { GiHumanTarget, GiJigsawPiece } from "react-icons/gi";
import {
  BsReverseLayoutTextWindowReverse,
  BsCalendarDay,
  BsFillNodePlusFill,
  BsSearchHeart,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { IoCreate } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";

import {
  FcIdea,
  FcReadingEbook,
  FcPodiumWithSpeaker,
  FcNook,
  FcCollaboration,
  FcOnlineSupport,
  FcWorkflow,
} from "react-icons/fc";
import { TfiVideoClapper } from "react-icons/tfi";
import { RiMapPinTimeLine, RiPagesLine, RiUserStarLine } from "react-icons/ri";
import { SiPersonio } from "react-icons/si";

import introJs from "intro.js";
import "intro.js/introjs.css";

export const images = [
  {
    src: "https://image.lexica.art/md2/0f18df74-9644-491f-bd1c-7ced4c960f05",
  },
  {
    src: "https://image.lexica.art/md2/100f5fb8-20c0-4ee8-b39d-23f1e9d56693",
  },
  {
    src: "https://image.lexica.art/md2/13597c70-1403-49b4-8c02-1f4becc5d6f0",
  },
  {
    src: "https://image.lexica.art/md2/2399d47e-0a6f-4de0-b7c8-cc8c4225b210",
  },
  {
    src: "https://image.lexica.art/md2/267e8c98-a646-4ab9-aae7-8c041720861d",
  },
  {
    src: "https://image.lexica.art/md2/27ac9396-4e7d-4ed7-90fb-aa49e7f6ce87",
  },
  {
    src: "https://image.lexica.art/md2/18a0b031-6dea-4f4f-8100-1709b1c3c0bd",
  },
  {
    src: "https://image.lexica.art/md2/19ba5f22-2b39-4d43-9db4-6b3d57cae634",
  },
  {
    src: "https://image.lexica.art/md2/1ef85991-b0db-42b9-bb9b-d8d74000dc48",
  },
  {
    src: "https://image.lexica.art/md2/04693dc0-7374-488e-83c3-1460f8ec5b98",
  },
  {
    src: "https://image.lexica.art/md2/052652f3-d5db-4452-9895-18c1472e9317",
  },
  {
    src: "https://image.lexica.art/md2/0c32396a-02b7-4dab-92bc-e98878bbe4ad",
  },
  {
    src: "https://image.lexica.art/md2/2399d47e-0a6f-4de0-b7c8-cc8c4225b210",
  },
  {
    src: "https://image.lexica.art/md2/267e8c98-a646-4ab9-aae7-8c041720861d",
  },
  {
    src: "https://image.lexica.art/md2/27ac9396-4e7d-4ed7-90fb-aa49e7f6ce87",
  },
  {
    src: "https://image.lexica.art/md2/40e6efa0-9a0a-4c9e-81a0-a3df54bbb21c",
  },
  {
    src: "https://image.lexica.art/md2/450f2765-549d-4d54-a087-15dc11ad96aa",
  },
  {
    src: "https://image.lexica.art/md2/442114d3-85e0-412e-ae23-8b7e5e035066",
  },
  {
    src: "https://image.lexica.art/md2/5365787c-ad0f-47db-a789-027250b38bf7",
  },
  {
    src: "https://image.lexica.art/md2/663bc5bb-d37d-4dac-a9f3-28b2b8566b46",
  },
  {
    src: "https://image.lexica.art/md2/68975fc2-bea6-4a1d-86ac-62a1d077838c",
  },
  {
    src: "https://image.lexica.art/md2/45efb499-5df7-435a-8c29-91b53e40b81e",
  },
  {
    src: "https://image.lexica.art/md2/2bf06943-69a5-47bb-89f0-94d4f6658c56",
  },
  {
    src: "https://image.lexica.art/md2/323f1ce8-591b-4d1e-988e-311dc53ac4e4",
  },
  {
    src: "https://image.lexica.art/md2/3d884cb3-73d4-45d2-b37b-16b98d9361ff",
  },
  {
    src: "https://image.lexica.art/md2/18a0b031-6dea-4f4f-8100-1709b1c3c0bd",
  },
  {
    src: "https://image.lexica.art/md2/19ba5f22-2b39-4d43-9db4-6b3d57cae634",
  },
  {
    src: "https://image.lexica.art/md2/1ef85991-b0db-42b9-bb9b-d8d74000dc48",
  },
  {
    src: "https://image.lexica.art/md2/984463dc-7640-4d22-b3e9-547a39d178b4",
  },
  {
    src: "https://image.lexica.art/md2/98ca1a1a-0bfe-4f56-8ba6-6b1b1bd5d1f0",
  },
  {
    src: "https://image.lexica.art/md2/b9633f7d-10f2-40d2-b46b-f89db9fe7a58",
  },
  {
    src: "https://image.lexica.art/md2/bc5397c6-3e1f-49cc-960c-82a1ae64b604",
  },
  {
    src: "https://image.lexica.art/md2/ca15beed-fb39-4812-9d0a-13cec058d8ae",
  },
  {
    src: "https://image.lexica.art/md2/cbf59358-587a-40e3-a659-5daaed32e106",
  },
  {
    src: "https://image.lexica.art/md2/ce282c65-13f7-45c9-a797-0c6c11eb25e2",
  },
  {
    src: "https://image.lexica.art/md2/cf908fc0-a2cc-4f20-bd5f-636c79491c81",
  },
  {
    src: "https://image.lexica.art/md2/d4d20137-ec85-481b-8618-86be5771b695",
  },
  {
    src: "https://image.lexica.art/md2/ca0e3de6-f969-49f0-8ece-8a50b6ba0113",
  },
  {
    src: "https://image.lexica.art/md2/a38dd2c6-001c-47ab-85b4-bc596582e361",
  },
  {
    src: "https://image.lexica.art/md2/b5fd63a2-d76a-4709-ba97-fff0532581bc",
  },
  {
    src: "https://image.lexica.art/md2/b8ceecc0-cd7a-4dfd-af64-f1b44f5304bb",
  },
  {
    src: "https://image.lexica.art/md2/a087d17e-6500-4e13-b94f-4b4bc1a3b508",
  },
  {
    src: "https://image.lexica.art/md2/86cf26e1-fa9f-4923-b4e9-d7205cf4dbe4",
  },
  {
    src: "https://image.lexica.art/md2/9021c8ad-e259-421f-a9b6-7f03cc684b5f",
  },
  {
    src: "https://image.lexica.art/md2/90694438-daae-489b-84b0-b4f58fcc4555",
  },
];

export const ufs = [
  "RO",
  "AC",
  "AM",
  "RR",
  "PA",
  "AP",
  "TO",
  "MA",
  "PI",
  "CE",
  "RN",
  "PB",
  "PE",
  "AL",
  "SE",
  "BA",
  "MG",
  "ES",
  "RJ",
  "SP",
  "PR",
  "SC",
  "RS",
  "MS",
  "MT",
  "GO",
  "DF",
];
//
//
//
//FOMATADORES ////////////////////////////////////////////////////////////////////
//
//
//
export const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
export const dateRegex =
  /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[0-2])([0-9]{4})$/;

//Formata o numero de telefone para: (xx) 9xxxx-xxxx
export const handlePhone = (event) => {
  let input = event.target;
  input.value = phoneMask(input.value);
};

const phoneMask = (value) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
};

// Formata o CPF para: xxx.xxx.xxx-xx
export const handleCPF = (event) => {
  let input = event.target;
  input.value = formatCPF(input.value);
};
const formatCPF = (value) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  const matches = value.match(cpfRegex);

  if (matches) {
    value = `${matches[1]}.${matches[2]}.${matches[3]}-${matches[4]}`;
  }
  return value.trim();
};

//Foramta a data para: DD/MM/AAAA
export const handleDate = (event) => {
  let input = event.target;
  input.value = dateFormater(input.value);
};

const dateFormater = (value) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  const matches = value.match(/(\d{2})(\d{2})(\d{4})/);

  if (matches) {
    const day = matches[1];
    const month = matches[2];
    const year = matches[3];

    if (day > 31 || month > 12) {
      return ""; // Data inválida
    }

    return `${day}/${month}/${year}`;
  }
  return value;
};

//formata links para lowerCase
export const formatarLink = (event, setFieldValue) => {
  const input = event.target;
  if (!input.value.startsWith("https://")) {
    const url = `https://${input.value.toLowerCase().replace(/\s/g, "")}`;
    setFieldValue(input.name, url);
  }
};

//Formata nomes para formato de titulo
export const handleName = (event, setFieldValue) => {
  let input = event.target;
  const newValue = nameFormater(input.value);
  setFieldValue(input.name, newValue);
};

const nameFormater = (nomeCompleto) => {
  const nomeArray = nomeCompleto.toLowerCase().split(" ");
  const nomeFormatadoArray = nomeArray.map(
    (nome) => nome.charAt(0).toUpperCase() + nome.slice(1)
  );
  return nomeFormatadoArray.join(" ").trim();
};

//Formata e-mail para lowerCase
export const handleEmail = (event, setFieldValue) => {
  let input = event.target;
  const newValue = emailFormater(input.value);
  setFieldValue("email", newValue);
};

const emailFormater = (email) => {
  return email.toLowerCase().trim();
};

//Formata o CEP para: xxxxx-xxx
export const handleCep = (event) => {
  let input = event.target;
  input.value = cepFormatter(input.value);
};

const cepFormatter = (cep) => {
  cep = cep.replace(/\D/g, "");
  if (cep.length > 8) cep = cep.slice(0, 8);
  cep = cep.replace(/^(\d{5})(\d)/, "$1-$2");
  return cep.trim();
};
//
//
//
//FOMATADORES ////////////////////////////////////////////////////////////////////
//
//
//
export const generateHash = (data) => {
  const salt = bcrypt.genSaltSync(10);
  const hash = bcrypt.hashSync(data, salt);
  return hash;
};

export const compareData = (data, hash) => {
  return bcrypt.compareSync(data, hash);
};

export const converterBase64ParaImagem = (base64String) => {
  const byteCharacters = atob(base64String); // Decodifica a string Base64
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: "image/png" }); // Altere o tipo de imagem conforme necessário
  // verificarQRCode(base64String, blob);
  return URL.createObjectURL(blob);
};
export const listaProfissoes = [
  "Restaurantes e Bares",
  "Cafeterias e Padarias",
  "Lojas de Roupas e Acessórios",
  "Salões de Beleza e Barbearias",
  "Floriculturas e Jardins",
  "Pet Shops e Clínicas Veterinárias",
  "Pequenas Livrarias e Papelarias",
  "Lojas de Eletrônicos e Tecnologia",
  "Artesanato e Decoração",
  "Serviços de Limpeza e Manutenção Residencial",
  "Consultorias e Serviços Profissionais",
  "Academias e Estúdios de Fitness",
  "Agências de Viagens e Turismo",
  "Empresas de Serviços de TI (Tecnologia da Informação)",
  "Agências de Publicidade e Marketing",
  "Treinamentos",
  "Tutoria",
  "Restaurantes e Cadeias de Fast-Food",
  "Lojas de Departamento e Varejo",
  "Concessionárias de Veículos",
  "Empresas de Logística e Transporte",
  "Empresas de Construção e Engenharia",
  "Fábricas e Indústrias de Médio Porte",
  "Empresas de Tecnologia e Desenvolvimento de Software",
  "Agências de Publicidade e Marketing Digital",
  "Empresas de Consultoria Empresarial",
  "Distribuidoras e Atacadistas",
  "Empresas de Eventos e Produção de Entretenimento",
  "Empresas de Saúde e Clínicas Médicas",
  "Empresas de Energia Renovável e Sustentabilidade",
  "Empresas de Turismo e Hospedagem",
  "Instituições Financeiras de Médio Porte",
  "Profissional educacional",
  "Empresas Multinacionais e Corporações Globais",
  "Indústrias de Grande Porte (automobilística, petroquímica, siderúrgica, etc.)",
  "Instituições Financeiras de Grande Porte (bancos, seguradoras, fundos de investimento)",
  "Empresas de Tecnologia e Telecomunicações",
  "Empresas de Energia (elétrica, petróleo, gás)",
  "Varejistas de Grande Porte (cadeias de supermercados, hipermercados)",
  "Empresas de Aviação e Transporte Aéreo",
  "Empresas de Construção Civil e Engenharia Pesada",
  "Empresas Farmacêuticas e de Ciências da Vida",
  "Empresas de Mineração e Recursos Naturais",
  "Empresas de Alimentos e Bebidas (fabricantes e distribuidores)",
  "Empresas de Entretenimento e Mídia (estúdios de cinema, redes de televisão)",
  "Empresas de Logística e Cadeia de Suprimentos",
  "Empresas de Consultoria de Gestão e Estratégia",
  "Empresas de Automóveis e Fabricantes de Veículos",
];

export const dashboardCards = [
  {
    topic: "Marketing",
    icon: () => <FcIdea />,
    needCompany: true,
    disabled: false,
    height: "80vh",
    items: [
      {
        icon: () => <BsReverseLayoutTextWindowReverse />,
        title: "Legendas",
        text: "Crie legendas para seus posts. Uma boa legenda nas redes sociais é a chave para conectar, contar histórias e envolver o público de forma significativa.",
        route: "/gera_texto/legendas",
        disable: false,
        type: "lia_legenda",
        tag: "Marketing / Legenda",
      },
      {
        icon: () => <FaRoute />,
        title: "Planejamento",
        text: "Confie em nossas poderosas ferramentas de planejamento para impulsionar o seu negócio a patamares mais elevados, construindo estratégias sólidas e eficazes que o levarão aos seus objetivos comerciais.",
        route: "/gera_texto/planejamento",
        disable: false,
        type: "lia_planejamentomkt",
        tag: "Marketing / Planejamento",
      },

      {
        icon: () => <FaBloggerB />,
        title: "Blogs",
        text: "Um bom texto de blog não é apenas informação, é a chave para cativar, educar e converter seu público. Utilize a nossa ferramenta de criação de conteúdo para blogs. É fácil e rápido.",
        route: "/gera_texto/blogs",
        disable: false,
        type: "lia_blog",
        tag: "Marketing / Blog",
      },
      {
        icon: () => <FaFilter />,
        title: "Funil",
        text: "Desenvolva um funil de vendas inteligente e eficiente para transformar leads em clientes satisfeitos. Conte com nossos recursos para estabelecer um fluxo consistente de vendas.",
        route: "/gera_texto/funil",
        disable: false,
        type: "lia_funil",
        tag: "Marketing / Funil",
      },
      {
        icon: () => <RiPagesLine />,
        title: "Landing Page",
        text: "Maximize suas conversões com landing pages impressionantes. Experimente nossos tokens para obter criatividade e inspiração na criação da sua página de destino.",
        route: "/gera_texto/landing_page",
        disable: false,
        type: "lia_landingpage",
        tag: "Marketing / Landing Page",
      },
      {
        icon: () => <IoCreate />,
        title: "Marcas",
        text: "Estabeleça uma marca robusta e amplamente reconhecida no mercado. Nossos recursos estão prontos para gerar novos nomes que refletem os valores e personalidade distintos de sua marca.",
        route: "/gera_texto/marcas",
        disable: false,
        type: "lia_marcasnomes",
        tag: "Marketing / Marcas e Nomes",
      },
      {
        icon: () => <TfiVideoClapper />,
        title: "Roteiros Vídeos",
        text: "Potencialize sua Criatividade: Apresentamos a Ferramenta de Criação de Roteiros de Vídeo. Transforme Idéias em Histórias Cativantes com Facilidade!",
        route: "/gera_texto/roteiros_videos",
        disable: false,
        type: "lia_roteirosvideos",
        tag: "Marketing / Roteiros Videos",
      },
      {
        icon: () => <MdEmail />,
        title: "E-mails",
        text: "Desbloqueie a Escrita Poderosa: Apresentamos a Ferramenta de Criação de Textos para E-mails. Crie Mensagens Impactantes de Forma Simples e Eficiente, melhorando a performance de suas vendas.",
        route: "/gera_texto/emails",
        disable: false,
        type: "lia_emailmkt",
        tag: "Marketing / Emails",
      },
      {
        icon: () => <GiHumanTarget />,
        title: "Público alvo",
        text: "O público-alvo engloba um conjunto diversificado de indivíduos com características e interesses afins, todos eles sendo potenciais consumidores de seus produtos ou serviços. Utilize a nossa ferramenta e ganhe tempo.",
        route: "/gera_texto/publico_alvo",
        disable: false,
        type: "lia_publicoalvo",
        tag: "Marketing / Publico Alvo",
      },
      {
        icon: () => <SiPersonio />,
        title: "Persona",
        text: "Crie Personas de Marketing com Precisão: Apresentamos nossa Ferramenta de Criação de Personas. Construa Perfis de Cliente Detalhados e Aprofunde sua Estratégia de Marketing de Forma Simples e Eficaz.",
        route: "/gera_texto/persona",
        disable: false,
        type: "lia_persona",
        tag: "Marketing / Persona",
      },
    ],
  },
  {
    topic: "Educação",
    needCompany: false,
    icon: () => <FcPodiumWithSpeaker />,
    disabled: false,
    height: "80vh",
    items: [
      {
        icon: () => <FaBusinessTime />,
        title: "Provas",
        text: "Apresentamos nossa Ferramenta de Criação de Provas Escolares. Poupe Tempo, Simplifique a Avaliação e Proporcione Testes de Qualidade aos Seus Alunos.",
        route: "/gera_texto/provas",
        disable: false,
        type: "lia_provas",
        tag: "Educação / Provas",
      },
      {
        icon: () => <GiJigsawPiece />,
        title: "Quiz",
        text: "Conheça nossa Ferramenta de Criação de Quizzes Escolares. Engaje seus Alunos com Perguntas Interativas.",
        route: "/gera_texto/quiz",
        disable: false,
        type: "lia_quiz",
        tag: "Educação / Quiz",
      },
      {
        icon: () => <RiMapPinTimeLine />,
        title: "Cronograma",
        text: "Planejar suas aulas nunca foi tão simples! Descubra nossa Ferramenta de Criação de Cronogramas de Aulas. Organize, otimize e leve suas aulas a um novo nível com facilidade e eficiência.",
        route: "/gera_texto/cronograma",
        disable: false,
        type: "lia_cronograma",
        tag: "Educação / Cronogramas",
      },

      {
        icon: () => <BsCalendarDay />,
        title: "Planejamento",
        text: "Potencialize seu Ensino com Nossa Ferramenta de Criação de Planejamento de Aulas! Crie Aulas Impactantes, Alinhe Objetivos Educacionais e Mantenha-se Organizado de Maneira Simples e Eficiente.",
        route: "/gera_texto/planejamento",
        disable: false,
        type: "lia_planejamentoedu",
        tag: "Educação / Planejamento",
      },
      {
        icon: () => <IoIosPeople />,
        title: "Reunião",
        text: "Otimizamos suas Reuniões Escolares! Descubra nossa Ferramenta de Criação de Pautas. Planeje, Organize e Mantenha Suas Reuniões de Professores Produtivas e Focadas nos Objetivos com Facilidade.",
        route: "/gera_texto/reuniao",
        disable: false,
        type: "lia_reuniao",
        tag: "Educação / Reunião",
      },
      {
        icon: () => <RiUserStarLine />,
        title: "Educação Especial",
        text: "Personalize o Aprendizado Especial! Apresentamos nossa Ferramenta de Criação de Conteúdo para Educação Especial. Adapte Lições com Facilidade e Capacite seus Alunos a Alcançarem Todo o seu Potencial.",
        route: "/gera_texto/educacao_especial",
        disable: false,
        type: "lia_educacaoespecial",
        tag: "Educação / Educação Especial",
      },
      {
        icon: () => <BsFillNodePlusFill />,
        title: "Material Suplementar",
        text: "Eleve suas aulas a um novo patamar com nossa Ferramenta de Criação de Conteúdo Complementar. Envolva seus alunos com materiais personalizados, enriqueça o aprendizado e transforme a educação em uma jornada empolgante!",
        route: "/gera_texto/material_suplementar",
        disable: false,
        type: "lia_materialsuplementar",
        tag: "Educação / Material Suplementar",
      },

      // {
      //   icon: () => <GiGiftTrap />,
      //   title: "Conteúdo Motivacional",
      //   text: "Descubra como incentivar o bom desempenho dos alunos. Os professores podem oferecer prêmios, certificados ou reconhecimento especial para motivar os estudantes a se destacarem em seus estudos.",
      //   route: "/gera_texto/",
      //   disable: falsMe,
      //   type: "lia_conteudomotivacional",
      //   tag: "Educacao / Conteudo Motivacional",
      // },
      {
        icon: () => <BsSearchHeart />,
        title: "Conteúdo Cultural",
        text: "Amplie o Horizonte do Conhecimento com Nossa Ferramenta de Criação de Conteúdo Cultural para Aulas. Enriqueça sua Sala de Aula com Experiências Imersivas, Promovendo uma Aprendizagem Cativante e Culturalmente Rica.",
        route: "/gera_texto/conteudo_Cultural",
        disable: false,
        type: "lia_conteudocultural",
        tag: "Educação / Conteúdo Cultural",
      },
    ],
  },
  {
    topic: "Redação",
    icon: () => <FcReadingEbook />,
    disabled: false,
    height: "80vh",
    route: "/textos/redacao",
    type: "lia_redacao",
    title: "Redação",
    text: "Selecione as ferramentas para criar o formato da sua redação e depois descreva o tema.",
    tag: "Redaçâo",
  },
  {
    topic: "Análise",
    icon: () => <FcNook />,
    disabled: false,
    height: "80vh",
    route: "/textos/analise",
    type: "lia_analise",
    title: "Análise de Texto",
    text: "Envie um texto para que eu analise, posso gerar um resumo, corrigir ou categorizar o texto por tópicos importantes.",
    tag: "Analise de Texto",
  },
  {
    topic: "Explique",
    icon: () => <FcCollaboration />,
    disabled: false,
    height: "80vh",
    route: "/textos/explique",
    type: "lia_explica",
    title: "Explicação",
    text: "Escolha um tema e a forma que deseja que seja explicado.",
    tag: "Explique",
  },
  {
    topic: "Suporte",
    icon: () => <FcOnlineSupport />,
    disabled: false,
    height: "80vh",
    route: "/suporte",
    type: "lia_suporte",
    title: "Suporte",
    text: "Duvidas em como usar o app? A gente te ajuda.",
    tag: "Suporte",
  },
  {
    topic: "Tutorial",
    icon: () => <FcWorkflow />,
    disabled: false,
    height: "80vh",
    route: () => {
      introJs()
        .oncomplete(() => window.scrollTo(0, 0))
        .start();
    },
    type: "lia_tutorial",
    title: "Tutorial",
    text: "Duvidas sobre a interface? Eu te ajudo.",
    tag: "Tutorial",
  },
];

//Retira todos acentros
export const removerAcentos = (texto) => {
  return texto
    .replace(/[áàãâä]/g, "a")
    .replace(/[éèêë]/g, "e")
    .replace(/[íìîï]/g, "i")
    .replace(/[óòõôö]/g, "o")
    .replace(/[úùûü]/g, "u")
    .replace(/[ç]/g, "c")
    .replace(/[ñ]/g, "n");
};

export const sortByDate = (array) => {
  // Converte as strings de data em objetos Date
  const dateObjects = array.map((dateString) => new Date(dateString));

  // Ordena o array de objetos Date em ordem decrescente (da mais atual para a mais antiga)
  const sortedDates = dateObjects.sort((a, b) => b - a);

  // Converte os objetos Date ordenados de volta para strings de data no formato original
  const sortedDateStrings = sortedDates.map((dateObject) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    return dateObject.toLocaleString("pt-BR", options);
  });

  return sortedDateStrings;
};
