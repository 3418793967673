import styled from "styled-components";

export const Img = styled.img`
  transition: 0.5s;
  border-radius: 10px 0 0 10px;
  width: 30%;
  @media (max-width:764px) {
      width: 60%;
  }
`;
