import { Container } from "@mui/material";
import { useUser } from "../../Contexts/UserProvider";
import { ConfirmEmail } from "../../Components/confirmEmail";
import { Login } from "../../Components/credentialsComponents/loginCard";
import { CircularIndeterminate } from "../../Components/loadingComponents/loading";

//Pagina de confirmação de email
export const ConfirmaEmail = () => {
  const { primeiroEnvio, isLoading } = useUser();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        padding: [0, 0, 0, 0],
        width: "100vw",
        height: "100vh",
      }}
    >
      {isLoading && <CircularIndeterminate />}

      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          width: "100vw",
          height: "100vh",
          position: "relative",
          transition: "1s",
          padding: [0, 0, 0, 0],
        }}
      >
        <ConfirmEmail transform={!primeiroEnvio ? -120 : 0} />
        <Login firstLogin={false} transform={!primeiroEnvio ? 0 : 120} />
      </Container>
    </Container>
  );
};
