import { Box, Button, Modal, Typography } from "@mui/material";
import { FaCopy, FaStar, FaTrash } from "react-icons/fa";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useRef, useState } from "react";
import { useUser } from "../../Contexts/UserProvider";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { ButtonComponent } from "../button";

//Componente cartão do hisórico de prompts
export const HistoryCard = ({ historicItem, duration }) => {
  const { themes } = useThemeChanger();
  const [open, setOpen] = useState(false);
  const { updateHistory, deleteHistory } = useUser();

  //Abre e fecha o cartão
  const handleCardSize = () => {
    open ? setOpen(false) : setOpen(true);
  };

  //Formata a data para DD/MM/AAAA
  const convertDate = (date) => {
    const dataObj = new Date(date);

    const dia = dataObj.getDate().toString().padStart(2, "0");
    const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
    const ano = dataObj.getFullYear().toString();
    const hora = date.toString().split(` `)[4];

    return `${dia}/${mes}/${ano} às ${hora}`;
  };

  //Formata o texto para que tenha quebras de linhas
  function formatText(text) {
    const lines = text.split("\n");
    return lines.map((line, index) => <p key={index}>{line}</p>);
  }

  //copia o texto do card
  const inputRef = useRef(null);
  const handleCopy = () => {
    const textToCopy = inputRef.current.innerText;
    toast.success(`Copiado`);
    navigator.clipboard.writeText(textToCopy);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const [isAnimating, setIsAnimating] = useState(false);

  const startAnimation = () => {
    setIsAnimating(true);
  };
  const handleAnimationComplete = () => {
    setIsAnimating(false);
    handleClose();
  };
  return (
    <Box
      className="cardBox"
      sx={{
        minHeight: open ? ["350px", "350px", "350px", "350px"] : "100px",
        height: open && ["auto"],
        transition: "0.2s",
        my:2,
        "& .motion": {
          minHeight: open ? ["320px", "320px", "350px", "350px"] : "100px",
          height: open && ["auto"],
          transition: "0.2s",
        },
      }}
    >
      <motion.div
        className="motion"
        initial={{ transform: "translateX(100%)" }}
        animate={{ transform: "translateX(0)" }}
        exit={{ transform: "translateX(100%)" }}
        transition={{ duration: duration / 2 }}
      >
        <Box
          className="cardBox--info"
          tabIndex={0}
          sx={{
            background: themes.background,
            borderRadius: 5,
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: open ? "auto" : "100px",
            height: open && ["auto"],
            transition: "0.2s",
            overflow: "hidden",
            color: themes.liaMainColor,
            border: `1px solid ${themes.liaMainColor}`,
            ":hover": {
              cursor: `pointer`,
              border: `1px solid ${themes.highlight}`,
              background: themes.background,
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCardSize();
            }
          }}
          onClick={handleCardSize}
        >
          <Box
            className="cardBox--info--top"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography sx={{ fontWeight: 900, fontSize: [10, 12, 14, 16] }}>
                Prompt:{" "}
                <Typography variant="span" sx={{ fontWeight: 400 }}>
                  {historicItem.prompt.length >= 15 && open === false
                    ? historicItem.prompt.slice(0, 15).concat("...")
                    : historicItem.prompt}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontWeight: 900,
                  mr: [3, 5, 8, 10],
                  fontSize: [10, 12, 14, 14],
                }}
              >
                Data:{" "}
                <Typography variant="span" sx={{ fontWeight: 400 }}>
                  {convertDate(historicItem.created_at)}
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: `start` }}>
              <Button
                sx={{ color: themes.textGray, p: 0, minWidth: "20px" }}
                onClick={(event) => {
                  handleCopy();
                  event.stopPropagation();
                }}
              >
                <FaCopy />
              </Button>
              <Button
                sx={{
                  color: historicItem.favorite
                    ? themes.highlight
                    : themes.textGray,
                  p: 0,
                  minWidth: "20px",
                }}
                onClick={(event) => {
                  const value = historicItem.favorite ? false : true;
                  updateHistory({
                    register_id: historicItem.register_id,
                    favorite: value,
                  });
                  event.stopPropagation();
                }}
              >
                <FaStar />
              </Button>
              <Button
                sx={{ color: themes.textGray, p: 0, minWidth: "20px" }}
                onClick={(event) => {
                  setOpenModal(true);
                  event.stopPropagation();
                }}
              >
                <FaTrash />
              </Button>
              <Modal
                className="cardBox--confirmDeleteModal"
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(0,0,0,0.5)",
                  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                  backdropFilter: "blur( 5px )",
                  WebkitBackdropFilter: "blur( 5px )",
                }}
              >
                <motion.div
                  className="motion"
                  initial={{ scale: 0 }}
                  animate={{ scale: isAnimating ? 0 : 1 }}
                  exit={{ scale: 0 }}
                  transition={{ duration: 1 }}
                  onAnimationComplete={isAnimating && handleAnimationComplete}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: `10px`,
                      border: `2px solid ${themes.highlight}`,
                      background: themes.background,
                      p: 4,
                      color: themes.highlight,
                      gap: 5,
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        fontSize: [24, 28, 32, 36],
                        fontWeight: 900,
                      }}
                    >
                      Deseja apagar ?
                    </Typography>
                    <Box sx={{ display: "flex", gap: 5 }}>
                      <ButtonComponent
                        text="Sim"
                        onClick={() => {
                          startAnimation();
                          deleteHistory(historicItem.register_id);
                        }}
                      />

                      <ButtonComponent
                        color={"#055C9A"}
                        text="Não"
                        onClick={() => {
                          startAnimation();
                        }}
                      />
                    </Box>
                  </Box>
                </motion.div>
              </Modal>
            </Box>
          </Box>
          <Box
            className="cardBox--info--text"
            tabIndex={0}
            sx={{
              display: "flex",
              flexDirection: `column`,
              gap: 2,
              overflow: "hidden",
              maxHeight: `250px`,
            }}
          >
            <Box sx={{ display: "flex", mt: 1 }}>
              <Typography
                sx={{
                  fontWeight: 900,
                  width: "100%",
                  fontSize: [10, 12, 14, 16],
                }}
              >
                Texto gerado:{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                height: `250px`,
                overflowY: `scroll`,
                "::-webkit-scrollbar ": {
                  width: "5px",
                },

                "::-webkit-scrollbar-track": {
                  background: "none",
                  margin: "1rem 0",
                },

                "::-webkit-scrollbar-thumb": {
                  backgroundColor: themes.highlight,
                  borderRadius: "20px",
                },
              }}
            >
              <Typography ref={inputRef} sx={{ fontSize: [10, 12, 14, 16] }}>
                {formatText(historicItem.prompt_response)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: `space-around`,
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: ["column", "column", "row", "row"],
                  gap: [0, 1, 1, 1],
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: `nowrap`,
                    fontWeight: 900,
                    fontSize: [10, 12, 14, 16],
                  }}
                >
                  Tokens gastos:{" "}
                </Typography>
                <Typography sx={{ fontSize: [10, 12, 14, 16] }}>
                  {historicItem.used_token}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: ["column", "column", "row", "row"],
                  gap: [0, 1, 1, 1],
                }}
              >
                <Typography
                  sx={{
                    ml: 2,
                    fontWeight: 900,
                    fontSize: [10, 12, 14, 16],
                    whiteSpace: `nowrap`,
                  }}
                >
                  Local Gasto:{" "}
                </Typography>
                <Typography sx={{ fontSize: [10, 12, 14, 16] }}>
                  {historicItem.location}{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};
