import { Box, Button, Popover, Tooltip, Typography } from "@mui/material";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { useUser } from "../../Contexts/UserProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputComponent } from "../input";
import { ButtonComponent } from "../button";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { useState } from "react";
import { TopContent } from "../topContent";
import { FaInfoCircle } from "react-icons/fa";
import { Description } from "../geraTextoComponents/description";
import { LoadingDot } from "../loadingComponents/loagindDot";

//configurações do textArea Mui - https://mui.com/material-ui/react-textarea-autosize/
const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[500] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

//componente de solicitação de ajuda para usuarios
export const SupportComponent = ({ startAnimation }) => {
  const { themes } = useThemeChanger();
  const { support, isLoading, disabled } = useUser();

  const validationSchema = Yup.object({
    subject: Yup.string()
      .required("Digite o assunto.")
      .min(3, "Mínimo 3 caracteres")
      .max(50, "Maximo 50 caracteres"),
    message: Yup.string()
      .required("Digite sua duvida.")
      .min(10, "Mínimo 10 caracteres")
      .max(500, "Maximo 500 caracteres"),
  });

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      support(values);
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box
        className="supportContainer"
        sx={{
          display: "flex",
          flexDirection: ["row", "row", "column", "column"],
          width: ["100%", "100%", "50%", "50%"],
          gap: [1, 1, 2, 2],
        }}
      >
        <TopContent
          showTokens={false}
          title="Solicitação de ajuda"
          onClick={startAnimation}
        />
        <Button
          className="supportContainer--infoButton"
          sx={{
            display: ["flex", "flex", "none", "none"],
            alignSelf: "center",
            fontSize: 24,
            p: [0, 0, 0, 0],
            minWidth: 24,
            width: 24,
            borderRadius: "50%",
            transition: "0.5s",
            color: themes.liaMainColor,
            ":hover": { color: themes.highlight },
            " .Mui-focusVisible": {
              color: themes.highlight,
            },
          }}
          onClick={handleClick}
        >
          <FaInfoCircle />
        </Button>
        <Popover
          className="supportContainer--infoButton--description"
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          sx={{ "& p": { color: themes.liaMainColor } }}
        >
          <Typography
            style={{
              color: themes.liaMainColor,
              fontWeight: "400",
              padding: 5,
            }}
          >
            Envie um e-mail com o assunto desejado e a descrição do seu problema
            e entraremos em contato o quanto antes!
          </Typography>
        </Popover>
        <Description text="Envie um e-mail com o assunto desejado e a descrição do seu problema e entraremos em contato o quanto antes! " />
      </Box>
      <Box
        className="supportContainer--form"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
          onSubmit={formik.handleSubmit}
        >
          <InputComponent
            label={"Assunto:"}
            width={"100%"}
            id="subject"
            name="subject"
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.touched.subject && formik.errors.subject}
          />
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              maxWidth: ["90vw", "90vw", "50vw", "50vw"],
              maxHeight: "50vh",
              minWidth: ["90vw", "90vw", "50vw", "50vw"],
              minHeight: "50vh",
            }}
          >
            <Tooltip title={formik.touched.message && formik.errors.message}>
              <StyledTextarea
                id="message"
                name="message"
                maxRows={4}
                aria-label="maximum height"
                placeholder="Diga nos, qual a sua duvida?"
                maxLength={500}
                minLength={10}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  maxWidth: ["80vw", "80vw", "50vw", "50vw"],
                  maxHeight: "50vh",
                  minWidth: ["80vw", "80vw", "50vw", "50vw"],
                  minHeight: "50vh",
                  border: formik.errors.message
                    ? `1px solid red`
                    : `1px solid ${themes.liaMainColor}`,
                  borderRadius: "5px",
                  color: themes.liaMainColor,
                  background: themes.background,
                }}
              />
            </Tooltip>
            {isLoading && (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  position: "absolute",
                }}
              >
                <LoadingDot />
              </Box>
            )}
            <Typography
              className="supportContainer--limitLength"
              sx={{
                position: "absolute",
                bottom: "0",
                right: ["1.5rem", "2.5rem", "1.5rem", "0.5rem"],
                fontSize: "12px",
                color:
                  formik.values.message.length > 500
                    ? themes.highlight
                    : themes.liaMainColor,
              }}
            >
              <Typography
                className="supportContainer--limitLength--errReport"
                variant="span"
                sx={{
                  fontSize: 22,
                  color: themes.highlight,
                  fontWeight: `bolder`,
                }}
              >{`${
                formik.errors.message ? formik.errors.message : ``
              }   `}</Typography>
              {`${formik.values.message.length}/500`}
            </Typography>
          </Box>
          <ButtonComponent
            disabled={disabled}
            type="submit"
            text={"Enviar"}
            width={"50%"}
          />
        </form>
      </Box>
    </>
  );
};
