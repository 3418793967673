import { useTheme } from "@mui/material";
import { ThemeProvider } from "styled-components";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Cookies from "js-cookie";

const ThemeContext = createContext({});

export const useThemeChanger = () => useContext(ThemeContext);
//Context responsavel por gerenciamento de temas(dark/light mode)
export const CustomThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("primary");
  const themes = useTheme().palette[theme];

  //ao iniciar o app, verifica e mantem o tema selecionado anteriormente pelo usuario
  useEffect(() => {
    const actualTheme = Cookies.get("theme");
    if (actualTheme) {
      if (actualTheme === "primary") {
        setTheme("primary");
      } else {
        setTheme("secondary");
      }
    } else {
      Cookies.set("theme", "primary", { expires: 365 });
    }
  }, [theme]);

  //função que permite a troca de temas
  const toggleTheme = useCallback(() => {
    const actualTheme = Cookies.get("theme");
    if (actualTheme) {
      if (actualTheme === "primary") {
        setTheme("secondary");
        Cookies.set("theme", "secondary", { expires: 365 });
      } else {
        setTheme("primary");
        Cookies.set("theme", "primary", { expires: 365 });
      }
    } else {
      const color = themes.background;
      if (color === "#eee") {
        setTheme("secondary");
        Cookies.set("theme", "secondary", { expires: 365 });
      } else {
        setTheme("primary");
        Cookies.set("theme", "primary", { expires: 365 });
      }
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ toggleTheme, themes, setTheme }}>
      <ThemeProvider theme={themes}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
