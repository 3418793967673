import styled from "styled-components";

export const Container = styled.section`
width:100vw;
height :80vh;
position: relative;
overflow: hidden;

.card{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 350px;
    height: 525px;
    object-fit: cover;
    opacity: 0;
    transition: 0.5s;
}

.card--active{
    opacity: 1;
    transform: scale(1);
    z-index: 90;
}

.card--left{
    transform: translateX(-50%) scale(0.8);
    transition: 0.5s;
    opacity:0.3;
}

.card--right{
    transform: translateX(50%) scale(0.8);
    transition: 0.5s;
    opacity: 0.3;
}

/* .button{
    
    width: 20px;
    height: 20px;
    background-color: gray;
    border-radius: 100%;
    border: none;
} */
`