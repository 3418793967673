import * as yup from "yup";
import { InputComponent } from "../../input";
import { ReturnDashButton, ButtonComponent } from "../../button";
import { Box, Typography } from "@mui/material";
import { useThemeChanger } from "../../../Contexts/themeProvider";
import { useUser } from "../../../Contexts/UserProvider";
import { useFormik } from "formik";
import { handleEmail } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { CircularIndeterminate } from "../../loadingComponents/loading";
import { Img } from "./styles";

//Componente de solicitação recuperação de senha
export const RecuperaSenhaEmail = () => {
  const { themes } = useThemeChanger();
  const { recuperar_senha, isLoading } = useUser();
  const navigate = useNavigate();

  const formSchema = yup.object().shape({
    email: yup.string().required("Insira seu e-mail.").email("E-mail invalido"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      recuperar_senha(values);
    },
  });

  return (
    <Box
      className="recoveryAccountBox"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        height: "50vh",
        gap: ["5rem"],
      }}
    >
      {isLoading && <CircularIndeterminate />}
      <Img
        className="logo"
        src={themes.src.logoSocial}
        alt="Logo fale com Lia Social"
      />
      <Box
        className="recoveryAccountBox--returnButton"
        sx={{ position: "absolute", top: "2rem", left: "2rem" }}
      >
        <ReturnDashButton startAnimation={() => navigate("/")}  />
      </Box>
      <Box
        className="recoveryAccountBox--form"
        sx={{
          width: ["90vw", "90vw", "50vw", "50vw"],
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: themes.textGray,
            fontSize: [24, 24, 28, 32],
            fontWeight: 600,
          }}
        >
          Recupere sua conta
        </Typography>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            gap: "1rem",
          }}
          onSubmit={formik.handleSubmit}
        >
          <InputComponent
            id="email"
            label="E-mail*"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              handleEmail(e, formik.setFieldValue);
            }}
            errors={formik.touched.email && formik.errors.email}
          />
          <ButtonComponent
            type="submit"
            text="Confirmar"
            width={["90%", "90%", "70%", "70%"]}
          />
        </form>
      </Box>
    </Box>
  );
};
