import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { InputButton } from "../input";
import { LoadingDot } from "../loadingComponents/loagindDot";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { FaCopy, FaQuestionCircle, FaShareAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useLocation } from "react-router-dom";
import { useGenText } from "../../Contexts/iaRequests";
// const arr = ["Escolha um modelo aqui", "prompt 1", "prompt 2", "prompt 3"];

//Componente onde o texto é gerado
export const TextGenerate = () => {
  const { themes } = useThemeChanger();
  const [textValue, setTextValue] = useState("");
  const [displayText, setDisplayText] = useState([]);
  const { prompt, isLoading, spentTokens, disabled } = useGenText();
  const location = useLocation();

  const handleChange = (event) => {
    setTextValue(event.target.value);
  };

  //Função que auxilia no compartilhamento do texto gerado
  const shareText = (text) => {
    const textToShare = text;
    navigator
      .share({ text: textToShare })
      .then(() => console.log("Texto compartilhado com sucesso"))
      .catch((error) => console.log("Erro ao compartilhar:", error));
  };
  const inputRef = useRef(null);

  //Função que copia o texto gerado
  const handleCopy = () => {
    const textToCopy = inputRef.current.innerText;

    navigator.clipboard.writeText(textToCopy);
  };

  // useEffect(() => {
  //   const element = inputRef.current;
  //   element.scrollTop = element.scrollHeight;
  // }, [displayText]);

  //função quer formata e escreve o texto letra por letra
  useEffect(() => {
    // Formata o texto recebido
    const formattedText = prompt.split("\n").flatMap((line, lineIndex) => {
      const characters = line.split("").map((char, charIndex) => ({
        id: `${lineIndex}-${charIndex}`,
        value: char,
      }));
      characters.push({ id: `${lineIndex}-${line.length}`, value: "\n" });
      return characters;
    });
    setDisplayText(formattedText);
    // let currentIndex = 0;
    // //Escreve letra por letra
    // const intervalId = setInterval(() => {
    //   setDisplayText((prevText) => {
    //     if (currentIndex >= formattedText.length) {
    //       clearInterval(intervalId);
    //       return prevText;
    //     }
    //     if (inputRef.current) {
    //       inputRef.current.focus();
    //     }
    //     currentIndex++;
    //     setDisabled(true);
    //     return formattedText.slice(0, currentIndex);
    //   });
    //   if (currentIndex === formattedText.length) {
    //     setDisabled(false);
    //   }
    // }, 10);
    // return () => clearInterval(intervalId);
  }, [prompt, textValue]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box
        id="Box input"
        data-intro="Após o envio do seu pedido, aguarde a criação do texto, a criação pode variar o tempo com base no tamanho de texto que será gerado."
        data-step="5"
        className="genTextBox"
        sx={{
          marginTop: ["1rem", "1rem", "2rem", "2rem"],
          borderRadius: "20px",
          padding: ["1rem", "1rem", "3rem", "3rem"],
          minHeight: "60vh",
          maxHeight: "60vh",
          minWidth: ["100%"],
          maxWidth: ["100%"],
          color: themes.liaMainColor,
          border: `2px solid ${themes.liaMainColor}`,
          outline: "none",
          overflowY: "scroll",
          "::-webkit-scrollbar ": {
            width: "5px",
          },

          "::-webkit-scrollbar-track": {
            background: themes.background,
            margin: "1rem 0",
          },

          "::-webkit-scrollbar-thumb": {
            backgroundColor: themes.highlight,
            borderRadius: "20px",
          },
          position: "relative",
        }}
        ref={inputRef}
      >
        <Typography
          className="genTextBox--textField"
          id="textResponse"
          sx={{
            fontSize: [14, 16, 16, 18],
            height: "auto",
            overflow: "visible",
          }}
        >
          {displayText.length > 1 ? (
            displayText.map((char) => (
              <React.Fragment key={char.id}>
                {char.value === "\n" ? <br /> : char.value}
              </React.Fragment>
            ))
          ) : (
            <>Olá! Como posso ajudá-lo(a) hoje?</>
          )}
        </Typography>

        {isLoading && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <LoadingDot />
          </Box>
        )}
      </Box>
      {/* <Popover
        className="genTextBox--infoButton"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ "& p": { color: themes.textGray } }}
      >
        <Typography sx={{ p: 2 }}>{selectedCard.text}</Typography>
      </Popover> */}

      <Box
        className="genTextBox--shortcuts"
        aria-describedby={id}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          alignSelf: "flex-start",
          justifySelf: "flex-start",
          color: themes.highlight,
          gap: 1,
        }}
      >
        <FaQuestionCircle
          onClick={() => introJs().start()}
          style={{ fontSize: "24px" }}
        />
        {/* <Select
          id="demo-simple-select"
          value={"Escolha um modelo aqui"}
          defaultValue={"Escolha um modelo aqui"}
          onChange={handleChange}
          sx={{
            gap: 1,
            background: themes.background,
            color: themes.liaMainColor,
            border: `1px solid ${themes.liaMainColor}`,
            borderRadius: "15px",
            p: [1.5, 2, 2, 2],
            fontWeight: 900,
            width: [200, 210, 230, 260],
            height: 24,
            textAlign: "center",
            transition: "0.5s",
            fontSize: [12, 12, 14, 16],

            "&:focus": {
              borderRadius: 8,
              borderColor: (theme) => theme.palette.primary.main,
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}20`,
            },
            "& div": { display: "flex", justifyContent: "center" },
            "& svg": { color: themes.liaMainColor },
            "& fieldset": {
              border: "none",
            },
          }}
        >
          {arr.map((el, i) => (
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
              key={`${el}-${i}`}
              value={el}
            >
              {el.length < 24 ? el : el.slice(0, 20).concat("...")}
            </MenuItem>
          ))}
        </Select> */}
        <Button
          id="Box input"
          data-intro="Copia o texto para que você possa utilizar onde precisar"
          data-step="1"
          className="genTextBox--shortcuts--copy"
          sx={{
            padding: [0, 0, 1, 1],
            minWidth: ["15px", "15px", "20px", "20px"],
            color: themes.highlight,
            fontSize: 20,
          }}
          disabled={disabled}
          onClick={() => {
            toast.success("Texto Copiado!");
            handleCopy();
          }}
        >
          <FaCopy />
        </Button>
        {/* <Button
          id="Box input"
          data-intro="Limpará a tela de texto"
          data-step="2"
          className="genTextBox--shortcuts--clear"
          sx={{
            padding: [0, 0, 1, 1],
            minWidth: ["15px", "15px", "20px", "20px"],
            color: themes.liaMainColor,
            fontSize: 24,
          }}
          disabled={disabled}
          onClick={() => {
            document.getElementById("textResponse").textContent = " ";
          }}
        >
          <RotateLeftSharp />
        </Button> */}
        <Button
          id="Box input"
          data-intro="Clicando aqui você poderá compartilhar o texto gerado"
          data-step="3"
          className="genTextBox--shortcuts--share"
          sx={{
            padding: [0, 0, 1, 1],
            minWidth: ["15px", "15px", "20px", "20px"],
            color: themes.liaMainColor,
            fontSize: 20,
          }}
          disabled={disabled}
          onClick={() => {
            const text = document.getElementById("textResponse").textContent;
            shareText(text);
          }}
        >
          <FaShareAlt />
        </Button>
        {spentTokens && displayText.length > 1 ? (
          <Typography sx={{ fontWeight: 900, color: themes.liaMainColor }}>
            Tokens Gastos:{" "}
            <span style={{ fontWeight: 400, color: themes.highlight }}>
              {spentTokens}
            </span>
          </Typography>
        ) : (
          ``
        )}
      </Box>
      <Box
        id="Box input"
        data-intro="Digite aqui o que você deseja divulgar e envie para gerar o seu texto. Exemplo: Buque de rosas"
        data-step="4"
        className="genTextBox--shortcuts--costTokens"
        sx={{ width: "100%" }}
      >
        <InputButton
          id="prompt"
          name="prompt"
          label="Escreva em poucas palavras o tema desejado"
          disabled={disabled}
          prompt={textValue}
          path={location.pathname}
        />
      </Box>
    </>
  );
};
