import { useFormik } from "formik";
import { handleEmail, ufs, handleName, listaProfissoes } from "../../../utils";
import { InputComponent } from "../../input";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useThemeChanger } from "../../../Contexts/themeProvider";
import { pequenoPorte, medioPorte, grandePorte } from "../../../utils/index";
import { useEffect, useState } from "react";
import { ReviewItem } from "./reviewItem";
import { FaInfoCircle } from "react-icons/fa";
import { useCompany } from "../../../Contexts/companyProvider";
import { ModalRegisterCompany } from "../../modal";

//Componente de cadastro de novas empresas stepper MUI - https://mui.com/material-ui/react-stepper/

export const CompanyCredentials = ({ startAnimation }) => {
  const { themes } = useThemeChanger();
  const { cadastra_perfil_empresa, isFormComplete, setIsFormComplete } =
    useCompany();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (index) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (index === steps.length - 1) {
      formik.handleSubmit();
    }
    handleNewArray();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClear = () => {
    formik.resetForm();
    setActiveStep(0);
  };
  //Formulario feito com YUP
  const formSchema = yup.object({
    company_name: yup.string().required("Nome da empresa é necessaria."),
    email: yup
      .string()
      .required("E-mail é necessário")
      .email("Digite um e-mail valido, ex: empresa@email.com"),
    street: yup.string().required("Campo obrigatório."),
    number: yup.string().required("Campo obrigatório."),
    city: yup.string().required("Campo obrigatório."),
    uf: yup.string().required("Campo obrigatório."),
    category: yup.string().required("Selecione uma categoria."),
    sub_category: yup
      .string()
      .required("Campo obrigatório.")
      .max(130, "Limite de caracteres atingido."),
    site: yup
      .string()
      .matches(/https:\/\/\S+/gi, "Formato invalido, ex: https://meusite.com"),
    instagram: yup
      .string()
      .matches(
        /^https?:\/\/(?:www\.)?instagram\.com\/([A-Za-z0-9_]+)/,
        "Formato invalido, ex: https://instagram.com/"
      ),
    facebook: yup
      .string()
      .matches(
        /^https?:\/\/(?:www\.)?facebook\.com\/([A-Za-z0-9.]+)/,
        "Formato invalido, ex: https://facebook.com/"
      ),
    blog: yup
      .string()
      .matches(/https:\/\/\S+/gi, "Formato invalido, ex: https://blog.com"),
    tiktok: yup
      .string()
      .matches(
        /^https?:\/\/(?:www\.)?tiktok\.com\/@([A-Za-z0-9_]+)/,
        "Formato invalido, ex: https://@tiktok.com/"
      ),
  });

  const formik = useFormik({
    initialValues: {
      company_name: "",
      email: "",
      street: "",
      number: "",
      city: "",
      uf: "",
      category: "",
      sub_category: "",
      site: "",
      instagram: "",
      tiktok: "",
      facebook: "",
      blog: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      cadastra_perfil_empresa(values);
    },
  });
  const [isMainFieldsError, setIsMainFieldsError] = useState(false);
  const [isSecondFieldsError, setIsSecondFieldsError] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      isMainFieldsError === false && isSecondFieldsError === false
        ? true
        : false
    );
  }, [isMainFieldsError, isSecondFieldsError]);

  //Verifica se todos os 8 campos principais foram preenchidos  company_name, email, street, number, city, uf, category, sub_category,
  useEffect(() => {
    if (
      Object.values(formik.values)
        .splice(0, 8)
        .every((el) => el !== "")
    ) {
      setIsMainFieldsError(false);
    } else {
      setIsMainFieldsError(true);
    }
    const socialMediaValues = Object.values(formik.values).splice(8);

    if (
      socialMediaValues.every((el) => el === "" || el.match(/https:\/\/\S+/gi))
    ) {
      setIsSecondFieldsError(false);
    } else {
      setIsSecondFieldsError(true);
    }
  }, [formik.values, formik.errors]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const nomeArray = [
    "Nome",
    "E-mail",
    "Endereço",
    "Nº",
    "Cidade",
    "UF",
    "Categoria",
    "sub Categoria",
    "Site",
    "Instagram",
    "Tik Tok",
    "Facebook",
    "Blog",
  ];

  const [newArray, setNewArray] = useState([]);

  const handleNewArray = () => {
    const values = Object.values(formik.values);
    const resultArray = nomeArray.map((nome, index) => ({
      nome,
      value: values[index],
    }));
    setNewArray(resultArray);
  };

  const steps = [
    {
      label: "Dados da empresa", //todos os campos obrigatorios que devem ser preenchidos
      description: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1rem",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <InputComponent
            id="company_name"
            name="company_name"
            label="Nome da empresa"
            type="text"
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              handleName(e, formik.setFieldValue);
            }}
            errors={formik.touched.company_name && formik.errors.company_name}
          />
          <InputComponent
            id="email"
            type="email"
            name="email"
            label="E-mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              handleEmail(e, formik.setFieldValue);
            }}
            errors={formik.touched.email && formik.errors.email}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: [1, 1, 2, 2],
              width: "100%",
            }}
          >
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={listaProfissoes}
              onChange={(e, newValue) => {
                formik.setFieldValue("category", newValue);
              }}
              sx={{
                m: 1,
                width: ["90%", "90%", "70%", "70%"],
                position: "relative",
                color: themes.liaMainColor,
                "& .Mui-focused": {
                  color: themes.liaMainColor,
                },
                "& label,input": {
                  color: themes.liaMainColor,
                },

                "& fieldset": {
                  border: `1px solid ${themes.liaMainColor}`,
                  ":hover": {
                    border: `1px solid ${themes.liaMainColor}`,
                  },
                },
                "& svg": {
                  color: themes.liaMainColor,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categoria"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  id="category"
                  name="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={formik.touched.category && formik.errors.category}
                  sx={{ fontSize: "15px", color: themes.liaMainColor }}
                />
              )}
            />

            {/*  <FormControl sx={{ m: 1, width: ["90%", "90%", "70%", "70%"] }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{ fontSize: "15px", color: themes.liaMainColor }}
              >
                Categoria
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="category"
                name="category"
                label="Categoria"
                value={formik.values.category}
                defaultValue={formik.values.category}
                onChange={formik.handleChange}
                error={formik.touched.category && formik.errors.category}
                inputProps={{ color: themes.liaMainColor }}
                sx={{
                  position: "relative",
                  color: themes.liaMainColor,
                  "& span": {
                    color: themes.liaMainColor,
                  },
                  "& fieldset": {
                    border: `1px solid ${themes.liaMainColor}`,
                  },
                  "& svg": {
                    color: themes.liaMainColor,
                  },
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontWeight: 900,
                    width: "100%",
                    background: themes.textGray,
                    textAlign: "center",
                    color: themes.liaMainColor,
                  }}
                >
                  Pequeno porte
                </Typography>
                {pequenoPorte.map((el, i) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      wordWrap: "break-word",
                      gap: 2,
                      fontSize: 16,
                      textAlign: "center",
                      whiteSpace: "break-spaces",
                    }}
                    key={`${el}-${i}`}
                    value={el}
                    onClick={() => {}}
                  >
                    {el}
                  </MenuItem>
                ))}{" "}
                <Typography
                  sx={{
                    fontWeight: 900,
                    width: "100%",
                    background: themes.textGray,
                    textAlign: "center",
                    color: themes.liaMainColor,
                  }}
                >
                  Médio porte
                </Typography>
                {medioPorte.map((el, i) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 16,
                      textAlign: "center",
                      gap: 2,
                      wordWrap: "break-word",
                      whiteSpace: "break-spaces",
                    }}
                    key={`${el}-${i}`}
                    value={el}
                    onClick={() => {}}
                  >
                    {el}
                  </MenuItem>
                ))}{" "}
                <Typography
                  sx={{
                    fontWeight: 900,
                    width: "100%",
                    background: themes.textGray,
                    textAlign: "center",
                    color: themes.liaMainColor,
                  }}
                >
                  Grande porte
                </Typography>
                {grandePorte.map((el, i) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 16,
                      textAlign: "center",
                      whiteSpace: "break-spaces",
                      wordWrap: "break-word",
                      gap: 2,
                    }}
                    key={`${el}-${i}`}
                    value={el}
                    onClick={() => {}}
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
          <Box
            sx={{
              width: ["100%", "100%", "70%", "70%"],
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <InputComponent
              id="sub_category"
              type="sub_category"
              name="sub_category"
              label="Sub categorias"
              width={"100%"}
              autoComplete="off"
              value={formik.values.sub_category}
              onBlur={formik.handleBlur}
              errors={formik.touched.sub_category && formik.errors.sub_category}
              style={{
                color:
                  formik.values.sub_category.length > 130
                    ? themes.highlight
                    : themes.liaMainColor,
              }}
              onChange={(event) => {
                formik.handleChange(event);
                if (formik.values.sub_category.length > 130) {
                  let value = formik.values.sub_category;
                  formik.setFieldValue("sub_category", value.slice(0, 130));
                }
              }}
            />

            <Typography
              sx={{
                position: "absolute",
                bottom: "0",
                right: ["1.5rem", "2.5rem", "1.5rem", "0.5rem"],
                fontSize: "12px",
                color:
                  formik.values.sub_category.length > 130
                    ? themes.highlight
                    : themes.liaMainColor,
              }}
            >{`${formik.values.sub_category.length}/130`}</Typography>
            <Button
              className="infoButton"
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: ["-1rem", "-1rem", "-2rem", "-2rem"],
                fontSize: 24,
                p: [0, 0, 0, 0],
                minWidth: 24,
                borderRadius: "50%",
                transition: "0.5s",
                color: themes.textGray,
                ":hover": { color: themes.highlight },
                " .Mui-focusVisible": {
                  color: themes.highlight,
                },
              }}
              onClick={handleClick}
            >
              <FaInfoCircle />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              sx={{ "& p": { color: themes.textGray } }}
            >
              <ul style={{ margin: "1rem" }}>
                <span style={{ fontWeight: "900" }}>
                  {" "}
                  Descreva de maneira resumida o foco principal da sua empresa,
                  exemplos:
                </span>
                <p style={{ color: themes.highlight }}>
                  Caso possua mais de um foco separe por ","(vírgula)
                </p>
                <li>Roupas femininas, Roupas social;</li>
                <li>Lanches artesanais;</li>
                <li>Manutenção de carros esportivos, Manutenção de motos;</li>
                <li>Vendas de periféricos para computadores;</li>
                <li>Banho, tosa e vacinação;</li>
              </ul>
            </Popover>
          </Box>
          <Box
            sx={{
              width: ["100%", "100%", "70%", "70%"],
              display: "flex",
              flexDirection: ["column", "column", "row", "row"],
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <InputComponent
              label="Endereço"
              id="street"
              type="text"
              name="street"
              width={"78%"}
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                handleName(e, formik.setFieldValue);
              }}
              errors={formik.touched.street && formik.errors.street}
            />
            <InputComponent
              id="number"
              type="number"
              name="number"
              label="Nº"
              width={"20%"}
              value={formik.values.number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.touched.number && formik.errors.number}
            />
          </Box>
          <Box
            sx={{
              width: ["100%", "100%", "70%", "70%"],
              display: "flex",
              flexDirection: ["column", "column", "row", "row"],
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <InputComponent
              id="city"
              type="city"
              name="city"
              label="Cidade"
              width="78%"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                handleName(e, formik.setFieldValue);
              }}
              errors={formik.touched.city && formik.errors.city}
            />{" "}
            <FormControl
              sx={{ width: ["90%", "90%", "20%", "20%"], height: "54.56px" }}
            >
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{ fontSize: "15px", color: themes.liaMainColor }}
              >
                UF
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="uf"
                name="uf"
                label="UF"
                value={formik.values.uf}
                defaultValue={formik.values.uf}
                onChange={formik.handleChange}
                error={formik.touched.uf && formik.errors.uf}
                sx={{
                  color: themes.liaMainColor,
                  height: "100%",
                  width: "100%",
                  "& fieldset": {
                    border: `1px solid ${themes.liaMainColor}`,
                  },
                  "& svg": {
                    color: themes.liaMainColor,
                  },
                }}
              >
                {ufs.sort().map((el, i) => (
                  <MenuItem
                    style={{
                      display: "flex",
                      gap: 2,
                    }}
                    key={`${el}-${i}`}
                    value={el}
                    onClick={() => {}}
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      ),
    },
    {
      label: "Conteúdo adicional (Opcional)",
      description: (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1rem",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <InputComponent
            id="site"
            type="site"
            name="site"
            label="Site"
            placeholder="Ex: https://seusite.com"
            value={formik.values.site}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            errors={formik.touched.site && formik.errors.site}
          />
          <InputComponent
            id="instagram"
            type="instagram"
            name="instagram"
            label="Instagram"
            placeholder="Ex: https://instagram.com/seuUsuario"
            value={formik.values.instagram}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            errors={formik.touched.instagram && formik.errors.instagram}
          />
          <InputComponent
            id="tiktok"
            type="tiktok"
            name="tiktok"
            label="Tik Tok"
            placeholder="Ex: https://tiktok.com/@seuUsuario"
            value={formik.values.tiktok}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            errors={formik.touched.tiktok && formik.errors.tiktok}
          />
          <InputComponent
            id="facebook"
            type="facebook"
            name="facebook"
            label="Facebook"
            placeholder="Ex: https://facebook.com/seuUsuario"
            value={formik.values.facebook}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            errors={formik.touched.facebook && formik.errors.facebook}
          />
          <InputComponent
            id="blog"
            type="blog"
            name="blog"
            label="Blog"
            placeholder="Ex: https://seublog.com"
            value={formik.values.blog}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            errors={formik.touched.blog && formik.errors.blog}
          />
        </Box>
      ),
    },
    {
      label: "Revisão",
      description: (
        <>
          <Typography sx={{ fontWeight: 900 }}>Dados da Empresa:</Typography>
          {newArray.map((item, i) => (
            <ReviewItem
              key={i}
              title={item.nome}
              required={item.value ? false : true}
              value={item.value ? item.value : "Campo obrigatório!"}
            />
          ))}
        </>
      ),
    },
  ];
  return (
    <>
      <Box sx={{ width: ["90vw", "90vw", "70vw", "70vw"] }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step sx={{ color: themes.highlight }} key={step.label}>
              <StepLabel
                error={
                  (index === 0 && isMainFieldsError) ||
                  (index === 1 && isSecondFieldsError)
                }
                sx={{
                  "& .MuiStepIcon-root.Mui-active, .MuiStepLabel-active, .MuiStepLabel-label.Mui-active, .MuiStepIcon-root.Mui-completed ":
                    {
                      color: themes.highlight,
                    },
                  "&  .MuiStepLabel-active .MuiStepLabel-label.Mui-active": {
                    color: themes.liaMainColor,
                  },
                  "& .MuiStepIcon-root.Mui-completed ": {
                    color:
                      (index === 0 && isMainFieldsError) ||
                      (index === 1 && isSecondFieldsError)
                        ? themes.highlight
                        : themes.liaMainColor,
                  },
                  "& .MuiStepLabel-label.Mui-completed ": {
                    color:
                      (index === 0 && isMainFieldsError) ||
                      (index === 1 && isSecondFieldsError)
                        ? themes.highlight
                        : themes.liaMainColor,
                  },
                }}
                optional={
                  index === 2 ? (
                    <Typography
                      sx={{ color: themes.liaMainColor }}
                      variant="caption"
                    >
                      Último passo
                    </Typography>
                  ) : null
                }
              >
                <form
                  style={{
                    color:
                      (index === 0 && isMainFieldsError) ||
                      (index === 1 && isSecondFieldsError)
                        ? themes.highlight
                        : themes.liaMainColor,
                  }}
                  onSubmit={formik.handleSubmit}
                >
                  {step.label}
                </form>
              </StepLabel>
              <StepContent>
                {step.description}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => handleNext(index)}
                      sx={{
                        mt: 1,
                        mr: 1,
                        background: themes.highlight,
                        ":hover": { background: themes.hoverButton },
                      }}
                    >
                      {index === steps.length - 1 ? "Cadastrar" : "Continuar"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1, color: themes.liaMainColor }}
                    >
                      Voltar
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper
            style={{ background: "transparent" }}
            square
            elevation={0}
            sx={{ p: 3 }}
          >
            <Typography sx={{ color: themes.liaMainColor }}>
              {isFormComplete ? (
                "Empresa cadastrada com sucesso!"
              ) : (
                <span style={{ color: themes.highlight }}>
                  Verifique se os campos foram preenchidos corretamente.
                </span>
              )}
            </Typography>
            <Button
              onClick={() => handleReset()}
              sx={{ mt: 1, mr: 1, color: themes.liaMainColor }}
            >
              {isFormComplete ? "Concluído" : "Preencher"}
            </Button>
          </Paper>
        )}

        <ModalRegisterCompany handleClear={() => handleClear()} />
      </Box>
    </>
  );
};
