import {
  Box,
  Button,
  List,
  ListItem,
  Popover,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { BuyTokenHistoryCard } from "./historyCard";
import { useBounty } from "../../Contexts/bountyProvider";
import { LoadingDot } from "../loadingComponents/loagindDot";
import { useUser } from "../../Contexts/UserProvider";
import { Description } from "../geraTextoComponents/description";
import { useThemeChanger } from "../../Contexts/themeProvider";
import { BsArrowRepeat } from "react-icons/bs";
import { useEffect, useState } from "react";
import { DateFilter } from "../DateFilterComponent";
import { AiOutlineCalendar } from "react-icons/ai";

//Historico de compras de tokens
export const BuyTokenHistory = () => {
  const { transactions, getTransactions } = useBounty();
  const { isLoading } = useUser();
  const { themes } = useThemeChanger();
  const [filteredItems, setFilteredItems] = useState([]);

  //converte a data para formato DD/MM/AAAA
  const convertDate = (date) => {
    const dataObj = new Date(date);
    const dia = dataObj.getDate().toString().padStart(2, "0");
    const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
    const ano = dataObj.getFullYear().toString();
    const hora = dataObj.toString().split(` `)[4];

    return `${dia}/${mes}/${ano} às ${hora}`;
  };

  const [type, setType] = useState("");
  const [value, setValue] = useState(0);
  const [date, setDate] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Função que trata o filtro
  //por padrão o filtro pega o historico de até 90 dias, caso inserido outro prazo de tempo ele atualizará conforme as 4 primeiras variaveis
  //e aplicara o filtro de tempo quando entrar no if/else
  const ItemList = () => {
    const today = new Date();
    const sixtyDaysAgo = new Date(today);
    sixtyDaysAgo.setDate(today.getDate() - date);
    const items = transactions;
    //type se refere a gasto, compra ou tudo
    if (type === "") {
      setFilteredItems(
        items.filter((item) => {
          const itemDate = new Date(item.transaction_date);
          return itemDate >= sixtyDaysAgo && itemDate <= today;
        })
      );
    } else {
      setFilteredItems(
        items.filter((item) => {
          const itemDate = new Date(item.transaction_date);
          return (
            itemDate >= sixtyDaysAgo &&
            itemDate <= today &&
            item.transaction_type === type
          );
        })
      );
    }
  };

  //quando algum filtro é escolhido, esse useEffect é chamado, caso haja uma data, ele chamara a função itemList()
  //caso contrario apenas aplicará o filtro
  useEffect(() => {
    const filter = transactions;
    if (type) {
      if (date !== 0) {
        ItemList();
      } else {
        setFilteredItems(filter.filter((el) => el.transaction_type === type));
      }
    } else {
      if (date !== 0) {
        ItemList();
      } else {
        setFilteredItems(filter);
      }
    }
  }, [type, date, transactions]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      className="historyTokensContainer"
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row", "row"],
        height: "100%",
        width: "100%",
        mt: [0, 0, 5, 5],
      }}
    >
      <Box
        className="historyTokensContainer--description"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          gap: 3,
          overflow: "hidden",
        }}
      >
        <Description
          text={
            "Aqui você tem a liberdade para consultar todas as compras realizadas na conta da sua empresa, com o limite das 100 ultimas transações."
          }
        />
        <Description text="Para facilitar você pode escolher a data ou o tipo, para filtrar sua pesquisa." />
      </Box>
      {isLoading && (
        <Box
          className="historyTokensContainer--loadingComponent"
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <LoadingDot />
        </Box>
      )}
      <Box
        className="historyTokensContainer--tokensCardsContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxHeight: "90%",
        }}
      >
        {transactions ? (
          <>
            <Tabs
              className="historyTokensContainer--tokensCardsContainer--filter"
              value={value}
              onChange={handleChange}
              centered
              textColor={themes.highlight}
              indicatorColor={themes.highlight}
              sx={{
                "& button": {
                  padding: "12px 0px",
                  color: themes.liaMainColor,
                },
                "& .Mui-selected": {
                  color: themes.highlight,
                },
                "& .MuiTabs-indicator": { background: themes.highlight },
              }}
            >
              <Tab
                label="Todos"
                onClick={() => {
                  setType("");
                }}
              />
              <Tab label="Gastos" onClick={() => setType("expense")} />
              <Tab label="Créditos" onClick={() => setType("credit")} />
              <Button
                sx={{ color: themes.liaMainColor, fontSize: 24 }}
                onClick={handleClick}
              >
                <AiOutlineCalendar />
              </Button>
              <Button
                onClick={getTransactions}
                sx={{
                  color: themes.highlight,
                  fontSize: 24,
                }}
              >
                <BsArrowRepeat />
              </Button>
              <Box className="historyTokensContainer--tokensCardsContainer--filter--date">
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <DateFilter
                    type={type}
                    setDate={setDate}
                    setType={setType}
                    transactions={transactions}
                    setFilteredItems={setFilteredItems}
                    handleClose={handleClose}
                  />
                </Popover>
              </Box>
            </Tabs>
            <List
              className="historyTokensContainer--tokensCardsContainer--tokenCards"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxHeight: "95%",
                gap: [1, 1, 3, 3],
                overflowY: "scroll",
                padding: 0.5,
                mt: 2,
                pr: 2,
                pb: 2,
                "::-webkit-scrollbar ": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: themes.background,
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: themes.highlight,
                  borderRadius: "20px",
                },
              }}
            >
              {transactions &&
                filteredItems?.map((el, i) => (
                  <ListItem key={i}>
                    <BuyTokenHistoryCard
                      payment_method={el.payment_method}
                      receipt_link={el.receipt_link}
                      spent_location={el.spent_location}
                      token_volume={el.token_volume}
                      total_amount={el.total_amount}
                      transaction_date={convertDate(el.transaction_date)}
                      transaction_id={el.transaction_id}
                      transaction_type={el.transaction_type}
                    />
                  </ListItem>
                ))}
            </List>
          </>
        ) : (
          <Box
            className="historyTokensContainer--emptyContainer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50%",
            }}
          >
            <Typography
              sx={{
                fontSize: [18, 22, 28, 36],
                fontWeight: 600,
                width: "80%",
                color: themes.liaMainColor,
              }}
            >
              Você ainda não realizou nenhuma transação.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
